import { createAction, createSlice } from '@reduxjs/toolkit';

import { setStoreField } from '@common/store-utils';

import { config } from '../config';
import { ModelState } from '../types';

const initialState: ModelState = {
  carouselItems: [],
  infoSquares: [],
};

/**
 * Action to get Carousel Items From API
 */
const getCarouselItems = createAction('getCarouselItems');
const getInfoSquares = createAction('getInfoSquares');

export const createLoginSlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    setCarouselItems: setStoreField('carouselItems'),
    setInfoSquares: setStoreField('infoSquares'),
    reset: () => initialState,
  },
});

export const { reducer } = createLoginSlice;

export const actions = {
  ...createLoginSlice.actions,
  getCarouselItems,
  getInfoSquares,
};
