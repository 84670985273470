import { request } from '../../../common/request';
import { UploadResponse } from '../types';

const PATH = `${process.env.REACT_APP_API_URL}/core-service/api/upload`;

export const uploadFileApi = async (
  fileType: 'DOCUMENT' | 'APPEAL',
  file: File
): Promise<UploadResponse> => {
  const doc = new FormData();
  doc.append('file', file);
  const { data } = await request.post<UploadResponse>({
    url: `${PATH}/upload-file/${fileType}`,
    data: doc,
  });
  return data;
};

export const deleteUploadedFileApi = async (
  fileType: 'DOCUMENT' | 'APPEAL',
  fileName: string
): Promise<UploadResponse> => {
  const { data } = await request.delete<UploadResponse>({
    url: `${PATH}/delete-file/${fileType}`,
    data: {
      file_name: fileName,
    },
  });
  return data;
};
