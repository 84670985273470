import {defaultInputTheme} from '../Input/theme';
import {defaultMaskedInputTheme} from '../MaskedInput/theme';
import {defaultCollapseTheme} from '../Collapse/theme';
import {defaultLoaderTheme} from '../Loader/theme';
import {defaultButtonTheme} from '../Button/theme';
import {defaultCheckBoxTheme} from '../CheckBox/theme';
import {defaultDropDownTheme} from '../DropDown/theme';
import {defaultMultiSelectTheme} from '../MultiSelect/theme';
import {defaultDropDownSelectTheme} from '../DropDownSelect/theme';
import {defaultPasswordTheme} from '../Password/theme';
import {defaultTagsTheme} from '../Tags/theme';
import {defaultDateTimeInputTheme} from '../../src/DateTimeInput/theme';
import {defaultSuggestionListTheme} from '../../src/SuggestionList/theme';

export const globalDefaultTheme = {
  input: defaultInputTheme,
  maskedInput: defaultMaskedInputTheme,
  dateTimeInput: defaultDateTimeInputTheme,
  collapse: defaultCollapseTheme,
  loader: defaultLoaderTheme,
  button: defaultButtonTheme,
  checkBox: defaultCheckBoxTheme,
  dropDown: defaultDropDownTheme,
  multiSelect: defaultMultiSelectTheme,
  dropDownSelect: defaultDropDownSelectTheme,
  tags: defaultTagsTheme,
  suggestionList: defaultSuggestionListTheme,
  password: defaultPasswordTheme,
};
