import React, {useRef} from 'react';
import { UncontrolledTooltip, UncontrolledTooltipProps } from 'reactstrap';

type TooltipProps = {
  text: string;
  placement?: UncontrolledTooltipProps['placement'];
};

export const Tooltip: React.FC<TooltipProps> = ({ text, placement = 'top', children }) => {
  const ref = useRef(null)

  return (
    <>
      <span ref={ref}>{children}</span>
      <UncontrolledTooltip
        placement={placement}
        target={ref}
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '24px',
          fontSize: '16px',
          borderRadius: '15px',
          border: 'solid 2px #000',
          boxShadow: '0 4px 0 #00ff96',
          width: '500px',
          marginBottom: '12px',
        }}
      >
        {text}
      </UncontrolledTooltip>
    </>
  );
};
