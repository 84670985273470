import { request } from '../../../common/request';
const OTP_PATH = `${process.env.REACT_APP_API_URL}/core-service/api`;

export const getFaqCategories = (): Promise<any> => {
  return request.get<any>({
    url: `${OTP_PATH}/faq-category/all`,
  });
};

export const getInfoSquares = (): Promise<any> => {
  return request.get<any>({
    url: `${OTP_PATH}/information-squares/get-all`,
  });
};
