import React, { useContext } from 'react';

type BranchProps = {
  /** condition */
  when: boolean;
};

const BranchContext = React.createContext(false);

const If: React.FC = ({ children }) => {
  const when = useContext(BranchContext);
  return when ? <>{children}</> : null;
};

const Otherwise: React.FC = ({ children }) => {
  const when = useContext(BranchContext);
  return when ? null : <>{children}</>;
};

/**
 * Component with branchy rendering
 * @param props
 * @returns component
 */
export const Branch = ({
  when,
  children,
}: React.PropsWithChildren<BranchProps>): React.ReactElement => (
  <BranchContext.Provider value={when}>{children}</BranchContext.Provider>
);

Branch.If = If;
Branch.Otherwise = Otherwise;
