import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { config } from '../config';
import { ModelState } from '../types';
import { modelStateDefaultValue } from '../defaults';
import { setStoreField } from '@common/store-utils';

const createSendAppealForm = createAction('sendAppealForm');
const createUpdateAppealSent = createAction('createUpdateAppealSent');
const createUpdateAppealSentSuccessfully = createAction('sendAppealForm');
const getInvalidDocuments = createAction('getInvalidDocuments');
const getPdf = createAction('getPdf');

const initialState = modelStateDefaultValue;

type Update<T> = {
  changes: Partial<T>;
  withNoChangesMark?: boolean;
};

/**
 * Action to confirm appeal was sent
 * */
const setIsAppealSent = createAction('isAppealSent');
const returnAppealDocumentsFromCorrections = createAction<Record<string, any>>(
  'returnAppealDocumentsFromCorrections'
);
const returnDetailsFromCorrections = createAction(
  'returnDetailsFromCorrections'
);

/**
 * Get user documents list
 * */
const getUserDocumentsList = createAction('getUserDocumentsList');
const getAppealDocumentsBackToFix = createAction('getAppealDocumentsBackToFix');
const getRequestData = createAction('getRequestData');
const getAppealData = createAction('getAppealData');
const updateAppeal = createAction('updateAppeal');

export const createPersonalAreaAppealSlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    // Update hole state
    setState: (_, { payload }: PayloadAction<ModelState>) => payload,
    setIsChangeBankDetails: setStoreField('isChangeBankDetails'),
    // partial personal info update
    updateDocumentsForm(
      state,
      { payload }: PayloadAction<Update<{ documents: any; details: string }>>
    ) {
      const { changes } = payload;
      state.documents = changes.documents;
      state.details = changes.details || '';
    },
    updateUserRequestId(
      state,
      { payload }: PayloadAction<Update<{ id: number }>>
    ) {
      const { changes } = payload;
      state = { ...state, request_id: changes.id };
    },

    updateAppealFromErrorForm(
      state,
      { payload }: PayloadAction<Update<string>>
    ) {},

    updateAppealSentState(
      state,
      { payload }: PayloadAction<Update<{ appealSent: boolean }>>
    ) {
      state.appealSent = payload.changes.appealSent || false;
    },
    updateAppealSentSuccessfullyState(
      state,
      { payload }: PayloadAction<Update<{ appealSentSuccessfully: boolean }>>
    ) {
      state.appealSentSuccessfully =
        payload.changes.appealSentSuccessfully || false;
    },
    setIsAppealSent: setStoreField('isAppealSent'),
    setIsAppealSentSuccess: setStoreField('isApeealSentSuccess'),
    setUserDocuments: setStoreField('userDocumentsList'),
    setAppealType: setStoreField('appealType'),
    setAppealDocumentsBackToFix: setStoreField('appealDocumentsBackToFix'),
    setRequestData: setStoreField('requestData'),
    setDetailedScore: setStoreField('detailedScore'),
    setInvalidDocuments: setStoreField('invalidDocuments'),
    setAppealReturnComment: setStoreField('appealReturnComment'),
    setAppealOriginalDocuments: setStoreField('originalAppealDocuments'),
    setDetails: setStoreField('details'),
    setFixBankFile: setStoreField('fixBankFile'),

    reset: () => initialState,
  },
});

export const { reducer } = createPersonalAreaAppealSlice;
export const actions = {
  getUserDocumentsList,
  createSendAppealForm,
  createUpdateAppealSent,
  createUpdateAppealSentSuccessfully,
  getAppealDocumentsBackToFix,
  returnAppealDocumentsFromCorrections,
  returnDetailsFromCorrections,
  getRequestData,
  getPdf,
  getInvalidDocuments,
  getAppealData,
  updateAppeal,
  ...createPersonalAreaAppealSlice.actions,
};
