import { actions, reducer, selectors, sagas } from './model';
import { config } from './config';

export { RegistrationContainer } from './Components';
export * from './types';

export const personalInfoModel = {
  selectors,
  actions,
  reducer,
  config,
  sagas,
};
