import {
  createModelSelector,
  createHookSelector,
  createModelFieldSelector,
} from '../../../common/store-utils';

import { config } from '../config';
import { User } from '../types';
import { createSelector } from '@reduxjs/toolkit';

/**
 * Selector of model State
 */
const modelSelector = createModelSelector<User>(config.modelName);

/**
 * Selector builder of model State field
 */
const fieldSelector = createModelFieldSelector<User>(config.modelName);

/**
 * Process selector
 */
const processIdSelector = fieldSelector('processId');

/**
 * Username selector
 */
const usernameSelector = fieldSelector('username');

/**
 * Username selector
 */
const isLoggedInSelector = fieldSelector('isLoggedIn');

/**
 * Confirmation session for OTP
 */
const confirmSessionSelector = fieldSelector('confirmSession');

/**
 * Confirmation session for OTP
 */
const isSponsoredSelector = fieldSelector('isSponsored');

/**
 * Is from survey link
 */
const isFromSurveySelector = fieldSelector('isFromSurvey');

/**
 * Is survey pop up should appear
 */
const isShowSurveyFromRegistration = fieldSelector(
  'isShowSurveyFromRegistration'
);

/**
 * Selector - State of the alert modal
 */
const requestStatusSelector = fieldSelector('requestStatus');
const publishedStateSelector = fieldSelector('publishedState');

/**
 * Selector - Request creation date
 */
const requestDateSelector = fieldSelector('requestDate');
const requestIdSelector = fieldSelector('requestId');

const statusNameSelector = createSelector(
  requestStatusSelector,
  publishedStateSelector,
  (status, publishState) => {
    if (
      (status === 'DENIED' ||
        status === 'WAITING_TO_BE_COMPUTED' ||
        status === 'COMPUTED' ||
        status === 'REJECTED_BY_ACADEMIC_INSTITUTION') &&
      // eslint-disable-next-line eqeqeq
      (publishState == '0' || !publishState)
    ) {
      return 'UI_PROGRESS';
    }
    if (
      (status === 'APPEAL_WAITING_CALCULATED' ||
        status === 'APPEAL_CALCULATED' ||
        status === 'APPEAL_APPROVED' ||
        status === 'APPEAL_DENIED') &&
      // eslint-disable-next-line eqeqeq
      publishState != '2'
    ) {
      return 'UI_APPEAL_PROGRESS';
    }

    return status;
  }
);

export const selectors = {
  processId: processIdSelector,
  username: usernameSelector,
  confirmSession: confirmSessionSelector,
  isLoggedIn: isLoggedInSelector,
  isSponsored: isSponsoredSelector,
  requestStatus: requestStatusSelector,
  requestDate: requestDateSelector,
  requestId: requestIdSelector,

  useUsername: createHookSelector(usernameSelector),
  useIsLoggedIn: createHookSelector(isLoggedInSelector),
  useIsSponsored: createHookSelector(isSponsoredSelector),
  useRequestStatus: createHookSelector(requestStatusSelector),
  usePublishedState: createHookSelector(publishedStateSelector),

  useIsFromSurvey: createHookSelector(isFromSurveySelector),
  useIsShowSurveyFromRegistration: createHookSelector(
    isShowSurveyFromRegistration
  ),
  useRequestDate: createHookSelector(requestDateSelector),
  useConfirmSession: createHookSelector(confirmSessionSelector),
  useUiStatus: createHookSelector(statusNameSelector),
};
