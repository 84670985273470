import {
  createHookSelector,
  createModelFieldSelector,
} from '../../../common/store-utils';

import { config } from '../config';

import { Dictionaries, DictionariesModel } from '../types';
import { createSelector } from '@reduxjs/toolkit';

/**
 * Selector of model State
 */
const fieldSelector = createModelFieldSelector<DictionariesModel>(
  config.modelName
);

/**
 * Academic institution list Selector
 */
const loadersSelector = fieldSelector('loaders');

/**
 * Academic institution list Selector
 */
const academicInstitutionListSelector = fieldSelector(
  Dictionaries.AcademicInstitution
);

/**
 * Study fields list Selector
 */
const studyFieldsListSelector = fieldSelector(Dictionaries.StudyFields);

/**
 * Study fields list Selector
 */
const citiesListSelector = fieldSelector(Dictionaries.Cities);

/**
 * Study fields list Selector
 */
const sectorsListSelector = fieldSelector(Dictionaries.Sector);

/**
 * Study fields list Selector
 */
const banksListSelector = fieldSelector(Dictionaries.Banks);

/**
 * Study fields list Selector
 */
const districtsListSelector = fieldSelector(Dictionaries.Districts);

/**
 * Academic institution UI list Selector
 */
const academicInstitutionSelector = createSelector(
  academicInstitutionListSelector,
  (data = []) =>
    data.map(
      ({ id, name }) =>
        ({
          value: id.toString(),
          text: name,
        } || [])
    )
);

/**
 * Study Fields UI list Selector
 */
const studyFieldsSelector = createSelector(
  studyFieldsListSelector,
  (data = []) =>
    data.map(
      ({ id, label }) =>
        ({
          value: id.toString(),
          text: label,
        } || [])
    )
);

/**
 * Study Fields UI list Selector
 */
const citiesSelector = createSelector(citiesListSelector, (data = []) =>
  data.map(
    ({ city_symbol, city_name }) =>
      ({
        value: city_symbol.toString(),
        text: city_name,
      } || [])
  )
);

/**
 * Study Fields UI list Selector
 */
const sectorsSelector = createSelector(sectorsListSelector, (data = []) =>
  data.map(
    ({ id, label }) =>
      ({
        value: id.toString(),
        text: label,
      } || [])
  )
);

/**
 * Study Fields UI list Selector
 */
const banksSelector = createSelector(banksListSelector, (data = []) =>
  data.map(
    ({ value, label }) =>
      ({
        value,
        text: label,
      } || [])
  )
);

/**
 * Study Fields UI list Selector
 */
const districtsSelector = createSelector(districtsListSelector, (data = []) =>
  data.map(
    ({ symbol, name }) =>
      ({
        value: symbol.toString(),
        text: name,
      } || [])
  )
);

export const selectors = {
  academicInstitution: academicInstitutionSelector,
  studyFields: studyFieldsSelector,
  cities: citiesSelector,
  sectors: sectorsSelector,
  banks: banksSelector,

  useLoaders: createHookSelector(loadersSelector),
  useAcademicInstitution: createHookSelector(academicInstitutionSelector),
  useStudyFields: createHookSelector(studyFieldsSelector),
  useCities: createHookSelector(citiesSelector),
  useSectors: createHookSelector(sectorsSelector),
  useBanks: createHookSelector(banksSelector),
  useDistricts: createHookSelector(districtsSelector),
};
