import {
  CheckEligibilityRequest,
  CheckEligibilityFormFields,
  ScholarshipsSelectType,
} from './types';

/**
 * Maps data from UI format to server format
 * @param data User provided data
 * @returns result
 * */
export const mapFromUiToServer = (
  data: CheckEligibilityFormFields
): CheckEligibilityRequest => ({
  identity_number: data.identityNumber,
  phone: data.phone,
  password: data.password,
  residency_status: data.residencyStatus,
  academic_institution_id: Number(data.academicInstitution?.value),
  currently_studying: data.currentlyStudying,
  other_bachelor: data.otherBachelor,
  eligible_for_assistance: data.eligibleForAssistance || undefined,
  scholarships: Object.keys(data?.scholarships || {})?.filter(
    (key) => data.scholarships[key as ScholarshipsSelectType]
  ) as ScholarshipsSelectType[] || [],
  other_scholarship: data.receivedScholarship,
  other_scholarship_name: data.receivedScholarshipName,
  other_scholarship_amount: data.receivedScholarship ? Number(data.receivedScholarshipAmount.replace(',', '')) || '' : '',
  not_deserter: data.notDeserter,
  terms: Number(data.termsConfirm),
  email: data.email,
});
