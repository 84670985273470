import React from 'react';
import * as CUI from '../../CUI';

/**
 * Customized checkbox input
 * @returns React.FC
 * */
export const CheckBox = ({ ...props }) => (
  <CUI.CheckBox
    {...props}

    defaultValue={!!props?.checked}
    inputRender={({ componentProps, Element, elementProps }) => (
      <>
        <Element
          {...elementProps}
          className="inp-cbx form-check-input"
          style={{ display: 'none' }}
        />
        <label className="cbx" htmlFor={elementProps.id}>
          <span >
            <svg width="12px" height="10px" viewBox="0 0 12 10">
              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </svg>
          </span>
        </label>
      </>
    )}
  />
);
