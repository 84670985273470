type FormatCurrencyOptions = {
  separator?: string;
  precision?: number;
};

const defaultOptions: FormatCurrencyOptions = {
  separator: ',',
  precision: 0,
};

/**
 * Formatting currency
 * @param currency sum
 * @param options
 * @returns result
 */
export const formatCurrency = (
  currency: string,
  options = defaultOptions,
): string => {
  if (!currency) {
    return '';
  }

  const { separator = defaultOptions.separator, precision } = options;

  const [integer, fractional = ''] = currency.split('.');

  const formattedInteger = integer.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    `$1${separator}`,
  );

  const precisionedFractional =
    precision !== undefined
      ? Number(`0.${fractional}`).toFixed(precision).split('.')[1] ?? ''
      : fractional;

  return precisionedFractional
    ? `${formattedInteger}.${precisionedFractional}`
    : formattedInteger;
};
