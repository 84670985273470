export const globalConfig = {
  routes: {
    /** Home page */
    main: () => '/',
    /** Check eligibility page */
    checkEligibility: () => '/check-eligibility',
    /** Registration page */
    registration: () => `/registration`,
    /**Personal Area page  */
    personalArea: () => `/personal-area`,
    personalAreaAppealForm: () => `/personal-area-appeal`,
    AppealOfPersonalStatusChange: () => `/personal-area-status-change-appeal`,
    /** Login page */
    login: () => `/login`,
    /** About page*/
    about: () => '/about',
    /** IFrame admin page */
    admin: () => '/admin/:params',
    /**FAQ page */
    faq: () => '/faq',
    scholarshipFaq: () => '/scholarship-faq',
    /**Contact Us page */
    contactUs: () => '/contact',
    /**Accessibility  page */
    accessibility: () => '/accessibility',
    /** notFound */
    notFound: () => '/404',
    /** Student Survey page*/
    studentSurvey: () => '/student-survey',
    /** Print */
    print: () => '/print',
    privacy: () => '/privacy',
    pdf: () => '/pdf'

  },
  storage: {
    tokenKey: 'PerachToken',
    cmsTokeKey: 'jwt-token',
    isAdmin: 'isAdmin',
    userId: 'userId',
    redirectKey: 'redirectKey',
  },
};
