import { request } from '../../../common/request';

const PATH = `${process.env.REACT_APP_API_URL}/core-service/api`;

export const getCurrentTimeApi = async (): Promise<string> => {
  const { data } = await request.get<string>({
    url: `${PATH}/user/get-current-time`,
  });
  return data;
};
