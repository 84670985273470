import { SagaIterator } from 'redux-saga';
import { put, call, all, takeEvery, select, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { globalConfig } from '@/globalConfig';

import { userModel } from '@entities/user';
import { timeModel } from '@entities/time';

import { mapFromUiToServer } from '../mapper';
import { CheckEligibilityResponse, UserStatus } from '../types';
import { resendOtpCode, sendCheckEligibilityForm, sendOtpCode } from './api';
import { actions } from './ducks';
import { selectors } from './selectors';
import { loaderModel } from '@entities/loader';

/**
 * Send user data to be checked for eligibility
 * @returns {void}
 */
function* sendCheckEligibilitySaga(): SagaIterator {
  const userData = yield select(selectors.formData);
  try {
    yield put(loaderModel.actions.setIsLoading(true));
    const response: CheckEligibilityResponse = yield call(
      sendCheckEligibilityForm,
      mapFromUiToServer(userData)
    );
    if (response?.id) {
      yield put(actions.setStatus(UserStatus.CHECKED));
      yield put(actions.setErrors([]));
      yield put(userModel.actions.setProcessId(response.process_id || ''));
      yield put(userModel.actions.setUsername(response.username || ''));
      yield put(userModel.actions.setConfirmSession(response.session || ''));
    }
    // User not pass eligibility
    if (!!response?.error) {
      if (!!response?.error?.errors?.length) {
        yield put(actions.setErrors(response?.error?.errors));
      }
      yield put(actions.setStatus(UserStatus.FAILED));
    }
    // User in black list
    if (response?.error?.statusCode === 206) {
      yield put(actions.setStatus(UserStatus.FAILED));
    }
    // User already registered
    if (response?.error?.statusCode === 203) {
      // @ts-ignore
      yield put(actions.setErrors(['USER_EXIST']));
    }
    yield put(loaderModel.actions.setIsLoading(false));
  } catch (e: any) {
    if (e.response?.data?.error?.statusCode === 400) {
      const message = e?.response?.data?.error?.message || [];
      yield put(actions.setErrors([message]));
      yield put(loaderModel.actions.setIsLoading(false));
    }
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

/**
 * Send user data to be checked for eligibility
 * @returns {void}
 */
function* confirmCheckEligibilityWithOtpSaga(): SagaIterator {
  const username = yield select(userModel.selectors.username);
  const otpCode = yield select(selectors.otpCodeValue);
  const { password } = yield select(selectors.formData);
  try {
    yield put(loaderModel.actions.setIsLoading(true));
    const data = yield call(sendOtpCode, otpCode, username, password);
    if (data.jwtToken) {
      yield delay(0);
      yield call(
        [window.localStorage, window.localStorage.setItem],
        globalConfig.storage.tokenKey,
        data.jwtToken
      );
      yield delay(0);
      yield put(actions.setStatus(UserStatus.CONFIRMED));
      yield put(userModel.actions.setIsLoggedIn(true));
      const isTokenSet = yield call(
        [window.localStorage, window.localStorage.getItem],
        globalConfig.storage.tokenKey
      );
      while (!isTokenSet) {
        yield delay(100);
        console.log('Waiting for token');
      }

      yield delay(100);
      yield put(loaderModel.actions.setIsLoading(false));
      yield put(push(globalConfig.routes.registration()));
    }
  } catch (e: any) {
    if (e.response?.data?.error?.statusCode === 400) {
      const message = e?.response?.data?.error?.message || [];
      yield put(actions.setOtp(''));
      yield put(actions.setErrors([message]));
      yield put(loaderModel.actions.setIsLoading(false));
    }
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

/**
 * Resend the OTP CODE
 * @returns {void}
 */
function* resendOtpSaga(): SagaIterator {
  const username = yield select(userModel.selectors.username);
  const otpCode = yield select(selectors.otpCodeValue);
  const { password } = yield select(selectors.formData);

  yield call(resendOtpCode, username, password);
}

/**
 * Watcher
 * @returns {void}
 */
function* watcher(): SagaIterator<void> {
  yield all([
    takeEvery(actions.checkUserEligibility, sendCheckEligibilitySaga),
    takeEvery(actions.sendOtpCode, confirmCheckEligibilityWithOtpSaga),
    takeEvery(actions.resendOtpCode, resendOtpSaga),
  ]);
}

export const sagas = {
  watcher,
};
