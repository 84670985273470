import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  stepThreeFields,
  stepTwoFields,
  stepOneFields,
  stepFourFields,
} from '@common/text/fields';

import { selectors } from './model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { HelpKeyType } from '@entities/settings/types';

type Props = {
  fieldName: HelpKeyType;
};
export const HelpText: React.FC<Props> = ({ fieldName }) => {
  const { t } = useTranslation();
  const fieldsList = selectors.useFieldsHelp();

  if (!fieldsList || fieldsList.length === 0) {
    return <></>;
  }

  const targetField = fieldName.includes('@@')
    ? fieldName.split('@@')?.[0]
    : fieldName;

  const field = fieldsList?.find((el) => el.title === targetField);

  if (!field?.show || !field.description) {
    return <></>;
  }

  const MySwal = withReactContent(Swal);

  const handleHTMLAlert = () => {
    return MySwal.fire({
      // title: '<strong>HTML <u>example</u></strong>',
      icon: 'question',
      iconColor: '#6e19f0',
      html: field.description,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: (
        <span className="align-middle">
          <span className="align-middle">סגור</span>
        </span>
      ),
      customClass: {
        confirmButton: 'button-wrapper btn-primary-perach',
        // cancelButton: 'btn btn-outline-danger ms-1'
      },
      buttonsStyling: false,
    });
  };
  return (
    <div
      onClick={() => handleHTMLAlert()}
      className={'label-success cursor-pointer'}
    >
      {t('HELP')}
      <FontAwesomeIcon icon={faQuestionCircle} className="mr-8" />
    </div>
  );
};
