import { utils } from '@CUI';
import {
  AdditionalInfo,
  AdditionalInfoRequest,
  Address,
  AddressRequest,
  BankDetails,
  BankDetailsRequest,
  DisabilityDocumentType,
  DisabledPersonRequest,
  DocumentsForm,
  DocumentsRequest,
  FamilyMembersType,
  getUserDocumentsResponse,
  MaritalStatusType,
  MilitaryType,
  OneOrBothParentsType,
  PersonalInfo,
  PersonalInfoRequest,
  PrefilledDocuments,
  PrefillFileType,
  UserPersonalDataResponse,
} from './types';
import { createFamilyMember, familyIncomeDefaults } from './defaults';

const addressMapper = (address: Address): AddressRequest => ({
  city: address.city.value || '',
  street: address.street,
  apartment_number: address.apartment,
  house_number: address.home,
});

/**
 * Maps User Personal data from UI format to server format
 * @param data User provided data
 * @returns result
 * */
export const mapPersonalInfoFromUiToServer = (
  data: PersonalInfo
): PersonalInfoRequest => {
  const parsedDate = data?.birthday?.split('.') || null;
  const getDate = () => {
    if (parsedDate === null) return null;
    return `${parsedDate[1]}.${parsedDate[0]}.${parsedDate[2]}`;
  };
  return {
    first_name: data.firstName,
    last_name: data.lastName,
    gender: data.gender,
    phone: data.phone,
    additional_phone: data.phoneAdditional,
    // email: data.email,
    birthdate: getDate() || null, //data.birthday?.toISOString() || null, //date?.toLocaleString(),
    //identity_number: data.userId,

    city_symbol: Number(data.addressById.city.value) || '',
    house_number: data.addressById.home,
    street: data.addressById.street,
    apartment_number: data.addressById.apartment,

    study_city_symbol: Number(data.addressWhileStudying.city.value) || '',
    study_street: data.addressWhileStudying.street,
    study_house_number: data.addressWhileStudying.home,
    study_apartment_number: data.addressWhileStudying.apartment,

    //educational_city_id: Number(data?.educationDetails?.city?.value) || '',
    //sector_id: Number(data.sector.value) || '',
    is_ba: data?.educationDetails?.isBachelor,
    another_field_of_study: data?.educationDetails?.otherField,
    additional_field_of_study_id:
      Number(data?.educationDetails?.additionalField.value) || '',
    study_year_number: data?.educationDetails?.year.value || '',
    field_of_study_id: Number(data?.educationDetails?.field.value) || '',
    military_service_type: data.militaryServiceType.value || '',
    // value_exemption:
    //   data?.militaryServiceType.value === 'EXEMPTION'
    //     ? data?.valueExemption
    //     : null,
    //learning_disability_certificate: data?.learningDisabilityCertificate,
    service_month: data?.serviceMoth ? Number(data.serviceMoth) : '',
    is_educational_student: data?.isEducationalStudent,
    additional_another_field_of_study:
      data?.educationDetails?.additionalAnotherFieldOfStudy || '',
    birthdate_hebrew: data?.birthdayHebrew || '',
    age_18_city_symbol: !data?.isUnder18address
      ? Number(data?.under18City?.value) || ''
      : '',
    age_18_neighborhood_symbol: !data?.isUnder18address
      ? Number(data?.under18District?.value) || ''
      : '',
    age_18_house_number: !data?.isUnder18address ? data?.under18Home || '' : '',
    age_18_street: !data?.isUnder18address ? data?.under18Street || '' : '',
    age_18_not_israel: data?.isUnder18address || false,
    age_18_correctness_information: data?.under18Confirm || false,
    days_in_reserve: data?.daysInReserve || '',
    is_reservist: data?.isReservist || false,
  };
};

/**
 * Maps User bank details from UI format to server format
 * @param data User provided data
 * @returns result
 * */
export const mapBankDetailsFromUiToServer = (
  data: BankDetails
): BankDetailsRequest => ({
  bank_name: data.bank.value || '',
  branch_number: data.branch,
  account_number: data.accountNumber,
});

/**
 * Maps User additional Info from UI format to server format
 * @param data User provided data
 * @returns result
 * */
export const mapAdditionalInfoFromUiToServer = (
  data: AdditionalInfo
): AdditionalInfoRequest => {
  const date = new Date(
    typeof data.repatriationDate === 'string'
      ? data?.repatriationDate.split('.').reverse().join('/')
      : 0
  );
  return {
    family_status: data.maritalStatus.value || '',
    family_status_gap: data.isMaritalStatusGap,
    family_status_gap_details: data.isMaritalStatusGap
      ? data.maritalStatusGap
      : undefined,
    identity_children: Number(data.childrenCount) || 0,
    identity_children_gap: data.isChildrenCountGap,
    identity_children_gap_details: data.isChildrenCountGap
      ? data.childrenCountGap
      : undefined,
    brothers_students_under_30: Number(data.siblingsStudentsCount) || 0,
    brothers_under_24: Number(data.siblingsCount) || 0,
    new_immigrant: data.isNewcomer,
    immigrant_date: date,
    is_orphan: data.isOrphan,
    orphan_from: data.isOrphan ? data.orphanOfParentsCount.value : undefined,
    is_disconnected_from_parents: data.isCutOfFromParents,
    disconnected_from: data.isCutOfFromParents
      ? data.cutOfFromParentsCount.value
      : undefined,
    is_single_parent: data.isSingleParent,
    is_family_member_disabled: data.isDisabledFamilyMembers,
    family_members: data.isDisabledFamilyMembers
      ? data.disabledFamilyMembers?.map((el) => ({
          family_member: el.type.value as string,
          disability_percentage: el.percent ? Number(el.percent) : '',
        })) || []
      : [],
    // tuition_wage: Number(data.tuitionCost) || 0,
    // tuition_finances_by_work: data.isFundedByEmployer,
    // financing_amount: data.isFundedByEmployer
    //   ? Number(data.amountOfFinancing)
    //   : 0,
  };
};

/**
 * Maps User additional Info from UI format to server format
 * @param data User provided data
 * @returns result
 * */
export const mapAdditionalInfoFromServerToUi = (
  data: AdditionalInfoRequest
): AdditionalInfo => {
  // const date = new Date(
  //     typeof data.repatriationDate === 'string'
  //         ? data?.repatriationDate.split('.').reverse().join('/')
  //         : 0
  // );
  const familyArray: DisabledPersonRequest[] =
    typeof data.family_members === 'string'
      ? JSON.parse(data.family_members)
      : data.family_members;

  return {
    maritalStatus: {
      value:
        (data.family_status as MaritalStatusType) || MaritalStatusType.SINGLE,
    },
    isMaritalStatusGap: data.family_status_gap || false,
    maritalStatusGap: data.family_status_gap_details || '',
    childrenCount: data.identity_children?.toString() || '',
    isChildrenCountGap: data.identity_children_gap || false,
    childrenCountGap: data.identity_children_gap_details || '',
    siblingsStudentsCount: data.brothers_students_under_30?.toString() || '',
    siblingsCount: data.brothers_under_24?.toString() || '',
    isNewcomer: data.new_immigrant || false,
    repatriationDate:
      data?.immigrant_date
        ?.toString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('.') || null,

    isOrphan: data.is_orphan || false,
    orphanOfParentsCount: data?.is_orphan
      ? { value: data.orphan_from as OneOrBothParentsType, text: '' }
      : { value: undefined, text: undefined },

    isCutOfFromParents: data.is_disconnected_from_parents || false,
    cutOfFromParentsCount: data?.is_disconnected_from_parents
      ? { value: data.disconnected_from as OneOrBothParentsType, text: '' }
      : { value: undefined, text: undefined },

    isSingleParent: data.is_single_parent || false,

    isDisabledFamilyMembers: data.is_family_member_disabled || false,
    disabledFamilyMembers: familyArray?.length
      ? familyArray?.map((el: DisabledPersonRequest) => ({
          id: utils.generateId(),
          type: { value: el.family_member as FamilyMembersType, text: '' } || {
            value: undefined,
            text: undefined,
          },
          percent: el.disability_percentage?.toString() || '',
        }))
      : [createFamilyMember()],

    // tuitionCost: data.tuition_wage?.toString() || '',
    // isFundedByEmployer: data.tuition_finances_by_work || false,
    // amountOfFinancing: data.financing_amount?.toString() || '',
  };
};

export const mapBankDetailsFromServerToUi = (
  data: BankDetailsRequest
): BankDetails => ({
  bank: {
    value: data.bank_name || '',
    text: '',
  },
  branch: data.branch_number || '',
  accountNumber: data.account_number || '',
});

export const mapUserPersonalInfoFromServerToUi = (
  data: UserPersonalDataResponse
): Omit<
  PersonalInfo,
  'phone' | 'userId' | 'email' | 'emailConfirm' | 'addressesMatch'
> => ({
  birthday:
    data?.birthdate?.split('T')[0].split('-').reverse().join('.') || null,
  firstName: data.first_name || '',
  lastName: data.last_name || '',
  gender: data.gender || 'MALE',
  phoneAdditional: data.additional_phone || '',
  //valueExemption: data.value_exemption || false,
  militaryServiceType: {
    text: '',
    value: data.military_service_type as MilitaryType,
  },
  addressById: {
    home: data.house_number || '',
    street: data.street || '',
    apartment: data.apartment_number || '',
    city: {
      text: data.city?.city_name || '',
      value: data.city?.city_symbol?.toString() || '',
    },
  },
  addressWhileStudying: {
    home: data.study_house_number || '',
    street: data.study_street || '',
    apartment: data.study_apartment_number || '',
    city: {
      text: data.study_city?.city_name || '',
      value: data.study_city?.city_symbol?.toString() || '',
    },
  },
  educationDetails: {
    // city: {
    //   text: data.educational_city?.city_name || '',
    //   value: data.educational_city_id?.toString() || '',
    // },
    field: {
      text: data.field_of_study?.label || '',
      value: data.field_of_study?.id?.toString(),
    },
    year: { text: '', value: data.study_year_number || '' },
    isBachelor: data.is_ba || false,
    additionalField: {
      text: '',
      value: data.additional_field_of_study_id?.toString(),
    },
    otherField: data.another_field_of_study || '',
    additionalAnotherFieldOfStudy: data.additional_another_field_of_study || '',
  },
  serviceMoth: data?.service_month?.toString() || '',
  //learningDisabilityCertificate: data?.learning_disability_certificate || false,
  birthdayHebrew: data?.birthdate_hebrew || '',
  isUnder18address: data?.age_18_not_israel || false,
  under18Confirm: data?.age_18_correctness_information || false,
  under18District: {
    text: '',
    value: data?.age_18_neighborhood_symbol?.toString() || '',
  },
  under18Home: data?.age_18_house_number || '',
  under18Street: data?.age_18_street || '',
  under18City: { text: '', value: data?.age_18_city_symbol?.toString() || '' },
  // emailTouched: false,
  isEducationalStudent: data?.is_educational_student || false,
  isReservist: data?.is_reservist || false,
  daysInReserve: data?.days_in_reserve || '',
});

export const mapDocumentsFromUiToServer = (
  data: DocumentsForm
): DocumentsRequest => ({
  is_identity_biometric: data.isIdentityBiometric || false,
  front_identity: data.frontIdentity || '',
  back_identity: data.backIdentity || '',
  attachment_identity: data.attachmentIdentity || '',
  parent_one_is_identity_biometric: data.parentOneIsIdentityBiometric || false,
  parent_one_front_identity: data.parentOneFrontIdentity || '',
  parent_one_back_identity: data.parentOneBackIdentity || '',
  parent_one_attachment_identity: data.parentOneAttachmentIdentity || '',
  parent_one_death_certificate: data.parentOneDeathCertificate || '',
  parent_two_is_identity_biometric: data.parentTwoIsIdentityBiometric || false,
  parent_two_front_identity: data.parentTwoFrontIdentity || '',
  parent_two_back_identity: data.parentTwoBackIdentity || '',
  parent_two_attachment_identity: data.parentTwoAttachmentIdentity || '',
  parent_two_death_certificate: data.parentTwoDeathCertificate || '',
  confirmation_studies: data.confirmationStudies || '',
  // schedule: data.schedule || '',
  // learning_disability_certificate: data.learningDisabilityCertificate || '',
  approval_annual_tuition_fees: data.approvalAnnualTuitionFees || '',
  approval_study_brothers_under_30:
    data.approvalStudyBrothersUnder30?.map((el) => ({
      allowance: null,
      family_member: null,
      income_type: null,
      document: el,
    })) || [],
  bank_account_reference: data.bankAccountReference || '',
  family_documents:
    data.familyDocuments?.map((el) => ({
      income_type: el.incomeType.value || '',
      document: el.document,
      family_member: el.member_type,
      allowance: el.allowance.value || '',
    })) || [],
  is_another_income: !!data.otherIncomeFile && !!data.otherIncomeDetails,
  other_income_details: data.otherIncomeDetails || '',
  other_income_file: data.otherIncomeFile || '',
  approve_incomes: data.approveIncomes || false,
  army_type_document: data.armyTypeDocument || '',
  reservist_document: data.reservistDocument || '',
  newcomer_document: data.newcomerDocument || '',
  single_parent_approval: data.singleParentApproval || '',
  disability_documents:
    data.disabilityDocuments?.flatMap((el) =>
      !!el.memberType && !!el.document
        ? {
            family_member: el.memberType || '',
            national_confirmation: el.document || '',
          }
        : []
    ) || [],
  is_additional_document: !!data.additionalDocument || false,
  additional_document: data.additionalDocument || '',
  declaration_correctness_information:
    data.declarationCorrectnessInformation || false,
  parent_one_disconnect_permission: '',
  parent_two_disconnect_permission: '',
});

type ServerFileType = { file_name?: string; file_signed_url?: string } | null;

const mapFile = (doc: ServerFileType): PrefillFileType | undefined =>
  (!doc?.file_name && !doc?.file_signed_url) || doc === null
    ? undefined
    : {
        url: doc?.file_signed_url || undefined,
        filename: doc?.file_name || undefined,
      };

const mapFileToStore = (doc: ServerFileType): string =>
  !doc?.file_name ? '' : doc?.file_name;

const ifUndefinedThenTrue = (val: boolean | undefined): boolean =>
  val === undefined ? true : !!val;

export const mapPrefilledDocumentsFromServerToUI = (
  data: getUserDocumentsResponse
): PrefilledDocuments => ({
  isIdentityBiometric: ifUndefinedThenTrue(data?.is_identity_biometric),
  parentOneIsIdentityBiometric: ifUndefinedThenTrue(
    data?.parent_one_is_identity_biometric
  ),
  parentTwoIsIdentityBiometric: ifUndefinedThenTrue(
    data?.parent_two_is_identity_biometric
  ),
  frontIdentity: mapFile(data.front_identity),
  backIdentity: mapFile(data.back_identity),
  attachmentIdentity: mapFile(data.attachment_identity),
  parentOneFrontIdentity: mapFile(data.parent_one_front_identity),
  parentTwoFrontIdentity: mapFile(data.parent_two_front_identity),
  parentOneBackIdentity: mapFile(data.parent_one_back_identity),
  parentTwoBackIdentity: mapFile(data.parent_two_back_identity),
  parentOneAttachmentIdentity: mapFile(data.parent_one_attachment_identity),
  parentTwoAttachmentIdentity: mapFile(data.parent_two_attachment_identity),
  parentOneDeathCertificate: mapFile(data.parent_one_death_certificate),
  parentTwoDeathCertificate: mapFile(data.parent_two_death_certificate),
  confirmationStudies: mapFile(data.confirmation_studies),
  // schedule: mapFile(data.schedule),
  // learningDisabilityCertificate: mapFile(data.learning_disability_certificate),
  approvalAnnualTuitionFees: mapFile(data.approval_annual_tuition_fees),
  bankAccountReference: mapFile(data.bank_account_reference),
  familyDocuments:
    data.family_documents?.map(({ document }) => ({
      allowance: { value: document?.allowance || '', text: '' },
      document: mapFile(document) || undefined,
      member_type: document?.family_member as FamilyMembersType,
      incomeType: { value: document?.income_type || '', text: '' },
    })) || familyIncomeDefaults,
  otherIncomeDetails: data.other_income_details || '',
  otherIncomeFile: mapFile(data.other_income_file),
  approvalStudyBrothersUnder30:
    data.approval_study_brothers_under_30?.map(({ document }) => {
      return mapFile(document);
    }) || [],
  newcomerDocument: mapFile(data.newcomer_document),
  singleParentApproval: mapFile(data.single_parent_approval),
  armyTypeDocument: mapFile(data.army_type_document),
  reservistDocument: mapFile(data.reservist_document),
  additionalDocument: mapFile(data.additional_document),
  disabilityDocuments:
    data.disability_documents?.map(({ document }) => ({
      memberType: document?.family_member as FamilyMembersType,
      document: mapFile(document) || undefined,
    })) || undefined,
});

export const mapPrefilledDocumentsFromServerToStore = (
  data: getUserDocumentsResponse
): DocumentsForm => {
  const result = {
    isIdentityBiometric: ifUndefinedThenTrue(data?.is_identity_biometric),
    parentOneIsIdentityBiometric: ifUndefinedThenTrue(
      data?.parent_one_is_identity_biometric
    ),
    parentTwoIsIdentityBiometric: ifUndefinedThenTrue(
      data?.parent_two_is_identity_biometric
    ),
    frontIdentity: mapFileToStore(data.front_identity),
    backIdentity: mapFileToStore(data.back_identity),
    attachmentIdentity: mapFileToStore(data.attachment_identity),
    parentOneFrontIdentity: mapFileToStore(data.parent_one_front_identity),
    parentTwoFrontIdentity: mapFileToStore(data.parent_two_front_identity),
    parentOneBackIdentity: mapFileToStore(data.parent_one_back_identity),
    parentTwoBackIdentity: mapFileToStore(data.parent_two_back_identity),
    parentOneAttachmentIdentity: mapFileToStore(
      data.parent_one_attachment_identity
    ),
    parentTwoAttachmentIdentity: mapFileToStore(
      data.parent_two_attachment_identity
    ),
    parentOneDeathCertificate: mapFileToStore(
      data.parent_one_death_certificate
    ),
    parentTwoDeathCertificate: mapFileToStore(
      data.parent_two_death_certificate
    ),
    confirmationStudies: mapFileToStore(data.confirmation_studies),
    // schedule: mapFileToStore(data.schedule),
    // learningDisabilityCertificate: mapFileToStore(
    //   data.learning_disability_certificate
    // ),
    approvalAnnualTuitionFees: mapFileToStore(
      data.approval_annual_tuition_fees
    ),
    bankAccountReference: mapFileToStore(data.bank_account_reference),
    familyDocuments:
      data.family_documents && !!data?.family_documents?.length
        ? data?.family_documents?.map(
            ({ document, income_type, allowance }) => {
              return {
                allowance: { value: allowance || '', text: '' },
                document: mapFileToStore(document),
                member_type: document?.family_member as FamilyMembersType,
                incomeType: { value: income_type || '', text: '' },
              };
            }
          )
        : familyIncomeDefaults,
    otherIncomeDetails: data.other_income_details || '',
    otherIncomeFile: mapFileToStore(data.other_income_file),
    approvalStudyBrothersUnder30:
      data.approval_study_brothers_under_30?.map(({ document }) =>
        mapFileToStore(document)
      ) || [],
    newcomerDocument: mapFileToStore(data.newcomer_document),
    singleParentApproval: mapFileToStore(data.single_parent_approval),
    armyTypeDocument: mapFileToStore(data.army_type_document),
    reservistDocument: mapFileToStore(data.reservist_document),
    additionalDocument: mapFileToStore(data.additional_document),
    disabilityDocuments:
      data?.disability_documents?.length === 1 &&
      data?.disability_documents?.[0].document === null
        ? []
        : data.disability_documents?.map(({ document }) => ({
            memberType: document?.family_member as FamilyMembersType,
            document: document !== null ? mapFileToStore(document) : undefined,
          })) || undefined,
    declarationCorrectnessInformation:
      !!data.declaration_correctness_information,
    approveIncomes: !!data.approve_incomes,
    // parentTwoIsIdentityBiometric: !!data.parent_two_back_identity,
    // parentOneIsIdentityBiometric: !!data.parent_one_back_identity,
    // isIdentityBiometric: !!data.back_identity,
    documentPath: '',
    isAnotherIncome: !!data.other_income_file,
  };
  return result;
};
