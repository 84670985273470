import {
  createHookSelector,
  createModelFieldSelector,
} from '../../../common/store-utils';

import {config} from '../config';

import {SettingModel} from '../types';
import {createSelector} from "@reduxjs/toolkit";

/**
 * Selector of model State
 */
const fieldSelector = createModelFieldSelector<SettingModel>(config.modelName);

/**
 * Register period selector
 */
const registerPeriodSelector = fieldSelector('registerPeriod');

/**
 * All Register periods selector
 */
const registerPeriodsListSelector = fieldSelector('registrationPeriods');

/**
 * Current register period start date selector
 */
const registerPeriodStartDateSelector = createSelector(
  registerPeriodSelector,
  registerPeriodsListSelector,
  (val, list) => list?.find((el) => el.id === val)?.from || '');

/**
 * Current register period start date selector
 */
const isRegisterPeriodOpenSelector = createSelector(
  registerPeriodSelector,
  registerPeriodsListSelector,
  (val, list) => list?.find((el) => el.id === val)?.is_open || false);

const isAppealsOpenSelector = createSelector(
  registerPeriodSelector,
  registerPeriodsListSelector,
  (val, list) => list?.find((el) => el.id === val)?.is_appeals_open || false);

const isAppealsFromSelector = createSelector(
  registerPeriodSelector,
  registerPeriodsListSelector,
  (val, list) => {
    const currentDate = new Date();
    const appealsFrom = list?.find((el) => el.id === val)?.appeals_from || undefined;

    if (!!appealsFrom) {
      const targetDate = new Date(appealsFrom)
      return currentDate > targetDate
    }
    return false;
  });

const fieldsHelpSelector = fieldSelector('fieldsHelp');

export const selectors = {
  registerPeriod: registerPeriodSelector,
  registerPeriodStartDate: registerPeriodStartDateSelector,
  isRegisterPeriodOpen: isRegisterPeriodOpenSelector,
  isAppealsOpen: isAppealsOpenSelector,
  fieldsHelp: fieldsHelpSelector,

  useRegisterPeriod: createHookSelector(registerPeriodSelector),
  useRegisterPeriodStartDate: createHookSelector(registerPeriodStartDateSelector),
  useIsRegisterPeriodOpen: createHookSelector(isRegisterPeriodOpenSelector),
  useIsAppealsOpen: createHookSelector(isAppealsOpenSelector),
  useIsAppealsFrom: createHookSelector(isAppealsFromSelector),
  useFieldsHelp: createHookSelector(fieldsHelpSelector),
};
