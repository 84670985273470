import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

import { utils } from '../../CUI';
import { Branch } from '../ui';

type Step = {
  isActive: boolean;
  isDone: boolean;
  iconRender: JSX.Element;
  label?: string;
  shouldSkip?: boolean;
  onClick?: () => void;
};

type StepperProps = {
  steps: Step[];
};

/**
 * Customizable Stepper component
 * @returns React.FC
 * */
export const Stepper: React.FC<StepperProps> = ({ steps }) => (
  <div className="stepper-container">
    {steps.filter(el => !el.shouldSkip).map((el, i, a) => (
      <div className="step-container" key={utils.generateId()}>
        {i !== a.length - 1 && (
          <div className="step-connector-container">
            <span className="step-connector" />
          </div>
        )}
        <div className="step-wrapper">
          <div
            className={`step-outer-circle ${
              el.isActive && 'step-border-active'
            } ${el.isDone && 'step-border-done'}`}
          >
            <button
              className={`step-button ${
                (el.isActive || el.isDone) && 'step-bg-active'
              }`}

              onClick={() => !!el?.onClick ? el.onClick() : undefined}
            >
              <span className="step-button-content completed">
                <Branch when={el.isDone}>
                  <Branch.If>
                    <FontAwesomeIcon icon={faCheck} />
                  </Branch.If>
                  <Branch.Otherwise>{el.iconRender}</Branch.Otherwise>
                </Branch>
              </span>
            </button>
          </div>
          {el.label && <div className="step-label">{el.label}</div>}
        </div>
      </div>
    ))}
  </div>
);
