import { Expose } from 'class-transformer';

export type DicRecord<T = string> = { value?: T; text?: string };

export enum Dictionaries {
  AcademicInstitution = 'AcademicInstitution',
  StudyFields = 'StudyFields',
  Cities = 'Cities',
  Sector = 'Sector',
  Banks = 'Banks',
  Districts = 'Districts'
}

export type InputSelectType = {
  value: number;
  label: string;
};

export type AcademicInstitutionType = {
  id: number;
  name: string;
  address: string;
  is_priority_area: boolean;
  is_active: boolean;
};

export type StudyFieldsType = {
  id: number;
  label: string;
  value: string;
  is_active: boolean;
};

export type CitiesType = {
  city_symbol: number;
  settlement_symbol: number;
  city_name: string;
  district_name: string;
  council: string;
  cluster: number;
  zip: number;
};

export type SectorType = {
  id: number;
  label: string;
  value: string;
  is_active: boolean;
};

export type BankType = {
  id: number;
  label: string;
  value: string;
  is_active: boolean;
};

export type DistrictType = {
  symbol: number;
  city_symbol: number;
  name: string;
};

/** available dictionaries*/
export type DictionariesModel = {
  [Dictionaries.AcademicInstitution]: Array<AcademicInstitutionType>;
  [Dictionaries.StudyFields]: Array<StudyFieldsType>;
  [Dictionaries.Cities]: Array<CitiesType>;
  [Dictionaries.Sector]: Array<SectorType>;
  [Dictionaries.Banks]: Array<BankType>;
  [Dictionaries.Districts]: Array<DistrictType>;
  loaders: {
    [Dictionaries.Districts]: boolean;
  }
};
