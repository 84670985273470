import { createSelector } from '@reduxjs/toolkit';
import { RouterState } from 'connected-react-router';

import { createHookSelector, createModelSelector } from '../../../common/store-utils';

import { config } from '../config';

/**
 * Селектор состояния модели
 */
const modelSelector = createModelSelector<RouterState>(config.modelName);

/**
 * Получение URL страницы
 */
const pathNameSelector = createSelector(
  modelSelector,
  (router): string => router.location.pathname ?? '/',
);

/**
 * Получение якорной ссылки
 */
const hashSelector = createSelector(
  modelSelector,
  (router): string => router.location.hash ?? '',
);

/**
 * Получение query-params в виде объекта
 */
const queryParamsSelector = createSelector(
  modelSelector,
  (router): Record<string, string> => (router.location as any).query ?? {},
);

/**
 * Получение query-params в виде строки
 */
const searchStringSelector = createSelector(
  modelSelector,
  (router): string => router.location.search ?? '',
);

export const selectors = {
  pathName: pathNameSelector,
  hash: hashSelector,
  queryParams: queryParamsSelector,
  searchString: searchStringSelector,

  useQueryParams: createHookSelector(queryParamsSelector),
};
