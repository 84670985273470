import React from 'react';
import {useTranslation} from 'react-i18next';
import * as CUI from '../../CUI';
import {HelpText, HelpKeyType} from "@entities/settings";

/**
 * Input Label with help mark
 * @returns React.FC
 * */
export const LabelWithPopup: React.FC<{ label: string; helpKey: string }> = ({
                                                                               label,
                                                                               helpKey,
                                                                             }) => {
  const {t} = useTranslation();
  return (
    <CUI.Label _labelPerach>
      <CUI.Div _labelMore>
        <div>{label}</div>

        <HelpText fieldName={helpKey as HelpKeyType}/>
      </CUI.Div>
    </CUI.Label>
  );
};
