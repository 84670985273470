import { createSlice } from '@reduxjs/toolkit';

import { storeUtils } from '../../../common';
import { config } from '../config';
import {ModelState} from "../types";

const initialState: ModelState = {
  currentTime: '',
};

const createAction = storeUtils.createActionCreatorWithPrefix(config.modelName);

/**
 * Action for getting all dictionaries
 */
const getTime = createAction('getTime');

export const createTimeSlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    setTime: storeUtils.setStoreField('currentTime'),

    reset: () => initialState,
  },
});

export const { reducer } = createTimeSlice;

export const actions = {
  ...createTimeSlice.actions,
  getTime,
};
