import {
  stepOneFields as stepOne,
  stepTwoFields as stepTwo,
  stepThreeFields as stepThree,
  stepFourFields as stepFour
} from '@common/text/fields'

export const stepOneFields = stepOne;

export const stepTwoFields = stepTwo;

export const stepThreeFields = stepThree;

export const stepFourFields = stepFour;
