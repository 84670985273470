import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import * as CUI from '@CUI';
import { Optional } from '@common/ui';
import { CheckBox, LabelWithPopup } from '@common/perach-ui';
import { useActions } from '@common/store-utils/hooks';

import { FileInput, OnLoadCallbackType } from '@entities/file';
import { fixValue } from '@entities/dictionaries';
import { getYearLib } from '@entities/time';

import {
  FamilyDocumentType,
  FamilyMembersType,
  MaritalStatusType,
  OneOrBothParentsType,
  PrefillFileType,
} from '../types';
import { actions, selectors } from '../model';
import { useIsUserUnder30 } from '../hooks';
import { stepFourFields } from '../fields';
import { config } from '../config';

const Divider: React.FC<{ label: string }> = ({ label }) => (
  <Row>
    <Col>
      <CUI.Div _formDividerContainer>
        <CUI.Span>{label}</CUI.Span>
      </CUI.Div>
    </Col>
  </Row>
);

type InputElementProps = {
  dividerText: string;
  incomeTypeText: string;
  isDisabled: boolean;
  familyMemberType: FamilyMembersType;
  documentData: FamilyDocumentType;
  documentIndex: number;
  prefilledDocumentData: PrefillFileType | undefined;
  isFixesNeed?: boolean;
  fixesText?: string;
  isRequired?: boolean;
};
const IncomeElement: React.FC<InputElementProps> = ({
  dividerText,
  incomeTypeText,
  familyMemberType,
  isDisabled,
  isFixesNeed,
  fixesText,
  documentData,
  prefilledDocumentData,
  documentIndex,
  isRequired,
}) => {
  const { t } = useTranslation();
  const {
    updateFamilyIncomeDocument,
    updatePrefilledFamilyIncomeDocument,
    autoSaveRegistration,
  } = useActions(actions);
  const INCOME_TYPE_OPTIONS = [
    { value: 'EMPLOYEE', text: t('DOCUMENTS.INCOME_TYPES.EMPLOYEE') },
    { value: 'INDEPENDENT', text: t('DOCUMENTS.INCOME_TYPES.INDEPENDENT') },
    { value: 'NOT_WORK', text: t('DOCUMENTS.INCOME_TYPES.NOT_WORK') },
    { value: 'PENSION', text: t('DOCUMENTS.INCOME_TYPES.PENSION') },
    { value: 'ALIMONY', text: t('DOCUMENTS.INCOME_TYPES.ALIMONY') },
    {
      value: 'NATIONAL_INSURANCE',
      text: t('DOCUMENTS.INCOME_TYPES.NATIONAL_INSURANCE'),
    },
    { value: 'OTHER', text: t('DOCUMENTS.INCOME_TYPES.OTHER') },
  ];

  const ALLOWANCE_OPTIONS = [
    {
      value: 'INCOME_SECURITY',
      text: t('DOCUMENTS.ALLOWANCE_OPTIONS.INCOME_SECURITY'),
    },
    {
      value: 'NATIONAL_INSURANCE',
      text: t('DOCUMENTS.ALLOWANCE_OPTIONS.NATIONAL_INSURANCE'),
    },
    {
      value: 'SENIOR_BENEFIT',
      text: t('DOCUMENTS.ALLOWANCE_OPTIONS.SENIOR_BENEFIT'),
    },
    { value: 'PENSION', text: t('DOCUMENTS.ALLOWANCE_OPTIONS.PENSION') },
    { value: 'INJURY', text: t('DOCUMENTS.ALLOWANCE_OPTIONS.INJURY') },
    { value: 'NEWBORN', text: t('DOCUMENTS.ALLOWANCE_OPTIONS.NEWBORN') },
    // { value: 'FAMILY', text: t('DOCUMENTS.ALLOWANCE_OPTIONS.FAMILY') },
    {
      value: 'UNEMPLOYMENT',
      text: t('DOCUMENTS.ALLOWANCE_OPTIONS.UNEMPLOYMENT'),
    },
    { value: 'NURSING', text: t('DOCUMENTS.ALLOWANCE_OPTIONS.NURSING') },
    { value: 'REMAINING', text: t('DOCUMENTS.ALLOWANCE_OPTIONS.REMAINING') },
    { value: 'DISABILITY', text: t('DOCUMENTS.ALLOWANCE_OPTIONS.DISABILITY') },
    { value: 'OTHER', text: t('DOCUMENTS.ALLOWANCE_OPTIONS.OTHER') },
  ];

  // TODO: fix later
  // only for first several months it should be - 2021
  // then, always previous year
  // const incomeYear = getYearLib.getPreviousYear();
  const incomeYear = '2022';
  const getIncomeYear = (type: string | undefined) =>
    type === 'EMPLOYEE' || type === 'INDEPENDENT'
      ? incomeYear
      : getYearLib.getCurrentYear();

  return (
    <>
      <Divider label={dividerText} />
      <Row className="g-2 gx-5">
        <CUI.Span
          _txtBold
          _txtDanger
          shouldRender={familyMemberType === FamilyMembersType.SPOUSE}
        >
          אם אינך בקשר עם בן/בת זוג - יש להעלות הצהרת עו"ד
        </CUI.Span>
        <Col className="col-12 col-md-6">
          <LabelWithPopup
            label={incomeTypeText}
            helpKey={stepFourFields.incomeDocument}
          />
          <CUI.DropDownSelect
            name={`income@@${familyMemberType}`}
            isDisabled={isDisabled}
            form={config.forms.documents}
            shouldFilterValues
            data={INCOME_TYPE_OPTIONS}
            textField="text"
            placeholder=" "
            value={fixValue(documentData.incomeType || {}, INCOME_TYPE_OPTIONS)}
            onChange={(e) => {
              updateFamilyIncomeDocument({
                memberIndex: documentIndex,
                changes: {
                  incomeType: e.component.value,
                },
              });
            }}
            isRequired={!!isRequired}
          />
        </Col>

        <Optional when={documentData.incomeType.value !== 'NATIONAL_INSURANCE'}>
          <Col className="col-12 col-md-6">
            <FileInput
              // label={`${t('DOCUMENTS.LABELS.INCOME_DOCUMENT')} ${getIncomeYear(
              //   documentData.incomeType.value
              // )}`}
              label={'המסמך'}
              name={`document@@${familyMemberType}`}
              form={config.forms.documents}
              isRequired={!!isRequired}
              isDisabled={isDisabled}
              helpText={t('DOCUMENTS.HELP.STUDENT_INCOME')}
              isFixesNeed={isFixesNeed}
              fixesText={fixesText}
              prefilled={prefilledDocumentData}
              onLoad={({ fileName }) => {
                updateFamilyIncomeDocument({
                  memberIndex: documentIndex,
                  changes: {
                    document: fileName,
                  },
                });
              }}
              onDelete={({ fileName }) => {
                updateFamilyIncomeDocument({
                  memberIndex: documentIndex,
                  changes: {
                    document: '',
                  },
                });
                updatePrefilledFamilyIncomeDocument({
                  memberIndex: documentIndex,
                  changes: {
                    document: {
                      url: '',
                      filename: '',
                    },
                  },
                });
                autoSaveRegistration();
              }}
            />
          </Col>
        </Optional>

        <Optional when={documentData.incomeType.value === 'NATIONAL_INSURANCE'}>
          <Col className="col-12 col-md-6 mb-40">
            <LabelWithPopup
              label={t('DOCUMENTS.LABELS.ALLOWANCE_TYPE')}
              helpKey={stepFourFields.allowanceType}
            />
            <CUI.DropDownSelect
              name={`allowanceType@@${familyMemberType}`}
              form={config.forms.documents}
              isDisabled={isDisabled}
              shouldFilterValues
              data={ALLOWANCE_OPTIONS}
              textField="text"
              placeholder=" "
              value={fixValue(documentData.allowance || {}, ALLOWANCE_OPTIONS)}
              onChange={(e) => {
                updateFamilyIncomeDocument({
                  memberIndex: documentIndex,
                  changes: {
                    allowance: e.component.value,
                  },
                });
              }}
              isRequired={!!isRequired}
            />
          </Col>
        </Optional>
      </Row>
      <Optional when={documentData.incomeType.value === 'NATIONAL_INSURANCE'}>
        <Row className="g-2 gx-5">
          <Col className="col-12">
            <FileInput
              // label={`${t('DOCUMENTS.LABELS.INCOME_DOCUMENT')} ${getIncomeYear(
              //   documentData.incomeType.value
              // )}`}
              label={'המסמך'}
              name={`document@@${familyMemberType}`}
              form={config.forms.documents}
              isRequired={!!isRequired}
              helpText={t('DOCUMENTS.HELP.STUDENT_INCOME')}
              isFixesNeed={isFixesNeed}
              fixesText={fixesText}
              isDisabled={isDisabled}
              prefilled={prefilledDocumentData}
              onLoad={({ fileName }) => {
                updateFamilyIncomeDocument({
                  memberIndex: documentIndex,
                  changes: {
                    document: fileName,
                  },
                });
              }}
              onDelete={({ fileName }) => {
                updateFamilyIncomeDocument({
                  memberIndex: documentIndex,
                  changes: {
                    document: '',
                  },
                });
                updatePrefilledFamilyIncomeDocument({
                  memberIndex: documentIndex,
                  changes: {
                    document: {
                      url: '',
                      filename: '',
                    },
                  },
                });
                autoSaveRegistration();
              }}
            />
          </Col>
        </Row>
      </Optional>
    </>
  );
};

export const FileUploadFormIncome: React.FC<{
  disabledFields?: Record<string, boolean>;
  fieldsToFix?: Record<string, string>;
}> = ({ disabledFields, fieldsToFix }) => {
  const { t } = useTranslation();
  const { updateDocuments } = useActions(actions);
  const documents = selectors.useDocuments();
  const prefilled = selectors.usePrefilledDocuments();
  const additionalInfo = selectors.useAdditionalInfo();
  const isUserUnder30 = useIsUserUnder30();
  const [isAdditionalIncome, setIsAdditionalIncome] = useState(false);

  const onFileSuccess = ({ fileName, fieldName }: OnLoadCallbackType) =>
    updateDocuments({
      changes: { [fieldName]: fileName },
      withNoChangesMark: false,
    });

  const onFileDelete = ({ fileName, fieldName }: OnLoadCallbackType) =>
    updateDocuments({
      changes: { [fieldName]: '' },
      withNoChangesMark: false,
    });

  return (
    <>
      <Row>
        <Col>
          <CUI.Div _formInfoBox _inline>
            <FontAwesomeIcon icon={faCircleExclamation} className="ml-16" />
            <span>{t('INCOME_INFO_BOX')}</span>
          </CUI.Div>
        </Col>
      </Row>

      <Optional when={isUserUnder30}>
        <Optional
          when={
            (!additionalInfo?.isCutOfFromParents &&
              !additionalInfo?.isOrphan) ||
            (!additionalInfo?.isOrphan &&
              additionalInfo?.cutOfFromParentsCount.value ===
                OneOrBothParentsType.ONE) ||
            (!additionalInfo?.isCutOfFromParents &&
              additionalInfo?.orphanOfParentsCount.value ===
                OneOrBothParentsType.ONE)
          }
        >
          <IncomeElement
            isRequired={true}
            isFixesNeed={!!fieldsToFix?.[stepFourFields.incomeParentOne]}
            fixesText={fieldsToFix?.[stepFourFields.incomeParentOne]}
            isDisabled={!!disabledFields?.[stepFourFields.incomeParentOne]}
            dividerText={t('INCOME_DIVIDER_PAREN_1')}
            documentData={
              documents?.familyDocuments?.[0] || {
                member_type: FamilyMembersType.PARENTONE,
                incomeType: { value: '', text: '' },
                document: '',
                allowance: { value: '', text: '' },
              }
            }
            documentIndex={0}
            familyMemberType={FamilyMembersType.PARENTONE}
            incomeTypeText={t('FIELD_PARENT_INCOME_TYPE')}
            prefilledDocumentData={prefilled?.familyDocuments?.[0].document}
          />
        </Optional>
      </Optional>

      <Optional when={isUserUnder30}>
        <Optional
          when={
            !additionalInfo?.isCutOfFromParents && !additionalInfo?.isOrphan
          }
        >
          <IncomeElement
            isRequired={true}
            isFixesNeed={!!fieldsToFix?.[stepFourFields.incomeParenTwo]}
            fixesText={fieldsToFix?.[stepFourFields.incomeParenTwo]}
            isDisabled={!!disabledFields?.[stepFourFields.incomeParenTwo]}
            dividerText={t('INCOME_DIVIDER_PAREN_2')}
            documentData={
              documents?.familyDocuments?.[1] || {
                member_type: FamilyMembersType.PARENTTWO,
                incomeType: { value: '', text: '' },
                document: '',
                allowance: { value: '', text: '' },
              }
            }
            documentIndex={1}
            familyMemberType={FamilyMembersType.PARENTTWO}
            incomeTypeText={t('FIELD_PARENT_INCOME_TYPE')}
            prefilledDocumentData={prefilled?.familyDocuments?.[1].document}
          />
        </Optional>
      </Optional>

      <Optional
        when={
          (additionalInfo?.maritalStatus?.value === MaritalStatusType.MARRIED &&
            Number(additionalInfo?.childrenCount || 0) > 0) ||
          (additionalInfo?.maritalStatus?.value === MaritalStatusType.MARRIED &&
            !isUserUnder30) ||
          (additionalInfo?.maritalStatus?.value === MaritalStatusType.MARRIED &&
            additionalInfo?.isCutOfFromParents &&
            additionalInfo?.isOrphan) ||
          (additionalInfo?.maritalStatus?.value === MaritalStatusType.MARRIED &&
            (additionalInfo?.cutOfFromParentsCount.value === 'BOTH_PARENTS' ||
              additionalInfo?.orphanOfParentsCount.value === 'BOTH_PARENTS'))
        }
      >
        <IncomeElement
          isRequired={!additionalInfo?.isMaritalStatusGap}
          isFixesNeed={!!fieldsToFix?.[stepFourFields.incomeSpouse]}
          fixesText={fieldsToFix?.[stepFourFields.incomeSpouse]}
          isDisabled={!!disabledFields?.[stepFourFields.incomeSpouse]}
          dividerText={t('INCOME_DIVIDER_SPOUSE')}
          documentData={
            documents?.familyDocuments?.[2] || {
              member_type: FamilyMembersType.SPOUSE,
              incomeType: { value: '', text: '' },
              document: '',
              allowance: { value: '', text: '' },
            }
          }
          documentIndex={2}
          familyMemberType={FamilyMembersType.SPOUSE}
          incomeTypeText={t('FIELD_SPOUSE_INCOME_TYPE')}
          prefilledDocumentData={prefilled?.familyDocuments?.[2].document}
        />
      </Optional>

      <Optional
        when={
          Number(additionalInfo?.childrenCount || 0) !== 0 ||
          !isUserUnder30 ||
          additionalInfo?.cutOfFromParentsCount.value === 'BOTH_PARENTS' ||
          additionalInfo?.orphanOfParentsCount.value === 'BOTH_PARENTS' ||
          (additionalInfo?.cutOfFromParentsCount.value === 'ONE_PARENT' &&
            additionalInfo?.orphanOfParentsCount.value === 'ONE_PARENT')
        }
      >
        <IncomeElement
          isRequired={true}
          isFixesNeed={!!fieldsToFix?.[stepFourFields.incomeStudent]}
          fixesText={fieldsToFix?.[stepFourFields.incomeStudent]}
          isDisabled={!!disabledFields?.[stepFourFields.incomeStudent]}
          dividerText={t('INCOME_DIVIDER_CANDIDATE')}
          documentData={
            documents?.familyDocuments?.[3] || {
              member_type: FamilyMembersType.CANDIDATE,
              incomeType: { value: '', text: '' },
              document: '',
              allowance: { value: '', text: '' },
            }
          }
          documentIndex={3}
          familyMemberType={FamilyMembersType.CANDIDATE}
          incomeTypeText={t('FIELD_CANDIDATE_INCOME_TYPE')}
          prefilledDocumentData={prefilled?.familyDocuments?.[3].document}
        />
      </Optional>

      <Row className="g-2 gx-5">
        <Col>
          <CUI.Button
            _dBlock
            _w100
            _signInButton
            _btnInfoPerach
            _mb40
            onClick={() => {
              setIsAdditionalIncome(!isAdditionalIncome);
            }}
          >
            {t('DOCUMENTS.BUTTON.ADDITIONAL_INCOME')}
          </CUI.Button>
        </Col>
      </Row>
      <Optional when={isAdditionalIncome || !!prefilled?.otherIncomeFile}>
        <Row className="g-2 gx-5">
          <Col className="col-12 col-md-6">
            <LabelWithPopup
              label={t('DOCUMENTS.LABELS.ADDITIONAL_INCOME')}
              helpKey={stepFourFields.additionalIncome}
            />
            <CUI.Input
              form={config.forms.documents}
              name="additionalIncome"
              isDisabled={!!disabledFields?.[stepFourFields.additionalIncome]}
              value={documents?.otherIncomeDetails}
              onChange={(e) => {
                updateDocuments({
                  changes: {
                    otherIncomeDetails: e.component.value,
                  },
                });
              }}
            />
          </Col>
          <Col className="col-12 col-md-6">
            <FileInput
              isFixesNeed={!!fieldsToFix?.[stepFourFields.otherIncomeFile]}
              fixesText={fieldsToFix?.[stepFourFields.otherIncomeFile]}
              label={t('DOCUMENTS.LABELS.ADDITIONAL_INCOME_FILE')}
              name={'otherIncomeFile'}
              isDisabled={!!disabledFields?.[stepFourFields.otherIncomeFile]}
              form={config.forms.documents}
              onLoad={onFileSuccess}
              onDelete={onFileDelete}
              prefilled={prefilled?.otherIncomeFile}
              isRequired
            />
          </Col>
        </Row>
      </Optional>
      <Row className="g-2 gx-5 mb-40">
        <Col className="col-12">
          <CheckBox
            value={documents?.approveIncomes}
            checked={!!documents?.approveIncomes}
            name="approveIncomes"
            form={config.forms.documents}
            onChange={() => {
              updateDocuments({
                changes: { approveIncomes: !documents?.approveIncomes },
              });
            }}
          >
            {t('DOCUMENTS.CHECKBOX.APPROVE_INCOME')}
          </CheckBox>
        </Col>
      </Row>
    </>
  );
};
