import {familyIncomeDefaults} from '@features/Registration/defaults';
import {
  FamilyMembersType,
  ServerDocumentType,
  DocumentType,
  UserDocumentsListType,
  getUserDocumentsResponse,
  RequestData,
  SaveEditedRequestDataRequest,
} from './types';
import {generateId} from "@/CUI/utils";
import {BackToFixEntry} from "@features/Registration";

const mapFile = (doc: ServerDocumentType): DocumentType | undefined =>
  doc === null || (!doc?.file_name && !doc?.file_signed_url)
    ? undefined
    : {
      url: doc?.file_signed_url || undefined,
      filename: doc?.file_name || undefined,
    };

const mapFileToStore = (doc: ServerDocumentType): string =>
  !doc?.file_name ? '' : doc?.file_name;

export const mapUserDocumentsFromServerToUI = (
  data: getUserDocumentsResponse
): UserDocumentsListType => ({
  frontIdentity: mapFile(data.front_identity),
  backIdentity: mapFile(data.back_identity),
  attachmentIdentity: mapFile(data.attachment_identity),
  parentOneFrontIdentity: mapFile(data.parent_one_front_identity),
  parentTwoFrontIdentity: mapFile(data.parent_two_front_identity),
  parentOneBackIdentity: mapFile(data.parent_one_back_identity),
  parentTwoBackIdentity: mapFile(data.parent_two_back_identity),
  parentOneAttachmentIdentity: mapFile(data.parent_one_attachment_identity),
  parentTwoAttachmentIdentity: mapFile(data.parent_two_attachment_identity),
  parentOneDeathCertificate: mapFile(data.parent_one_death_certificate),
  parentTwoDeathCertificate: mapFile(data.parent_two_death_certificate),
  confirmationStudies: mapFile(data.confirmation_studies),
  schedule: mapFile(data.schedule),
  learningDisabilityCertificate: mapFile(data.learning_disability_certificate),
  approvalAnnualTuitionFees: mapFile(data.approval_annual_tuition_fees),
  bankAccountReference: mapFile(data.bank_account_reference),
  familyDocuments:
    data.family_documents?.map(({document}) => ({
      allowance: {value: document?.allowance || '', text: ''},
      document:
        mapFile({
          file_signed_url: document?.file_signed_url || '',
          file_name: document?.file_name || '',
        }) || undefined,
      member_type: document?.family_member as FamilyMembersType,
      incomeType: {value: document?.income_type || '', text: ''},
    })) || familyIncomeDefaults,
  otherIncomeDetails: data.other_income_details || '',
  otherIncomeFile: mapFile(data.other_income_file),
  approvalStudyBrothersUnder30:
    data.approval_study_brothers_under_30?.map(({document}) =>
      mapFile(document)
    ) || [],
  newcomerDocument: mapFile(data.newcomer_document),
  singleParentApproval: mapFile(data.single_parent_approval),
  armyTypeDocument: mapFile(data.army_type_document),
  reservistDocument: mapFile(data.reservist_document),
  additionalDocument: mapFile(data.additional_document),
  disabilityDocuments:
    data.disability_documents?.map(({document}) => ({
      memberType: document?.family_member as FamilyMembersType,
      document:
        mapFile({
          file_name: document?.file_name || '',
          file_signed_url: document?.file_signed_url || '',
        }) || undefined,
    })) || undefined,
});

type DissabilityData = {
  family_member: string,
  disability_percentages: string
};

export const mapEditedDataFromServerToUi = (
  data: SaveEditedRequestDataRequest
): RequestData => {

  const family = JSON.parse(data?.family_handicapped_data) as DissabilityData[] || [];
  return ({
    houseHoldPeople: data?.number_of_persons?.toString() || '',
    parenOneIncome: data?.parent_one_income?.toString() || '',
    parenTwoIncome: data?.parent_two_income?.toString() || '',
    note: data?.comment || '',
    candidateEntering: data?.candidate_income?.toString() || '',
    requestNumber: 0, //data?.request_number || 0,
    spouse: data?.mate_income?.toString() || '',
    openUniversity: data?.open_university?.toString() || '',
    surveyMonth: data?.survey_month?.toString() || '',
    adminAssignment: data?.managerial_allocation,
    disability:
      family.map(
        ({disability_percentages, family_member}) => ({
          id: generateId(),
          familyMember: family_member,
          percentages: !!disability_percentages ? disability_percentages : '',
        })
      ) || [],
    entry: data?.additional_income?.toString() || '',
    studiesScope: data?.study_extant?.toString() || '',
    exceedsStandards: data.standard_years_deviation
      ? {value: true, label: 'כן'}
      : {value: false, label: 'לא'},
    reservedField: '',
    childrenDisability: ((data?.children_disability_present || 0) * 100).toString() || '',
  });
};


export const mapNamesFromUiToServer = (data: any) => ({
  front_identity: data.frontIdentity || '',
  back_identity: data.backIdentity || '',
  attachment_identity: data.attachmentIdentity || '',
  parent_one_front_identity: data.parentOneFrontIdentity || '',
  parent_one_back_identity: data.parentOneBackIdentity || '',
  parent_one_attachment_identity: data.parentOneAttachmentIdentity || '',
  parent_one_death_certificate: data.parentOneDeathCertificate || '',
  parent_two_front_identity: data.parentTwoFrontIdentity || '',
  parent_two_back_identity: data.parentTwoBackIdentity || '',
  parent_two_attachment_identity: data.parentTwoAttachmentIdentity || '',
  parent_two_death_certificate: data.parentTwoDeathCertificate || '',
  confirmation_studies: data.confirmationStudies || '',
  learning_disability_certificate: data.learningDisabilityCertificate || '',
  approval_annual_tuition_fees: data.approvalAnnualTuitionFees || '',
  //brother: buildError('approval_study_brothers_under_30'),
  bank_account_reference: data.bankAccountReference || '',
  // family_documents: data.income,
  other_income_file: data.incomeDocument || '',
  additional_income: data.otherIncomeFile || '',
  army_type_document: data.armyTypeDocument || '',
  reservist_document: data.reservistDocument || '',
  newcomer_document: data.newcomerDocument || '',
  single_parent_approval: data.singleParentApproval || '',
  //disabledFamily: buildError('disability_documents'),
  additional_document: data.additionalDocument || '',
  // student_income: data.incomeStudent,
  // spouse_income: data.incomeSpouse,
  // parent_one_income: data.incomeParentOne,
  // parent_two_income: data.incomeParenTwo,
});

export const transformNames = (name: string) => {
  const names = {
    frontIdentity: "front_identity",
    backIdentity: "back_identity",
    attachmentIdentity: "attachment_identity",
    parentOneFrontIdentity: "parent_one_front_identity",
    parentOneBackIdentity: "parent_one_back_identity",
    parentOneAttachmentIdentity: "parent_one_attachment_identity",
    parentOneDeathCertificate: "parent_one_death_certificate",
    parentTwoFrontIdentity: "parent_two_front_identity",
    parentTwoBackIdentity: "parent_two_back_identity",
    parentTwoAttachmentIdentity: "parent_two_attachment_identity",
    parentTwoDeathCertificate: "parent_two_death_certificate",
    confirmationStudies: "confirmation_studies",
    learningDisabilityCertificate: "learning_disability_certificate",
    approvalAnnualTuitionFees: "approval_annual_tuition_fees",
    bankAccountReference: "bank_account_reference",
    income: "family_documents",
    incomeDocument: "other_income_file",
    otherIncomeFile: "additional_income",
    armyTypeDocument: "army_type_document",
    reservistDocument: "reservist_document",
    newcomerDocument: "newcomer_document",
    singleParentApproval: "single_parent_approval",
    additionalDocument: "additional_document",
    incomeStudent: "student_income",
    incomeSpouse: "spouse_income",
    incomeParentOne: "parent_one_income",
    incomeParenTwo: "parent_two_income",
  }
  // @ts-ignore
  return names.hasOwnProperty(name) ? names[name] : name;
}

