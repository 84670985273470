import {
  createHookSelector,
  createModelFieldSelector,
} from '../../../common/store-utils';

import { config } from '../config';

import { ModelState } from '../types';

/**
 * Selector of model State
 */
const fieldSelector = createModelFieldSelector<ModelState>(config.modelName);

/**
 * Current time selector
 */
const CurrentTimeSelector = fieldSelector('currentTime');

export const selectors = {
  currentTime: CurrentTimeSelector,
  useCurrentTime: createHookSelector(CurrentTimeSelector),
};
