import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as CUI from '../../CUI';
import {Tooltip} from '../components/shared/Tooltip/tooltip';
import {OpionalTooltip} from './OpionalTooltip';

/**
 * Input Label with help mark
 * @returns React.FC
 * */
export const LabelWithHelp: React.FC<{ label: string; helpText?: string }> = ({
                                                                                label,
                                                                                helpText,
                                                                              }) => {
  const {t} = useTranslation();
  return (
    <CUI.Label _labelPerach>
      <CUI.Div _labelMore>
        <div>{label}</div>
        <OpionalTooltip option={!!helpText} text={helpText || ''}>
          <div className={'label-success cursor-pointer'}>
            {t('HELP')}
            <FontAwesomeIcon icon={faQuestionCircle} className="mr-8"/>
          </div>
        </OpionalTooltip>
      </CUI.Div>
    </CUI.Label>
  );
};
