import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Collapse, Row } from 'reactstrap';
import { faPlus, faPercent } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as CUI from '@CUI';
import { useActions } from '@common/store-utils/hooks';
import {
  OptionsInput,
  CollapseHeader,
  LabelWithHelp,
  ShekelRender,
  LabelWithAction,
  LabelWithPopup,
} from '@common/perach-ui';
import { Optional } from '@common/ui';

import { DicRecord, fixValue } from '@entities/dictionaries';

import { RegistrationInputField } from './RegistrationInputField';
import { actions, selectors } from '../model';
import {
  FamilyMembersType,
  MaritalStatusType,
  OneOrBothParentsType,
  PersonalInfo,
} from '../types';
import { config } from '../config';
import { stepOneFields, stepThreeFields, stepTwoFields } from '../fields';
import { useCurrentTime } from '@entities/time/hooks';
import { additionalInfoDefaultValues } from '@features/Registration/defaults';
import { useLocation } from 'react-router-dom';
import { useIsUserUnder30 } from '@features/Registration/hooks';

const InputField: React.FC<{ label: string } & CUI.InputTypes.InputProps> = ({
  label,
  ...props
}) => (
  <RegistrationInputField
    form={config.forms.additionalData}
    label={label}
    {...props}
  />
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

/**
 * Users additional data for Registration
 * @returns React.FC
 * */
export const AdditionalInfoForm: React.FC<{
  disabledFields?: Record<string, boolean>;
  shouldHideButtons?: boolean;
}> = ({ disabledFields, shouldHideButtons = false }) => {
  const { t } = useTranslation();

  const {
    updateAdditionalInfo,
    saveAdditionalData,
    addDisabledFamilyMember,
    updateDisabledFamilyMember,
    updateDocuments,
    updatePrefillDocuments,
    autoSaveRegistration,
    removeDisabledFamilyMember,
    setIsModalOpen,
  } = useActions(actions);
  const additionalInfo =
    selectors.useAdditionalInfo() || additionalInfoDefaultValues;

  const isUserUnder30 = useIsUserUnder30();

  const initialCollapses = {
    1: true,
    2: true,
    3: true,
    4: true,
  };

  const [collapses, setCollapses] =
    useState<Record<string, boolean>>(initialCollapses);

  const toggleCollapse = (id: string) =>
    setCollapses({ ...collapses, [id]: !collapses[id] });

  const BOOL_OPTIONS = [
    { value: true, label: t('YES') },
    { value: false, label: t('NO') },
  ];

  const MARITAL_STATUS_OPTIONS = [
    { value: MaritalStatusType.SINGLE, text: t('SINGLE') },
    { value: MaritalStatusType.MARRIED, text: t('MARRIED') },
    { value: MaritalStatusType.DIVORCED, text: t('DIVORCED') },
    { value: MaritalStatusType.WIDOWED, text: t('WIDOWED') },
    { value: MaritalStatusType.OTHER, text: t('OTHER') },
  ];

  const NUMBER_OF_PARENTS_OPTIONS = [
    { value: OneOrBothParentsType.ONE, text: t('ONE_PARENT') },
    { value: OneOrBothParentsType.BOTH, text: t('TWO_PARENTS') },
  ];

  const FAMILY_MEMBER_OPTION = [
    { value: FamilyMembersType.PARENTONE, text: t('FIRST_PARENT') },
    { value: FamilyMembersType.PARENTTWO, text: t('SECOND_PARENT') },
    { value: FamilyMembersType.SIBLING, text: t('BROTHER_OR_SISTER') },
    { value: FamilyMembersType.SPOUSE, text: t('SPOUSE') },
    { value: FamilyMembersType.CANDIDATE, text: t('CANDIDATE') },
    { value: FamilyMembersType.CHILD, text: t('CHILD') },
  ];

  const familyMembersOptionsCleared = () => {
    const options = [];
    if (isUserUnder30) {
      options.push(FAMILY_MEMBER_OPTION[0]);
      options.push(FAMILY_MEMBER_OPTION[1]);
      options.push(FAMILY_MEMBER_OPTION[2]);
    }
    if (additionalInfo?.maritalStatus?.value === MaritalStatusType.MARRIED) {
      options.push(FAMILY_MEMBER_OPTION[3]);
    }
    if (
      !!additionalInfo?.childrenCount &&
      additionalInfo.childrenCount !== '0'
    ) {
      options.push(FAMILY_MEMBER_OPTION[5]);
    }
    options.push(FAMILY_MEMBER_OPTION[4]);
    return options;
  };

  const onFieldChange = (e: CUI.InputTypes.ChangeEvent) => {
    const { name, value } = e.component;
    updateAdditionalInfo({
      changes: { [name as Partial<keyof PersonalInfo>]: value },
    });
    if (name === stepThreeFields.childrenCount) {
      updateAdditionalInfo({
        changes: {
          [stepThreeFields.siblingsCount]: 0,
          [stepThreeFields.siblingsStudentsCount]: 0,
        },
      });
    }
  };

  const onChangeSiblingsUnder30 = (e: CUI.InputTypes.ChangeEvent) => {
    const { name, value } = e.component;
    updateAdditionalInfo({
      changes: { [name as Partial<keyof PersonalInfo>]: value },
    });
    updateDocuments({
      changes: {
        approvalStudyBrothersUnder30: [],
      },
    });
    updatePrefillDocuments({
      changes: {
        approvalStudyBrothersUnder30: [],
      },
    });
  };

  return (
    <>
      <ScrollToTop />
      <div className="form-container ph-25">
        <CollapseHeader
          id="1"
          isOpen={collapses['1']}
          title={t('PERSONAL_INFO')}
          toggle={() => toggleCollapse('1')}
        />
        <Collapse isOpen={collapses['1']}>
          <Row className="g-2 gx-5 align-items-end">
            <Col className="col-12 col-md-6 mb-40">
              <LabelWithPopup
                label={t('FAMILY_STATUS')}
                helpKey={stepThreeFields.maritalStatus}
              />
              <CUI.DropDownSelect
                name={stepThreeFields.maritalStatus}
                isDisabled={!!disabledFields?.[stepThreeFields.maritalStatus]}
                form={config.forms.additionalData}
                shouldFilterValues
                data={MARITAL_STATUS_OPTIONS}
                textField="text"
                compareObjectsBy="value"
                placeholder=" "
                value={fixValue(
                  additionalInfo?.maritalStatus,
                  MARITAL_STATUS_OPTIONS
                )}
                onChange={(e) =>
                  updateAdditionalInfo({
                    changes: {
                      maritalStatus: e.component
                        .value as DicRecord<MaritalStatusType>,
                    },
                  })
                }
                isRequired
                requiredMessage={t('FIELD_REQUIRED')}
              />
            </Col>
            <Col className="col-12 col-md-6 mb-40">
              <LabelWithPopup
                label={t('IS_GAP_IN_MARITAL_STATUS')}
                helpKey={stepThreeFields.isMaritalStatusGap}
              />
              <OptionsInput
                options={BOOL_OPTIONS}
                isDisabled={
                  !!disabledFields?.[stepThreeFields.isMaritalStatusGap]
                }
                value={additionalInfo?.isMaritalStatusGap || false}
                onChange={(val) =>
                  updateAdditionalInfo({ changes: { isMaritalStatusGap: val } })
                }
              />
            </Col>
          </Row>
          <Optional when={!!additionalInfo?.isMaritalStatusGap}>
            <Row className="g-2 gx-5">
              <Col className="col-12">
                <LabelWithPopup
                  label={t('GAP_IN_MARITAL_STATUS')}
                  helpKey={stepThreeFields.maritalStatusGap}
                />
                <CUI.Input
                  _mb40
                  form={config.forms.additionalData}
                  name={stepThreeFields.maritalStatusGap}
                  isDisabled={
                    !!disabledFields?.[stepThreeFields.maritalStatusGap]
                  }
                  value={additionalInfo?.maritalStatusGap}
                  onChange={onFieldChange}
                  // @ts-ignore
                  onBlur={autoSaveRegistration}
                  isRequired
                  requiredMessage={t('FIELD_REQUIRED')}
                  maxLength={200}
                />
              </Col>
            </Row>
          </Optional>
          <Row className="g-2 gx-5">
            <Col className="col-12 col-md-6">
              <InputField
                label={t('CHILDREN_COUNT')}
                name={stepThreeFields.childrenCount}
                isDisabled={!!disabledFields?.[stepThreeFields.childrenCount]}
                value={additionalInfo?.childrenCount}
                onChange={onFieldChange}
                allowedSymbols={'numbers'}
                isRequired
                requiredMessage={t('FIELD_REQUIRED')}
                maxLength={2}
              />
            </Col>
            <Col className="col-12 col-md-6 mb-40">
              <LabelWithPopup
                label={t('IS_CHILDREN_COUNT_GAP')}
                helpKey={stepThreeFields.isChildrenCountGap}
              />
              <OptionsInput
                options={BOOL_OPTIONS}
                isDisabled={
                  !!disabledFields?.[stepThreeFields.isChildrenCountGap]
                }
                value={additionalInfo?.isChildrenCountGap || false}
                onChange={(val) =>
                  updateAdditionalInfo({ changes: { isChildrenCountGap: val } })
                }
              />
            </Col>
          </Row>
          <Optional when={!!additionalInfo?.isChildrenCountGap}>
            <Row className="g-2 gx-5">
              <Col className="col-12">
                <LabelWithPopup
                  label={t('CHILDREN_COUNT_GAP')}
                  helpKey={stepThreeFields.childrenCountGap}
                />
                <CUI.Input
                  _mb40
                  form={config.forms.additionalData}
                  name={stepThreeFields.childrenCountGap}
                  isDisabled={
                    !!disabledFields?.[stepThreeFields.childrenCountGap]
                  }
                  value={additionalInfo?.childrenCountGap}
                  onChange={onFieldChange}
                  // @ts-ignore
                  onBlur={autoSaveRegistration}
                  isRequired
                  maxLength={50}
                  requiredMessage={t('FIELD_REQUIRED')}
                />
              </Col>
            </Row>
          </Optional>
          <Optional
            when={
              (additionalInfo?.childrenCount === '0' ||
                !additionalInfo?.childrenCount) &&
              isUserUnder30
            }
          >
            <Row className="g-2 gx-5">
              <Col className="col-12 col-md-6">
                <InputField
                  label={t('SIBLINGS_UNDER_24')}
                  name={stepThreeFields.siblingsCount}
                  isDisabled={!!disabledFields?.[stepThreeFields.siblingsCount]}
                  value={additionalInfo?.siblingsCount}
                  onChange={onFieldChange}
                  allowedSymbols="numbers"
                  validator={(val) => Number(val) <= 25 && Number(val) >= 0}
                  maxLength={2}
                  isRequired
                  requiredMessage={t('FIELD_REQUIRED')}
                />
              </Col>
              <Col className="col-12 col-md-6">
                <InputField
                  label={t('SIBLINGS_UNDER_30')}
                  name={stepThreeFields.siblingsStudentsCount}
                  isDisabled={
                    !!disabledFields?.[stepThreeFields.siblingsStudentsCount]
                  }
                  value={additionalInfo?.siblingsStudentsCount}
                  onChange={onChangeSiblingsUnder30}
                  allowedSymbols="numbers"
                  validator={(val) => Number(val) <= 20 && Number(val) >= 0}
                  maxLength={2}
                  isRequired
                  requiredMessage={t('FIELD_REQUIRED')}
                />
              </Col>
            </Row>
          </Optional>
        </Collapse>

        <Optional
          when={
            isUserUnder30 ||
            (!!additionalInfo?.childrenCount &&
              additionalInfo?.childrenCount !== '0')
          }
        >
          <CollapseHeader
            id="2"
            isOpen={collapses['2']}
            title={t('FAMILY_STATUS')}
            toggle={() => toggleCollapse('2')}
          />
          <Collapse isOpen={collapses['2']}>
            <Optional when={isUserUnder30}>
              <Row className="g-2 gx-5">
                <Col className="col-12 col-md-6 mb-40">
                  <LabelWithPopup
                    label={t('ARE_YOU_AN_ORPHAN')}
                    helpKey={stepThreeFields.isOrphan}
                  />
                  <OptionsInput
                    options={BOOL_OPTIONS}
                    isDisabled={!!disabledFields?.[stepThreeFields.isOrphan]}
                    value={additionalInfo?.isOrphan || false}
                    onChange={(val) =>
                      updateAdditionalInfo({
                        changes: !!val
                          ? {
                              isOrphan: val,
                              isCutOfFromParents: false,
                              cutOfFromParentsCount: {
                                value: undefined,
                                text: '',
                              },
                            }
                          : {
                              isOrphan: val,
                              orphanOfParentsCount: {
                                value: undefined,
                                text: '',
                              },
                            },
                      })
                    }
                  />
                </Col>
                <Col className="col-12 col-md-6 mb-40">
                  <Optional when={!!additionalInfo?.isOrphan}>
                    <LabelWithPopup
                      label={t('CHOOSE_PARENT')}
                      helpKey={stepThreeFields.orphanOfParentsCount}
                    />
                    <CUI.DropDownSelect
                      name={stepThreeFields.orphanOfParentsCount}
                      isDisabled={
                        !!disabledFields?.[stepThreeFields.orphanOfParentsCount]
                      }
                      form={config.forms.additionalData}
                      shouldFilterValues
                      data={
                        additionalInfo?.isCutOfFromParents
                          ? [NUMBER_OF_PARENTS_OPTIONS[0]]
                          : NUMBER_OF_PARENTS_OPTIONS
                      }
                      textField="text"
                      placeholder=" "
                      value={fixValue(
                        additionalInfo?.orphanOfParentsCount,
                        NUMBER_OF_PARENTS_OPTIONS
                      )}
                      onChange={(e) =>
                        updateAdditionalInfo({
                          changes: {
                            orphanOfParentsCount: e.component
                              .value as DicRecord<OneOrBothParentsType>,
                          },
                        })
                      }
                      isRequired
                      requiredMessage={t('FIELD_REQUIRED')}
                    />
                  </Optional>
                </Col>
              </Row>
              <Row className="g-2 gx-5">
                <Col className="col-12 col-md-6 mb-40">
                  <LabelWithPopup
                    label={t('ARE_YOU_CUT_OFF_FROM_PARENTS')}
                    helpKey={stepThreeFields.isCutOfFromParents}
                  />
                  <OptionsInput
                    options={BOOL_OPTIONS}
                    isDisabled={
                      additionalInfo?.orphanOfParentsCount?.value ===
                        OneOrBothParentsType.BOTH ||
                      !!disabledFields?.[stepThreeFields.isCutOfFromParents]
                    }
                    value={additionalInfo?.isCutOfFromParents || false}
                    onChange={(val) =>
                      updateAdditionalInfo({
                        changes: !!val
                          ? { isCutOfFromParents: val }
                          : {
                              isCutOfFromParents: val,
                              cutOfFromParentsCount: {
                                value: undefined,
                                text: '',
                              },
                            },
                      })
                    }
                  />
                </Col>
                <Col className="col-12 col-md-6 mb-40">
                  <Optional when={!!additionalInfo?.isCutOfFromParents}>
                    <LabelWithPopup
                      label={t('CHOOSE_PARENT')}
                      helpKey={stepThreeFields.cutOfFromParentsCount}
                    />
                    <CUI.DropDownSelect
                      name={stepThreeFields.cutOfFromParentsCount}
                      isDisabled={
                        !!disabledFields?.[
                          stepThreeFields.cutOfFromParentsCount
                        ]
                      }
                      form={config.forms.additionalData}
                      shouldFilterValues
                      data={
                        additionalInfo?.isOrphan
                          ? [NUMBER_OF_PARENTS_OPTIONS[0]]
                          : NUMBER_OF_PARENTS_OPTIONS
                      }
                      textField="text"
                      placeholder=" "
                      value={fixValue(
                        additionalInfo?.cutOfFromParentsCount,
                        NUMBER_OF_PARENTS_OPTIONS
                      )}
                      onChange={(e) =>
                        updateAdditionalInfo({
                          changes: {
                            cutOfFromParentsCount: e.component
                              .value as DicRecord<OneOrBothParentsType>,
                          },
                        })
                      }
                      isRequired
                      requiredMessage={t('FIELD_REQUIRED')}
                    />
                  </Optional>
                </Col>
              </Row>

              <Optional
                when={
                  additionalInfo?.cutOfFromParentsCount.value ===
                    OneOrBothParentsType.BOTH ||
                  (additionalInfo?.isCutOfFromParents &&
                    additionalInfo?.isOrphan)
                }
              >
                <Row className="g-2 gx-5 md-40">
                  <Col className="col-12 col-md-6 mb-40">
                    <LabelWithPopup
                      label={t('NEWCOMER')}
                      helpKey={stepThreeFields.isNewcomer}
                    />
                    <OptionsInput
                      options={BOOL_OPTIONS}
                      isDisabled={
                        !!disabledFields?.[stepThreeFields.isNewcomer]
                      }
                      value={additionalInfo?.isNewcomer || false}
                      onChange={(val) =>
                        updateAdditionalInfo({ changes: { isNewcomer: val } })
                      }
                    />
                  </Col>
                  <Col className="col-12 col-md-6">
                    <Optional when={!!additionalInfo?.isNewcomer}>
                      <LabelWithPopup
                        label={t('REPATRIATION_DATE')}
                        helpKey={stepThreeFields.repatriationDate}
                      />
                      <CUI.DatePicker
                        _wideList
                        form={config.forms.additionalData}
                        name={stepThreeFields.repatriationDate}
                        isDisabled={
                          !!disabledFields?.[stepThreeFields.repatriationDate]
                        }
                        placeholder={' '}
                        value={additionalInfo?.repatriationDate || null}
                        onChange={(e) =>
                          updateAdditionalInfo({
                            changes: {
                              repatriationDate: e.component
                                .value as unknown as Date,
                            },
                          })
                        }
                        isRequired
                        min={new Date('01-01-1901')}
                        requiredMessage={t('FIELD_REQUIRED')}
                      />
                    </Optional>
                  </Col>
                </Row>
              </Optional>
            </Optional>
            <Optional
              when={
                !!additionalInfo?.childrenCount &&
                additionalInfo?.childrenCount !== '0'
              }
            >
              <Row className="g-2 gx-5">
                <Col className="col-12 mb-40">
                  <LabelWithPopup
                    label={t('ARE_YOU_SINGLE_PARENT')}
                    helpKey={stepThreeFields.isSingleParent}
                  />
                  <OptionsInput
                    options={BOOL_OPTIONS}
                    isDisabled={
                      !!disabledFields?.[stepThreeFields.isSingleParent]
                    }
                    value={additionalInfo?.isSingleParent || false}
                    onChange={(val) =>
                      updateAdditionalInfo({ changes: { isSingleParent: val } })
                    }
                  />
                </Col>
              </Row>
            </Optional>
          </Collapse>
        </Optional>
        <CollapseHeader
          id="3"
          isOpen={collapses['3']}
          title={t('DISABLED_FAMILY_MEMBERS')}
          toggle={() => toggleCollapse('3')}
        />
        <Collapse isOpen={collapses['3']}>
          <Row className="g-2 gx-5">
            <Col className="mb-40">
              <LabelWithPopup
                label={t('IS_ANY_FAMILY_MEMBER_DISABLED')}
                helpKey={stepThreeFields.isDisabledFamilyMembers}
              />
              <OptionsInput
                options={BOOL_OPTIONS}
                isDisabled={
                  !!disabledFields?.[stepThreeFields.isDisabledFamilyMembers]
                }
                value={additionalInfo?.isDisabledFamilyMembers || false}
                onChange={(val) =>
                  updateAdditionalInfo({
                    changes: { isDisabledFamilyMembers: val },
                  })
                }
              />
            </Col>
          </Row>
          <Optional when={!!additionalInfo?.isDisabledFamilyMembers}>
            {additionalInfo?.disabledFamilyMembers?.length &&
              additionalInfo?.disabledFamilyMembers?.map((el, index) => (
                <Row className="g-2 gx-5" key={el?.id}>
                  <Col className="col-12 col-md-6 mb-40">
                    <LabelWithAction
                      helpKey={stepThreeFields.familyMember}
                      label={t('CHOOSE_FAMILY_MEMBER')}
                      actionText={t('DELETE_LINE')}
                      actionHandler={() => removeDisabledFamilyMember(el.id)}
                      isSuccess={true}
                      shouldHideAction={
                        index === 0 ||
                        !!disabledFields?.[
                          stepThreeFields.buttonDeleteFamilyMember
                        ]
                      }
                    />
                    <CUI.DropDownSelect
                      name={`${stepThreeFields.familyMember}@@${el.id}`}
                      isDisabled={
                        !!disabledFields?.[stepThreeFields.familyMember]
                      }
                      form={config.forms.additionalData}
                      shouldFilterValues
                      data={familyMembersOptionsCleared()}
                      textField="text"
                      placeholder=" "
                      value={fixValue(el.type, FAMILY_MEMBER_OPTION)}
                      onChange={(e) => {
                        updateDisabledFamilyMember({
                          id: el.id,
                          changes: {
                            type: e.component
                              .value as DicRecord<FamilyMembersType>,
                          },
                        });
                      }}
                      isRequired
                      requiredMessage={t('FIELD_REQUIRED')}
                    />
                  </Col>
                  <Col className="col-12 col-md-6 mb-40">
                    <InputField
                      label={t('DISABILITY_PERCENT')}
                      name={`${stepThreeFields.disabilityPercent}@@${el.id}`}
                      isDisabled={
                        !!disabledFields?.[stepThreeFields.disabilityPercent]
                      }
                      value={el?.percent}
                      onChange={(e) => {
                        updateDisabledFamilyMember({
                          id: el.id,
                          changes: { percent: e.component.value },
                        });
                      }}
                      allowedSymbols="numbers"
                      maxLength={3}
                      validator={(val) => val >= 20 && val <= 100}
                      invalidMessage={'יש לדווח על נכות בין 20-100% בלבד'}
                      isRequired
                      requiredMessage={t('FIELD_REQUIRED')}
                      inputRender={({ elementProps, Element }) => (
                        <>
                          <Element
                            {...elementProps}
                            className={`${elementProps.className} `}
                            style={{
                              padding: '0 8px 0 12px',
                              textAlign: 'right',
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faPercent}
                            style={{
                              paddingLeft: '25px',
                              fontSize: '20px',
                            }}
                          />
                        </>
                      )}
                    />
                  </Col>
                </Row>
              ))}
            <Optional
              when={
                additionalInfo?.disabledFamilyMembers?.length < 9 &&
                (isUserUnder30 ||
                  (!!additionalInfo?.childrenCount &&
                    additionalInfo.childrenCount !== '0') ||
                  additionalInfo?.maritalStatus?.value ===
                    MaritalStatusType.MARRIED)
              }
            >
              <Row className="g-2 gx-5">
                <Col>
                  <CUI.Button
                    _dBlock
                    _w100
                    _signInButton
                    _btnInfoPerach
                    isDisabled={
                      !!disabledFields?.[stepThreeFields.buttonAddFamilyMember]
                    }
                    // @ts-ignore
                    onClick={addDisabledFamilyMember}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="mr-8">{t('ADD_FAMILY_MEMBER')}</span>
                  </CUI.Button>
                </Col>
              </Row>
            </Optional>
          </Optional>
        </Collapse>

        <Optional when={!shouldHideButtons}>
          <Row className="mt-40">
            <Col className="col-12 col-md-9 mb-40">
              <CUI.Button
                _dBlock
                _w100
                _signInButton
                _btnPrimaryPerach
                form={config.forms.additionalData}
                isDisabled={!!disabledFields?.[stepThreeFields.buttonSubmit]}
                // @ts-ignore
                onClick={saveAdditionalData}
                shouldScrollToInvalidFields
                scrollOffset={135}
              >
                {t('NEXT')}
              </CUI.Button>
            </Col>
            <Col className="col-12 col-md-3 mb-40">
              <CUI.Button
                _dBlock
                _w100
                _signInButton
                _btnInfoPerach
                isDisabled={!!disabledFields?.[stepThreeFields.buttonSave]}
                onClick={() => setIsModalOpen(true)}
              >
                {t('SAVE_AND_CLOSE')}
              </CUI.Button>
            </Col>
          </Row>
        </Optional>
      </div>
    </>
  );
};
