/* eslint-disable max-lines-per-function */
import axios, {
  AxiosRequestConfig,
  AxiosPromise,
  AxiosError,
  AxiosInstance,
} from 'axios';
import qs from 'qs';
import { globalConfig } from '@/globalConfig';

interface HttpClientParams {
  url: string;
  config?: Omit<AxiosRequestConfig, 'url' | 'headers'>;
  version?: string;
  headers?: Record<string, string>;
}

interface HttpClientParamsWithData<T = any> extends HttpClientParams {
  config?: Omit<AxiosRequestConfig, 'url' | 'headers' | 'data'>;
  data?: T;
}

interface HttpClient {
  axiosInstance: AxiosInstance;

  get<T = any>(params: HttpClientParams): AxiosPromise<T>;

  delete<T = any, D = any>(
    params: HttpClientParamsWithData<D>
  ): AxiosPromise<T>;

  head<T = any>(params: HttpClientParams): AxiosPromise<T>;

  post<T = any, D = any>(params: HttpClientParamsWithData<D>): AxiosPromise<T>;

  put<T = any, D = any>(params: HttpClientParamsWithData<D>): AxiosPromise<T>;

  patch<T = any, D = any>(params: HttpClientParamsWithData<D>): AxiosPromise<T>;
}

/**
 * URL creator
 * @param {string} serviceName - service name
 * @param {string} apiVersion - API version
 * @param {string} url - URL
 * @returns {string} URL
 */
const constructUrl = (serviceName: string, apiVersion: string, url: string) => {
  if (serviceName && apiVersion) {
    return `${serviceName}/api/${apiVersion}/${url}`;
  }
  if (serviceName) {
    return `${serviceName}/${url}`;
  }
  return url;
};

/**
 * JWT builder. Stored inside localStorage
 * */
// const jwt = () =>
//   `Bearer ${window.localStorage.getItem(globalConfig.storage.tokenKey)}`;
// const isAdmin = () =>
//   `${window.localStorage.getItem(globalConfig.storage.isAdmin)}`;
// const userId = () =>
//   `${window.localStorage.getItem(globalConfig.storage.userId)}`;

// const enrichedHeadresObject = {
//   Authorization: jwt() || '',
//   is_admin: isAdmin() || '',
//   user_id: userId() || '',
// };
//
// const enrichedHeaders = () => {
//   return Object.fromEntries(
//     Object.entries(enrichedHeadresObject).filter(
//       ([_, v]) => v !== null && v !== 'null' && v !== ''
//     )
//   );
// };

/**
 * Creates and returns axiosInstance
 * @param {string} serviceName - service name
 * @param {string} apiVersion - API version
 * @returns {HttpClient} - AxiosInstance
 */
export const createHttpClient = ({
  serviceName = '',
  apiVersion = 'v1',
  errorHandler,
}: {
  serviceName?: string;
  apiVersion?: string;
  errorHandler?: (error: AxiosError) => Error;
} = {}): HttpClient => {
  const axiosInstance = axios.create({
    // headers: { Authorization: jwt() },
    paramsSerializer: (queryParams) =>
      qs.stringify(queryParams, { arrayFormat: 'indices' }),
  });

  axiosInstance.interceptors.request.use(function (config) {
    const token = window.localStorage.getItem(globalConfig.storage.tokenKey);
    const isAdmin = window.localStorage.getItem(globalConfig.storage.isAdmin);
    const userId = window.localStorage.getItem(globalConfig.storage.userId);

    if (!!token) {
      // @ts-ignore
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (!!isAdmin) {
      // @ts-ignore
      config.headers.is_admin = `${isAdmin}`;
    }

    if (!!userId) {
      // @ts-ignore
      config.headers.user_id = `${userId}`;
    }

    //config.headers = {...config.headers, ...enrichedHeaders()}
    return config;
  });

  // if (errorHandler) {
  //   console.log(errorHandler);
  //   axiosInstance.interceptors.response.use(
  //     (response) => response,
  //     (error) => Promise.reject(errorHandler(error))
  //   );
  // }

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        localStorage.removeItem(globalConfig.storage.tokenKey);
        localStorage.setItem(globalConfig.storage.redirectKey, window.location.href);
        window.location.href = globalConfig.routes.login();
      } else if (errorHandler) {
        return Promise.reject(errorHandler(error))
      } else {
        return Promise.reject(error)
      }
    }
  );

  /**
   * ### send GET request
   * @param {HttpClientParams} params
   * @returns {AxiosPromise} response
   */
  const get = <T = any>({
    version = apiVersion,
    url,
    headers,
    config,
  }: HttpClientParams): AxiosPromise<T> =>
    axiosInstance.request<T>({
      url: constructUrl(serviceName, version, url),
      method: 'GET',
      headers: {
        ...headers,
        //...enrichedHeaders(),
      },
      ...config,
    });

  /**
   * ### send DELETE request
   * @param {HttpClientParams} params
   * @returns {AxiosPromise} response
   */
  const del = <T = any, D = any>({
    version = apiVersion,
    url,
    data,
    headers,
    config,
  }: HttpClientParamsWithData<D>): AxiosPromise<T> =>
    axiosInstance.request<T>({
      url: constructUrl(serviceName, version, url),
      method: 'DELETE',
      headers: {
        ...headers,
        //...enrichedHeaders(),
      },
      ...config,
      data,
    });

  /**
   * ### send HEAD request
   * @param {HttpClientParams} params
   * @returns {AxiosPromise} response
   */
  const head = <T = any>({
    version = apiVersion,
    url,
    headers,
    config,
  }: HttpClientParams): AxiosPromise<T> =>
    axiosInstance.request<T>({
      url: constructUrl(serviceName, version, url),
      method: 'HEAD',
      headers: {
        ...headers,
        //...enrichedHeaders(),
      },
      ...config,
    });

  /**
   * ### send POST request
   * @param {HttpClientParams} params
   * @returns {AxiosPromise} response
   */
  const post = <T = any, D = any>({
    version = apiVersion,
    url,
    data,
    headers,
    config,
  }: HttpClientParamsWithData<D>): AxiosPromise<T> =>
    axiosInstance.request<T>({
      url: constructUrl(serviceName, version, url),
      method: 'POST',
      headers: {
        ...headers,
        //...enrichedHeaders(),
      },
      ...config,
      data,
    });

  /**
   * ### send PUT request
   * @param {HttpClientParams} params
   * @returns {AxiosPromise} response
   */
  const put = <T = any, D = any>({
    version = apiVersion,
    url,
    data,
    headers,
    config,
  }: HttpClientParamsWithData<D>): AxiosPromise<T> =>
    axiosInstance.request<T>({
      url: constructUrl(serviceName, version, url),
      method: 'PUT',
      headers: {
        ...headers,
        //...enrichedHeaders(),
      },
      ...config,
      data,
    });

  /**
   * ### send PATCH request
   * @param {HttpClientParams} params
   * @returns {AxiosPromise} response
   */
  const patch = <T = any, D = any>({
    version = apiVersion,
    url,
    data,
    headers,
    config,
  }: HttpClientParamsWithData<D>): AxiosPromise<T> =>
    axiosInstance.request<T>({
      url: constructUrl(serviceName, version, url),
      method: 'PATCH',
      headers: {
        ...headers,
        //...enrichedHeaders(),
      },
      ...config,
      data,
    });

  return {
    axiosInstance,
    get,
    delete: del,
    head,
    post,
    put,
    patch,
  };
};

export const request = createHttpClient();
