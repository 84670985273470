import React, {MutableRefObject, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faArrowUpFromBracket,
  faClose,
  faSpinner,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import {useTranslation} from 'react-i18next';

import * as CUI from '@CUI';
import {userModel} from '@entities/user';

import {uploadFileApi, deleteUploadedFileApi} from './model';
import {config} from './config';
import {OpionalTooltip} from '@common/perach-ui';
import {HelpKeyType, HelpText} from "@entities/settings";

type Response = {
  file_name: string;
  original_file_name: string;
  file_signed_url: string;
  path: string;
  status: string;
  url: string;
};

export type OnLoadCallbackType = {
  fileUrl: string;
  fileName: string;
  originalFileName: string;
  fieldName: string;
};

export type FileInputProps = {
  /** Field label */
  label: string;
  /** Tooltip text - if not set, not shown */
  helpText?: string;
  /** Form name(used for validation and form control) */
  form: string;
  /** Field name(used for validation and form control) */
  name: string;
  /** File type for API call, predefined values */
  fileType?: 'DOCUMENT' | 'APPEAL';
  /** Uploaded filename */
  fileName?: string;
  /** Link to open uploaded file*/
  link?: string;
  /** Is field required*/
  isRequired?: boolean;
  /** Successful load callback */
  onLoad?: (args: OnLoadCallbackType) => void;
  /** Delete file from server callback */
  onDelete?: (args: OnLoadCallbackType) => void;
  /** Prefilled values */
  prefilled?: { filename?: string; url?: string };
  /** is field disabled */
  isDisabled?: boolean;
  /** need fixes */
  isFixesNeed?: boolean;
  fixesText?: string;
};

export const FileInput: React.FC<FileInputProps> = ({
                                                      label,
                                                      form,
                                                      name,
                                                      helpText,
                                                      fileType = 'DOCUMENT',
                                                      isRequired = false,
                                                      onLoad = (args) => console.log(args),
                                                      onDelete = (args) => console.log(args),
                                                      prefilled,
                                                      isDisabled,
                                                      isFixesNeed,
                                                      fixesText,
                                                    }) => {
  const {t} = useTranslation();

  const fileRef =
    React.useRef<CUI.FileUploadTypes.FileUploadRefCurrent>() as MutableRefObject<CUI.FileUploadTypes.FileUploadRefCurrent>;

  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [result, setResult] = useState<Response | null>(null);
  const [wasDeleted, setWasDeleted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [componentError, setComponentError] = useState('');
  const shouldHideAction = isLoading;
  const isDanger = true;

  const actionText = isSuccess || !!prefilled?.url ? t('REMOVE_FILE') : '';

  const onDeleteAction = () => {
    if (result || !!prefilled) {
      setIsSuccess(false);
      setIsLoading(true);
      setWasDeleted(false);
      setIsError(false);
      deleteUploadedFileApi(
        fileType,
        result?.file_name || (prefilled?.filename as string)
      )
      .then((r) => {
        return r;
      })
      .then((r) => {
        if (r) {
          onDelete({
            fileUrl: result?.file_signed_url || '',
            fileName: result?.file_name || '',
            originalFileName: result?.original_file_name || '',
            fieldName: name,
          });
          setResult(null);
          setFile(null);
          setWasDeleted(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  };
  useEffect(() => {
    //@ts-ignore
    if (file && file.errors) {
      setIsError(true);
      //@ts-ignore
      if (file?.errors?.[0].code === 'file-too-large') {
        setComponentError(t('ERRORS.FILE_SIZE_ERROR'));
      }
      return;
    }
    if (file) {
      setComponentError('');
      setIsError(false);
      setIsLoading(true);
      setWasDeleted(false);
      const data = uploadFileApi(fileType, file)
      .then((r) => {
        setResult(r);
        return r;
      })
      .then((r) => {
        if (r) {
          setIsSuccess(true);
          onLoad({
            fileUrl: r.file_signed_url,
            fileName: r.file_name,
            originalFileName: r.original_file_name,
            fieldName: name,
          });
        }
      })
      .catch((e) => {
        console.log('submit error', e);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, [file]);

  return (
    <>
      <CUI.FileUpload
        _mb40
        _hidden
        name={name}
        ref={fileRef}
        allowedFiles={config.allowedFiles}
        maxFileSize={config.maxFileSize}
        onChange={({component}) => {
          setFile(component.value);
        }}
      />

      <CUI.Label _labelPerach>
        <CUI.Div _labelMore>
          <div>
            {label}
            {isFixesNeed && (
              <OpionalTooltip option={!!fixesText} text={fixesText || ''}>
                <CUI.Div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="mr-8 fix-color pointer fs-28"
                  />
                </CUI.Div>
              </OpionalTooltip>
            )}
          </div>
          <CUI.Div>
            <CUI.Div
              _ml16
              _pointer
              style={{display: 'inline-block'}}
              shouldRender={!shouldHideAction && !isDisabled}
              _labelDanger={isDanger}
              onClick={() => onDeleteAction()}
            >
              {actionText}
            </CUI.Div>
            <div style={{display: 'inline-block'}}>
              <HelpText fieldName={name as HelpKeyType}/>
            </div>
          </CUI.Div>
        </CUI.Div>
      </CUI.Label>

      <CUI.Input
        _needFixes={isFixesNeed}
        _emptyInput={!file?.name && !prefilled?.filename}
        _successUpload={isSuccess}
        name={`${name}##Input`}
        isDisabled={isLoading || isDisabled}
        form={form}
        value={file?.name || prefilled?.filename}
        //isValid={!isError}
        //validator={() => (!!file || !!prefilled) && !isLoading && !isError}
        isRequired={isRequired}
        placeholder=" "
        invalidMessage={t('ERRORS.FILE_SIZE_ERROR')}
        requiredMessage={t('FIELD_REQUIRED')}
        wrapperRender={({elementProps, Element}) => (
          <CUI.Div _mb40>
            <Element
              {...elementProps}
              onClick={() =>
                isLoading
                  ? null
                  : (isSuccess && result) || prefilled?.url
                    ? window.open(
                      result?.file_signed_url || prefilled?.url,
                      '_blank'
                    )
                    : isDisabled
                      ? null
                      : fileRef?.current?.wrapper?.click()
              }
            />
            <CUI.Span
              _inputMessage
              _loadSuccess={isSuccess || !!prefilled?.url}
            >
              {!!isError && (
                <CUI.Span _labelDanger>
                  {!!componentError ? componentError : t('FILE_LOADED_ERROR')}
                </CUI.Span>
              )}
              {isLoading && !isSuccess && t('FILE_UPLOADING')}
              {(!!prefilled?.url || isSuccess) &&
                !isLoading &&
                t('FILE_LOADED_SUCCESS')}
            </CUI.Span>
          </CUI.Div>
        )}
        inputRender={({componentState, elementProps, Element}) => (
          <>
            <CUI.Span _mr16>
              {(file?.name || prefilled?.filename) && !isError
                ? file?.name || prefilled?.filename
                : componentState.isValid
                  ? t('CHOOSE_FILE')
                  : t('PUT_FILE')}
            </CUI.Span>
            {!isLoading && !isSuccess && !prefilled?.url && (
              <FontAwesomeIcon
                icon={faArrowUpFromBracket}
                className="ml-16 fs-20"
              />
            )}
            {isLoading && !isSuccess && !prefilled?.url && (
              <span>
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="ml-16 fs-20 fa-spin"
                />
                <FontAwesomeIcon icon={faClose} className="ml-16 fs-20"/>
              </span>
            )}
            {(isSuccess || !!prefilled?.url) && !isLoading && (
              <CUI.Span _loadSuccess _ml16>
                {t('VIEW_FILE')}
              </CUI.Span>
            )}
          </>
        )}
      />
    </>
  );
};
