import {combineReducers} from 'redux';
import {History} from 'history';
import {connectRouter} from 'connected-react-router';

import {routerModel} from '@entities/store-router';
import {dictionariesModel} from '@entities/dictionaries';
import {userModel} from '@entities/user';
import {timeModel} from '@entities/time';
import {settingsModel} from '@entities/settings';

import {checkEligibilityModel} from '@features/CheckEligibilityForm';
import {personalInfoModel} from '@features/Registration';
import {personalAreaModel} from '@features/PersonalArea';
import {loginModel} from '@features/Login';
import {loaderModel} from '@entities/loader';
import {homePageModel} from '@features/HomePage';
import {faqPageModel} from '@features/FaqPage';
import {studentSurveyModel} from '@features/StudentSurvey';
import {scholarshipFaqPageModel} from '@features/SchoplarshipFaqPage';

const appReducer = (history: History) =>
  combineReducers({
    /** Entities */
    [routerModel.config.modelName]: connectRouter(history),
    [userModel.config.modelName]: userModel.reducer,
    [dictionariesModel.config.modelName]: dictionariesModel.reducer,
    [timeModel.config.modelName]: timeModel.reducer,
    [settingsModel.config.modelName]: settingsModel.reducer,
    [loaderModel.config.modelName]: loaderModel.reducer,
    /** Features */
    [checkEligibilityModel.config.modelName]: checkEligibilityModel.reducer,
    [personalInfoModel.config.modelName]: personalInfoModel.reducer,
    [personalAreaModel.config.modelName]: personalAreaModel.reducer,
    [loginModel.config.modelName]: loginModel.reducer,
    [homePageModel.config.modelName]: homePageModel.reducer,
    [faqPageModel.config.modelName]: faqPageModel.reducer,
    [studentSurveyModel.config.modelName]: studentSurveyModel.reducer,
    [scholarshipFaqPageModel.config.modelName]: scholarshipFaqPageModel.reducer,
  });

const rootReducer = appReducer;
export default rootReducer;
