import { createSlice } from '@reduxjs/toolkit';

import { storeUtils } from '@/common';
import { setStoreField } from '../../../common/store-utils';

import { config } from '../config';
import { User } from '../types';

const initialState: User = {
  processId: '',
  username: '',
  isLoggedIn: false,
  confirmSession: '',
  isSponsored: true,
  isFromSurvey: false,
  isShowSurveyFromRegistration: false,
  requestStatus: '',
  publishedState: '',
  requestDate: '',
  requestId: '',
};

const createAction = storeUtils.createActionCreatorWithPrefix(config.modelName);

/**
 * Action for logout
 */
const logout = createAction('logout');
const setIsShowSurveyFromRegistrationAction = createAction(
  'setIsShowSurveyFromRegistration'
);

export const createUserSlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    setProcessId: setStoreField('processId'),
    setUsername: setStoreField('username'),
    setIsLoggedIn: setStoreField('isLoggedIn'),
    setConfirmSession: setStoreField('confirmSession'),
    setIsSponsored: setStoreField('isSponsored'),
    setIsFromSurvey: setStoreField('isFromSurvey'),
    setIsShowSurveyFromRegistration: setStoreField(
      'isShowSurveyFromRegistration'
    ),
    setRequestStatus: setStoreField('requestStatus'),
    setPublishedState: setStoreField('publishedState'),
    setRequestDate: setStoreField('requestDate'),
    setRequestId: setStoreField('requestId'),
    reset: () => initialState,
  },
});

export const { reducer } = createUserSlice;

export const actions = {
  ...createUserSlice.actions,
  logout,
  setIsShowSurveyFromRegistrationAction,
};
