import {
  createModelSelector,
  createHookSelector,
  createModelFieldSelector,
} from '@common/store-utils';

import {config} from '../config';
import {ModelState} from '../types';

/**
 * Selector of model State
 */
const modelSelector = createModelSelector<ModelState>(config.modelName);

/**
 * Selector builder of model State field
 */
const fieldSelector = createModelFieldSelector<ModelState>(config.modelName);

/**
 * Selector - Carousel Items
 */
const faqCategoriesSelector = fieldSelector('faqCategories');

/**
 * Selector - Info Squares
 */
const faqCategorySelector = fieldSelector('faqCategory');

/**
 * Selector -Search Input
 */
const searchInputSelector = fieldSelector('searchInput');

export const selectors = {
  useFaqCategories: createHookSelector(faqCategoriesSelector),
  usefFaqCategory: createHookSelector(faqCategorySelector),
  useSearchInput: createHookSelector(searchInputSelector),
};
