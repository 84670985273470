import { format, parseISO, parse, isMatch } from 'date-fns';
import { ru } from 'date-fns/locale';
import { MILLISECONDS_IN_SECOND } from './config';

import { ServerDate, UiDate } from './types';

const isDate = (value: Date | string): value is Date => value instanceof Date;

/**
 * Конвертирует дату в другой формат
 *
 * @param date дата
 * @param toFormat формат
 * @returns дата
 */
export function convertDate(date: Date, toFormat: UiDate | ServerDate): string;

/**
 * Конвертирует дату из одного формата в другой
 *
 * @param date дата
 * @param fromFormat формат
 * @param toFormat формат
 * @returns дата
 */
export function convertDate(
  date: string,
  fromFormat: UiDate | ServerDate,
  toFormat: UiDate | ServerDate,
): string;

/**
 * Конвертирует дату из одного формата в другой
 *
 * @param date дата
 * @param formats форматы
 *
 * @returns дата
 */
export function convertDate(
  date: Date | string,
  ...formats: (UiDate | ServerDate)[]
): string {
  const isString = !isDate(date);

  const fromFormat = isString ? formats[0] : undefined;
  const toFormat = isString ? formats[1] : formats[0];

  const parsedDate = isDate(date)
    ? date
  // @ts-ignore
    : parse(date, fromFormat, new Date(), { locale: ru });

  const formattedDate = format(parsedDate, toFormat, { locale: ru });

  return isMatch(formattedDate, toFormat, { locale: ru })
    ? formattedDate
    : format(new Date(9999, 11, 31), toFormat, { locale: ru });
}

/**
 * Конвертирует дату из серверного в UI формат
 *
 * @param serverDate дата
 * @param uiFormat ui формат
 * @returns дата
 */
export const convertDateFromServerToUi = (
  serverDate: string,
  uiFormat: UiDate,
): string => format(parseISO(serverDate), uiFormat, { locale: ru });

/**
 * Получить текущую дату
 *
 * @param dateFormat формат
 * @returns дата
 */
export const currentDate = (dateFormat: UiDate | ServerDate): string =>
  format(new Date(), dateFormat, { locale: ru });

/**
 * Конвертирует миллисекунды в секунды
 *
 * @param milliseconds миллисекунды
 * @returns секунды
 */
export const convertMillisecondsToSeconds = (milliseconds: number): number => {
  const seconds = milliseconds / MILLISECONDS_IN_SECOND;
  return Math.floor(seconds);
};
