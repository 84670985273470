import React from 'react';

import { Tooltip } from '../components/shared/Tooltip/tooltip';

type Props = {
  option: boolean;
  text: string;
};

/**
 * Conditionally rendered tooltip
 * @returns React.FC
 * */
export const OpionalTooltip: React.FC<Props> = ({
  option,
  text,
  children,
  ...restProps
}) =>
  option ? (
    <Tooltip text={text} {...restProps} placement='top'>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
