import { SagaIterator } from 'redux-saga';
import { all, call, select, takeEvery, delay, put } from 'redux-saga/effects';
import { getFaqCategories } from './api';
import { actions } from './ducks';

/**
 * Get Scholarship Faq items
 */
function* getFaqCategoriesSaga(): SagaIterator {
  try {
    const response = yield call(getFaqCategories);
    if (response.data.length) {
      yield put(actions.setScholarshipFaqCategories(response.data));
    }
  } catch (e) {
    console.log('ERROR FROM CAROUSEL ITEMS', e);
    console.warn('Faced some problems during login operation', e);
  }
}

/**
 * Watcher
 * @returns {void}
 */
function* watcher(): SagaIterator<void> {
  yield all([
    takeEvery(actions.getScholarshipFaqCategories, getFaqCategoriesSaga),
  ]);
}

export const sagas = {
  watcher,
};
