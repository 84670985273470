import { isString } from 'lodash';
import { getClassNames } from './helpers';
import { SomeObject } from '../commonTypes';
import { UnderscoreClasses } from '../components/Provider';
import { underscorePropToClassName } from './underscorePropToClassName';

// collects all _classes and puts it to props.className and props.className
export const mergeClassNames = <Props>(props: Props, { underscoreClassesTransform }: { underscoreClassesTransform: UnderscoreClasses }): Props & { className?: string } => {
  const classNames: Set<string> = new Set();
  const restProps: {[prop: string]: unknown} = {};
  const componentProps = props as unknown as SomeObject;

  Object.keys(componentProps)
    .forEach((prop: string): void => {
      if (componentProps[prop] && prop[0] === '_' && componentProps[prop] === true) {
        const transformedClassName = underscorePropToClassName(prop, underscoreClassesTransform);

        classNames.add(transformedClassName);

      } else if ((componentProps[prop] === false && prop[0] === '_')) {

      } else {
        restProps[prop] = componentProps[prop];
      }
    });

  const restClassName = isString(restProps.className) ? restProps.className : null;

  const propsWithoutUnderscore: Props = restProps as unknown as Props;

  return {
    ...propsWithoutUnderscore,
    className: getClassNames(...classNames, restClassName),
  };
};
