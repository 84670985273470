import { SagaIterator } from 'redux-saga';
import { all, call, select, takeEvery, delay, put } from 'redux-saga/effects';
import {
  getCarouselItems as carouselItemsApi,
  getInfoSquares as infoSquaresApi,
} from './api';
import { actions } from './ducks';

/**
 * Get Carousel Items
 */
function* getCarouselItems(): SagaIterator {
  try {
    const response = yield call(carouselItemsApi);
    if (response.data.length) {
      yield put(actions.setCarouselItems(response.data));
    }
  } catch (e) {
    console.log('ERROR FROM CAROUSEL ITEMS', e);
    console.warn('Faced some problems during login operation', e);
  }
}
/**
 * Get Carousel Items
 */
function* getInfoSquares(): SagaIterator {
  try {
    const response = yield call(infoSquaresApi);
    if (response.data.length) {
      yield put(actions.setInfoSquares(response.data));
    }
  } catch (e) {
    console.log('ERROR FROM CAROUSEL ITEMS', e);
    console.warn('Faced some problems during login operation', e);
  }
}

/**
 * Watcher
 * @returns {void}
 */
function* watcher(): SagaIterator<void> {
  yield all([
    takeEvery(actions.getCarouselItems, getCarouselItems),
    takeEvery(actions.getInfoSquares, getInfoSquares),
  ]);
}

export const sagas = {
  watcher,
};
