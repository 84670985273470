import * as React from 'react';
import { isString, isNil } from 'lodash';
import { useElement, checkFormProps } from '../../utils';
import {
  addField, getValidators, removeField, updateField, validate,
} from './helpers';
import { InvalidMessage as DefaultInvalidMessage } from './InvalidMessage';
import {
  ValidationProps, ValidationState, ValidationExtra, ValidationResult,
} from './types';

export const useValidation = <P extends ValidationProps, S extends ValidationState>(
  props: P, state: S, extra: ValidationExtra,
): ValidationResult => {
  const {
    form,
    name,
    isRequired = false,
    isValid: isValidProp,
    shouldValidateUnmounted,
    validator,
    invalidMessage,
    requiredMessage,
    invalidMessageRender,
    mask,
    placeholderChar,
  } = props;

  checkFormProps(props);

  // значение берется из props или из state
  const value = props.value === undefined && state
    ? state.value
    : props.value;

  const [isValid, setIsValid] = React.useState<boolean>(true);

  const [messages, setMessages] = React.useState<string[] | undefined>([]);

  React.useEffect((): (() => void) | void => {
    if (isValidProp !== undefined && invalidMessage) setMessages([invalidMessage]);

    if (isString(form) && name) {
      const validators = getValidators(validator, invalidMessage, isValidProp);

      addField({
        fieldName: name,
        formName: form,
        invalidMessage,
        isRequired,
        mask,
        placeholderChar,
        requiredMessage,
        reset: extra.reset,
        setIsValid,
        setMessages,
        shouldValidateUnmounted,
        validators,
        value,
      });

      return (): void => {
        removeField(form, name);

        setIsValid(true);
      };
    }

    return undefined;
  }, [form, name]);

  React.useEffect(() => {
    if (form && name) {
      const validators = getValidators(validator, invalidMessage, isValidProp);

      updateField({
        fieldName: name,
        formName: form,
        invalidMessage,
        isRequired,
        isValidProp,
        mask,
        placeholderChar,
        requiredMessage,
        shouldValidateUnmounted,
        validators,
        value,
      });
    }
  }, [form, isRequired, name, value, isValidProp, validator, invalidMessage, shouldValidateUnmounted, requiredMessage, mask, placeholderChar]);

  const isValidateCurrent = true;

  /** Function that user receives to validate current field, it can be called, for example, in onBlur handler */
  const validateCurrent = React.useCallback((val?: unknown) => (
    isNil(isValidProp)
      ? validate(form, name, val, isValidateCurrent)
      : isValidProp
  ), [form, isValidProp, name, isValidateCurrent]);

  const InvalidMessage = useElement(
    'InvalidMessage',
    DefaultInvalidMessage,
    invalidMessageRender,
    props,
    state,
  );

  const invalidMessageComponent = React.useMemo(() => {
    const message: React.FC<{}> = () => <InvalidMessage isValid={isValid} messages={messages} />;
    message.displayName = 'InvalidMessageWrapper';

    return message;
  }, [isValid, messages]);

  if (!form) return { InvalidMessage: invalidMessageComponent, isValid: true, validateCurrent: (): boolean => true };

  return {
    InvalidMessage: invalidMessageComponent,
    isValid,
    validateCurrent,
  };
};
