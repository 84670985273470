import { sagas, actions, reducer, selectors } from './model';
import { config } from './config';

export { getYearLib } from './utils';

export * from './types';

export * from './hooks';

export const timeModel = {
  actions,
  reducer,
  sagas,
  config,
  selectors,
};