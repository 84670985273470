import { DicRecord } from './types';

export const getTextValue = (
  value: string,
  texts: Record<string, string>
): string => {
  if (texts.hasOwnProperty(value)) {
    return texts[value];
  }
  return value;
};

export const fixValue = (value: DicRecord, list: DicRecord[]): DicRecord => {
  if (!!value?.value && !!value?.label) {
    return value;
  }
  if (!!value.value && !value?.label) {
    const result = list?.find((el) => el.value === value?.value) || {
      value: '',
      label: '',
    };
    return result;
  }
  return { value: '', label: '' };
};

export const transformFields = (
  fields: Record<string, string>
): Record<string, boolean> =>
  Object.keys(fields).reduce(
    (attrs, key) => ({
      ...attrs,
      [key]: true,
    }),
    {}
  );

export const transformWithObjectFields = (
  fields: Record<string, string>,
  someObject: any
): Record<string, boolean> =>
  Object.keys(fields).reduce(
    (attrs, key) => ({
      ...attrs,
      [key]: !someObject[key],
    }),
    {}
  );
