import React, { ReactFragment } from 'react';
import { Modal as RModal, ModalBody, ModalHeader } from 'reactstrap';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as C from '@CUI';

type ModalProps = {
  isModalOpen: boolean;
  modalBody: ReactFragment;
  header: string;
  closeModal: () => void;
};

export const Modal: React.FC<ModalProps> = ({
  closeModal,
  isModalOpen,
  modalBody,
  header,
}) => {
  return (
    <RModal contentClassName='modal-shadow' isOpen={isModalOpen} size={'md'} centered={true}>
      <ModalHeader>
        <C.Div _modalHeaderContainer>
          <C.Button _modalButton onClick={() => closeModal()}>
            <FontAwesomeIcon icon={faClose} color="black" />
          </C.Button>
          <C.Span _header>{header}</C.Span>
        </C.Div>
      </ModalHeader>
      <ModalBody>{modalBody}</ModalBody>
    </RModal>
  );
};
