export const compareDateStringWithUI = (
  serverDate: string,
  uiDate: string,
  years: number
): boolean => {
  const serverConverted = new Date(serverDate) || new Date();
  const uiParsed = uiDate?.split('.') || ['01', '01', '2000'];
  const uiRebuilt = `${uiParsed[1] || '01'}.${uiParsed[0] || '01'}.${
    uiParsed[2] || '2000'
  }`;
  const uiConverted = new Date(uiRebuilt);
  const serverYears = serverConverted.getFullYear();
  const uiYears = uiConverted.getFullYear();

  return serverYears - uiYears <= years;
};

export const dateDiff = (startUi: string, endServer: string) => {
  const parsed = startUi?.split('.') || ['01', '01', '2000'];
  const today = new Date(endServer);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const yy = parseInt(parsed[2] || '2000');
  const mm = parseInt(parsed[1] || '01');
  const dd = parseInt(parsed[0] || '01');

  // months
  let months = month - mm;
  if (day < dd) {
    months = months - 1;
  }
  // years
  let years = year - yy;
  // eslint-disable-next-line no-mixed-operators
  if (month * 100 + day < mm * 100 + dd) {
    years = years - 1;
    months = months + 12;
  }
  // days
  const days = Math.floor((today.getTime() - (new Date(yy + years, mm + months - 1, dd)).getTime()) / (24 * 60 * 60 * 1000));

  return {years, months, days};
}

export const isUserUnder30 = (period: string, user: string) =>
  dateDiff(user, period)?.years < 30
//compareDateStringWithUI(period, user, 30);

export const subtractYears = (numOfYears: number, date = new Date()) => {
  date.setFullYear(date.getFullYear() - numOfYears);
  return date;
};

export const getReservistDatesRange = (): string => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const october31ThisYear = new Date(currentYear, 9, 31);

  let endYear = currentYear;
  if (currentDate <= october31ThisYear) {
    endYear -= 1;
  }

  const startYear = endYear - 1;
  const startDate = `30.09.${startYear}`;
  const endDate = `1.12.${endYear}`;

  return `האם עשית שירות מילואים בין ${startDate} לבין ${endDate}?`;
}
