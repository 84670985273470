import React from 'react';
import * as CUI from '@CUI';
import { Modal } from '@common/perach-ui';
import { actions } from '../model';
import { useActions } from '@common/store-utils/hooks';
import { useTranslation } from 'react-i18next';

type props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

export const SendBackFromRepairModal: React.FC<props> = ({
  isOpen,
  setIsOpen,
}) => {
  const { sendDocumentsAfterFixes } = useActions(actions);
  const { t } = useTranslation();
  return (
    <Modal
      isModalOpen={isOpen || false}
      header={t('RETURN_AFTER_FIX_MODAL.TITLE')}
      modalBody={
        <CUI.Div _modalBodyContainer>
          <CUI.Span _modalBodyHeader _mt24>
            {t('RETURN_AFTER_FIX_MODAL.TEXT')}
          </CUI.Span>
          <CUI.Div _inline>
            <CUI.Button
              _btnPrimaryPerach
              _mb16
              _mt24
              _ml16
              onClick={() => {
                setIsOpen(false);
                sendDocumentsAfterFixes();
              }}
            >
              {t('RETURN_AFTER_FIX_MODAL.SEND')}
            </CUI.Button>
            <CUI.Button
              _btnInfoPerach
              _noMinWidthMob
              _mb16
              _mt24
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('RETURN_AFTER_FIX_MODAL.CANCEL')}
            </CUI.Button>
          </CUI.Div>
        </CUI.Div>
      }
      closeModal={() => setIsOpen(false)}
    />
  );
};
