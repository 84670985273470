import { SagaIterator } from 'redux-saga';
import { put, all, takeEvery, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { globalConfig } from '@/globalConfig';

import { actions } from './ducks';
import { selectors } from '@entities/user/model/selectors';

/**
 * Logout saga
 * @returns {void}
 */
function* logoutSaga(): SagaIterator {
  yield call(
    [window.localStorage, window.localStorage.removeItem],
    globalConfig.storage.tokenKey
  );
  yield put(actions.setConfirmSession(''));
  yield put(actions.setIsLoggedIn(false));
  yield put(push(globalConfig.routes.main()));
  yield put(actions.setIsLoggedIn(false));
}

/**
 * Watcher
 * @returns {void}
 */
function* watcher(): SagaIterator<void> {
  yield all([takeEvery(actions.logout, logoutSaga)]);
}

export const sagas = {
  watcher,
};
