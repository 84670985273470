import {SagaIterator} from 'redux-saga';
import {call, all, fork} from 'redux-saga/effects';

import {dictionariesModel} from '@entities/dictionaries';
import {timeModel} from '@entities/time';
import {settingsModel} from '@entities/settings';
import {userModel} from '@entities/user';

import {checkEligibilityModel} from '@features/CheckEligibilityForm';
import {personalInfoModel} from '@features/Registration';
import {personalAreaModel} from '@features/PersonalArea';
import {loginModel} from '@features/Login';
import {homePageModel} from '@features/HomePage';
import {faqPageModel} from '@features/FaqPage';
import {studentSurveyModel} from '@features/StudentSurvey';
import {scholarshipFaqPageModel} from '@features/SchoplarshipFaqPage';

/**
 * Main saga - entry point
 *
 * @returns {void}
 */
export function* rootSaga(): SagaIterator {
  // eslint-disable-next-line no-console
  yield call(console.log, 'Root Saga Runner...!');

  yield all(
    [
      /** Entities */
      dictionariesModel.sagas.watcher,
      timeModel.sagas.watcher,
      settingsModel.sagas.watcher,
      userModel.sagas.watcher,
      /** Features */
      checkEligibilityModel.sagas.watcher,
      personalInfoModel.sagas.watcher,
      personalAreaModel.sagas.watcher,
      loginModel.sagas.watcher,
      homePageModel.sagas.watcher,
      faqPageModel.sagas.watcher,
      studentSurveyModel.sagas.watcher,
      scholarshipFaqPageModel.sagas.watcher,
    ].map(fork)
  );
}
