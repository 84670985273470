import React from 'react';
import * as CUI from '@CUI';
import { Modal } from '@common/perach-ui';
import { actions } from '../model';
import { useActions } from '@common/store-utils/hooks';
import { useTranslation } from 'react-i18next';

type props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  isReturnedToRepair?: boolean;
};

export const DontForgetToPressSubmitModal: React.FC<props> = ({
  isOpen,
  setIsOpen,
  isReturnedToRepair = false,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      isModalOpen={isOpen || false}
      header={t('DONT_FORGET_TO_SUBMIT_MODAL.HEADER')}
      modalBody={
        <CUI.Div _modalBodyContainer>
          <CUI.Span _modalBodyHeader _mt24>
            {isReturnedToRepair ? (
              <>
                הבקשה הוחזרה אליך לתיקון:
                <ul>
                  <li>
                    ברשותך 10 ימים ממועד קבלת המייל וה-SMS המודיעים על החזרת
                    הבקשה לתיקון
                  </li>
                  <li>
                    המסמכים הנדרשים לתיקון מסומנים בצהוב, ובאייקון (!) ישנו הסבר
                    מה נדרש לתיקון
                  </li>
                  <li>
                    עליך לעבור על כל העמוד, לאתר את כל השדות הצהובים ולתקן בהתאם
                    למידע המופיע באייקון ה-(!)
                  </li>
                  <li>
                    בטווח ה-10 הימים ניתן לחזור ולתקן את המסמכים. לאחר הגשת
                    התיקונים לא ניתן יהיה לערוך יותר, גם אם לא כל 10 הימים
                    הסתיימו
                  </li>
                  <li>
                    בסיום העריכה, יש להגיש את התיקון בלחיצה על "הגש.י תיקונים"
                  </li>
                </ul>
              </>
            ) : (
              t('DONT_FORGET_TO_SUBMIT_MODAL.TEXT')
            )}
          </CUI.Span>
          <CUI.Div _inline>
            <CUI.Button
              _btnPrimaryPerach
              _mb16
              _mt24
              _ml16
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('DONT_FORGET_TO_SUBMIT_MODAL.CLOSE')}
            </CUI.Button>
          </CUI.Div>
        </CUI.Div>
      }
      closeModal={() => setIsOpen(false)}
    />
  );
};
