import { SagaIterator } from 'redux-saga';
import { all, call, select, takeEvery, delay, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { globalConfig } from '@/globalConfig';

import { userModel } from '@entities/user';
import { actions } from './ducks';

import { selectors } from './selectors';
import { settingsModel } from '@entities/settings';
import { resetPassword } from '@features/Login/model/api';
import { useLocation } from 'react-router-dom';
import {
  checkEligibilityModel,
  UserStatus,
} from '@features/CheckEligibilityForm';
import { loaderModel } from '@entities/loader';

/**
 * Login by Email and password Saga
 * @returns {void}
 */
function* loginByPasswordSaga(): SagaIterator {
  const email = yield select(selectors.email);
  const password = yield select(selectors.password);
  const registerPeriod = yield select(settingsModel.selectors.registerPeriod);
  yield put(actions.setError(false));
  yield put(loaderModel.actions.setIsLoading(true));

  try {
    const data = yield call(
      userModel.api.loginByPasswordApi,
      registerPeriod,
      email,
      password
    );
    if (data.jwtToken) {
      yield delay(0);
      yield call(
        [window.localStorage, window.localStorage.setItem],
        globalConfig.storage.tokenKey,
        data.jwtToken
      );
      yield call(
        [window.localStorage, window.localStorage.removeItem],
        globalConfig.storage.isAdmin
      );
      yield call(
        [window.localStorage, window.localStorage.removeItem],
        globalConfig.storage.userId
      );
      yield put(userModel.actions.setIsLoggedIn(true));
    }
  } catch (error: any) {
    console.log(error);
    if (
      error?.response?.data?.error?.message ===
      'Incorrect username or password.'
    ) {
      yield put(
        actions.setNotExistUserValidation('שם המשתמש או הססמא אינם נכונים')
      );
    } else if (
      error?.response?.data?.error?.message === 'User does not exist.'
    ) {
      yield put(actions.setNotExistUserValidation('המשתמש אינו קיים'));
    } else {
      yield put(
        actions.setNotExistUserValidation(error?.response?.data?.error?.message)
      );
    }
    yield put(actions.setError(true));
    console.warn('Faced some problems during login operation', error);
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

/**
 * Login by phone and OTP code Saga
 * @returns {void}
 */
function* loginByPhoneSaga(): SagaIterator {
  const phone = yield select(selectors.phone);
  yield put(loaderModel.actions.setIsLoading(true));

  try {
    const data = yield call(userModel.api.loginByPhoneApi, phone);
    yield put(userModel.actions.setConfirmSession(data.session));
  } catch (error: any) {
    console.log(error);
    if (error?.response?.data?.error?.message === 'User does not exist.') {
      yield put(actions.setNotExistUserValidation('המשתמש אינו קיים'));
    } else {
      yield put(
        actions.setNotExistUserValidation(error?.response?.data?.error?.message)
      );
    }
    console.warn('Faced some problems during login operation', error);
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

/**
 * Confirm login by phone with OTP code Saga
 * @returns {void}
 */
function* confirmOtpAfterLoginSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));

  const session = yield select(userModel.selectors.confirmSession);
  const registerPeriod = yield select(settingsModel.selectors.registerPeriod);
  const phone = yield select(selectors.phone);
  const code = yield select(selectors.otpCode);

  try {
    const data = yield call(
      userModel.api.confirmOtpCodeAfterLoginApi,
      session,
      `+972${phone.substring(1)}_${registerPeriod}`,
      code
    );
    if (data.jwtToken) {
      yield delay(0);
      yield call(
        [window.localStorage, window.localStorage.setItem],
        globalConfig.storage.tokenKey,
        data.jwtToken
      );
      yield call(
        [window.localStorage, window.localStorage.removeItem],
        globalConfig.storage.isAdmin
      );
      yield call(
        [window.localStorage, window.localStorage.removeItem],
        globalConfig.storage.userId
      );
      yield put(userModel.actions.setIsLoggedIn(true));
      if (userModel.selectors.useIsFromSurvey()) {
        yield put(push(globalConfig.routes.personalArea()));
      } else {
        yield put(push(globalConfig.routes.main()));
      }
    }
    yield put(loaderModel.actions.setIsLoading(false));
  } catch (e) {
    if (e.response?.data?.error?.statusCode === 400) {
      yield put(
        actions.setErrorMessage(
          e?.response?.data?.error?.message || 'Unexpected login error'
        )
      );
    }
    yield put(actions.setError(true));
    yield put(loaderModel.actions.setIsLoading(false));
    console.warn('Faced some problems wile confirming login with OTP', e);
  }
}

/**
 * Resend the OTP code for login Saga
 * @returns {void}
 */
function* resendOtpCodeSaga(): SagaIterator {
  const phone = yield select(selectors.phone);
  try {
    const data = yield call(userModel.api.resendOtpCodeApi, phone);
    yield put(userModel.actions.setConfirmSession(data.session));
  } catch (e) {
    console.warn('Faced some problems requesting new OTP', e);
  }
}

function* restorePasswordSaga(): SagaIterator {
  const password = yield select(selectors.password);
  const password_verification = yield select(selectors.newPassword);
  const code = yield select(selectors.otpCode);
  const phone = yield select(selectors.phone);
  try {
    const resetPasswordResponse = yield call(resetPassword, {
      code,
      password,
      password_verification,
      phone,
    });
    if (resetPasswordResponse.status === 201) {
      yield put(actions.setIsResetPasswordSuccess(true));
    }
  } catch (e) {}
}

/**
 * Watcher
 * @returns {void}
 */
function* watcher(): SagaIterator<void> {
  yield all([
    takeEvery(actions.loginByPassword, loginByPasswordSaga),
    takeEvery(actions.loginByPhone, loginByPhoneSaga),
    takeEvery(actions.resendCode, resendOtpCodeSaga),
    takeEvery(actions.verifyCodeAfterLogin, confirmOtpAfterLoginSaga),
    takeEvery(actions.restorePassword, restorePasswordSaga),
  ]);
}

export const sagas = {
  watcher,
};
