import React from 'react';
import * as CUI from '@CUI';
import { Modal } from '@common/perach-ui';
import { useActions } from '@common/store-utils/hooks';
import { useTranslation } from 'react-i18next';
import { globalConfig } from '@/globalConfig';
import { useHistory } from 'react-router-dom';

type props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

export const OnLoadModal: React.FC<props> = ({ isOpen, setIsOpen }) => {
  const history = useHistory();

  return (
    <Modal
      isModalOpen={isOpen || false}
      header={'תשומת הלב'}
      modalBody={
        <CUI.Div _modalBodyContainer>
          <CUI.Span _modalBodyHeader _mt24>
            ההרשמה פתוחה <strong>אך ורק</strong> לכוחות הביטחון (אנשי מילואים,
            משרתי קבע, משטרה, כבאות והצלה, שב"כ ומוסד) ששירתו בין 07/10/23 לבין
            01/02/24 לפחות 100 ימים (ידרש מסמך המאשר זאת)- כל בקשה שלא תעמוד
            בכללים אלו- תדחה על הסף מבלי להיבדק כלל
          </CUI.Span>
          <CUI.Div _inline>
            <CUI.Button
              _btnPrimaryPerach
              _mb16
              _mt24
              _ml16
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {'שירתתי 100 ימים ומעלה'}
            </CUI.Button>
            <CUI.Button
              _btnInfoPerach
              _noMinWidthMob
              _mb16
              _mt24
              onClick={() => {
                setIsOpen(false);
                history.push(globalConfig.routes.main());
              }}
            >
              {'אני לא עומד בתנאי הנ"ל'}
            </CUI.Button>
          </CUI.Div>
        </CUI.Div>
      }
      closeModal={() => setIsOpen(false)}
    />
  );
};
