import * as React from 'react';
import { CustomRender, SetState } from '../../commonTypes';

/** names of pre-validation result */
export enum PreValidationResult {
  /** pre-validation determined that field being checked is invalid */
  FieldIsInvalid = 'field-is-invalid',
  /** pre-validation determined that field being checked is valid */
  FieldIsValid = 'field-is-valid',
  /** pre-validation determined that validators needed to be run */
  RequireRunValidators = 'require-run-validators',
}

export interface ValidationProps {
  /** Form name */
  form?: string;
  /** Custom message to show if field validation failed */
  invalidMessage?: string;
  /** Configuring appearance of validation error message */
  invalidMessageRender?: CustomRender<
    ValidationProps,
    ValidationState,
    InvalidMessageProps
  >;
  /** Required field or not */
  isRequired?: boolean;
  /** Attribute for setting validity from outside */
  isValid?: boolean;
  /** Input mask. It is set according to rules described in mask.md */
  mask?: string;
  /** Name of field in form */
  name?: string;
  /** Placeholder for editable chars in the mask */
  placeholderChar?: string;
  /** Message when validating an empty field */
  requiredMessage?: string;
  /** Field must be validated even in unmounted state */
  shouldValidateUnmounted?: boolean;
  /**
   * Field value validator (regular expression, function, string with name of
   * a ready-made validator or a list of validators in an array)
   */
  validator?: Validator | PredefinedValidator | RegExp | ValidatorObject[];
  /** Current value */
  value?: any;
}

export interface ValidationButtonProps {
  form?: string | string[];
}

export interface Validator {
  (value: any): boolean;
}

export interface ValidatorObject {
  invalidMessage?: string;
  validator: string | RegExp | Validator;
}

export interface NormalizedValidatorObject extends ValidatorObject {
  validator: Validator;
}

export interface ValidationState {
  value?: any;
}

// результат - isValid, validateField, validateForm
export interface ValidationResult {
  InvalidMessage: React.FC<{}>;
  isValid: boolean;
  validateCurrent: (value?: any) => boolean;
}

export interface FormGetField {
  isFilled: boolean;
  isRequired: boolean;
  isValid: boolean;
  name: string;
  value: any;
}

export interface Field {
  /** Custom message to show if field validation failed */
  invalidMessage?: string;
  /** Messages Array to show if field validation failed */
  invalidMessages?: string[];
  /** Required field or not */
  isRequired: boolean;
  /** Attribute for setting validity from outside */
  isValid: boolean;
  /** Input mask. It is set according to rules described in mask.md */
  mask?: string;
  /** Name of field in form */
  name: string;
  /** Placeholder for editable chars in the mask */
  placeholderChar?: string;
  /** Message when validating an empty field */
  requiredMessage?: string;
  /** Function for clearing form fields */
  reset: () => void;
  /** Setter for isValid property */
  setIsValid: SetState<boolean>;
  /** Setter for invalid messages array */
  setMessages: SetState<string[] | undefined>;
  /** Field must be validated even in unmounted state */
  shouldValidateUnmounted: boolean;
  /** Field value validators array */
  validators: NormalizedValidatorObject[];
  /** Current value */
  value: any;
}

export interface Form {
  fields: Field[];
  name: string;
}

export interface FormsObject {
  [formName: string]: {
    [fieldName: string]: Field;
  };
}

export interface InvalidMessageProps {
  isValid: boolean;
  messages?: string[];
}

export type PredefinedValidator = 'email' | 'password' | 'url' | 'israelId';

export interface AddFieldData {
  /** Name of field in form */
  fieldName: string;
  /** Form name */
  formName: string;
  /** Custom message to show if field validation failed */
  invalidMessage?: string;
  /** Required field or not */
  isRequired?: boolean;
  /** Input mask. It is set according to rules described in mask.md */
  mask?: string;
  /** Placeholder for editable chars in the mask */
  placeholderChar?: string;
  /** Message when validating an empty field */
  requiredMessage?: string;
  /** Function for clearing form fields */
  reset: () => void;
  /** Setter for isValid property */
  setIsValid: SetState<boolean>;
  /** Setter for invalid messages array */
  setMessages: SetState<string[] | undefined>;
  /** Field must be validated even in unmounted state */
  shouldValidateUnmounted?: boolean;
  /** Field value validators array */
  validators: NormalizedValidatorObject[];
  /** Current value */
  value: unknown;
}

export interface UpdateFieldData {
  /** Name of field in form */
  fieldName: string;
  /** Form name */
  formName: string;
  /** Custom message to show if field validation failed */
  invalidMessage?: string;
  /** Required field or not */
  isRequired?: boolean;
  /** Attribute for setting validity from outside */
  isValidProp?: boolean;
  /** Input mask. It is set according to rules described in mask.md */
  mask?: string;
  /** Placeholder for editable chars in the mask */
  placeholderChar?: string;
  /** Message when validating an empty field */
  requiredMessage?: string;
  /** Field must be validated even in unmounted state */
  shouldValidateUnmounted?: boolean;
  /** Field value validators array */
  validators: NormalizedValidatorObject[];
  /** Current value */
  value: unknown;
}

export interface ValidationExtra {
  reset: () => void;
}

export interface RemoveFieldOptions {
  shouldRemoveUnmounted?: boolean;
}
