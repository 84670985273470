import { validate } from '../../validators';
import { NormalizedValidatorObject, PredefinedValidator } from './types';

export const PREDEFINED_VALIDATORS: Record<PredefinedValidator, NormalizedValidatorObject> = {
  email: {
    invalidMessage: 'Enter correct E-Mail',
    validator: validate.email,
  },
  password: {
    invalidMessage: 'Enter correct password',
    validator: validate.password,
  },
  url: {
    invalidMessage: 'Enter correct URL',
    validator: validate.url,
  },
  israelId: {
    invalidMessage: 'Enter correct ID number',
    validator: validate.israelId,
  }
};
