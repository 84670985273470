
export const selectAllSuggestion = {
  text: 'Choose all',
};

export enum SelectedState {
  Nothing,
  Some,
  All
}
