import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { userModel } from '@entities/user';
import {
  getAcademicApi,
  getBanksApi,
  getCitiesApi,
  getDistrictsApi,
  getSectorsApi,
  getStudyFieldsApi,
} from './api';
import { actions } from './ducks';
import { selectors } from './selectors';
import { composeSaga, passActionPayload } from '@common/store-utils';
import { Dictionaries } from '@entities/dictionaries';

/**
 * Load Dictionaries
 * @returns {void}
 */
function* loadDictionariesSaga(): SagaIterator {
  const isLoggedIn = yield select(userModel.selectors.isLoggedIn);
  try {
    const academicList = yield select(selectors.academicInstitution);
    const banksList = yield select(selectors.banks);

    if (!banksList?.length) {
      const banks = yield call(getBanksApi);
      yield put(actions.setBanks(banks));
    }

    if (!academicList?.length) {
      const academic = yield call(getAcademicApi);
      yield put(actions.setAcademicInstitutions(academic));
    }

    if (isLoggedIn) {
      const cities = yield call(getCitiesApi);
      yield put(actions.setCities(cities));
    }

    if (isLoggedIn) {
      const studyFields = yield call(getStudyFieldsApi);
      yield put(actions.setStudyFields(studyFields));
    }

    if (isLoggedIn) {
      const sectors = yield call(getSectorsApi);
      yield put(actions.setSector(sectors));
    }

    if (isLoggedIn) {
      const banks = yield call(getBanksApi);
      yield put(actions.setBanks(banks));
    }
  } catch (e) {
    console.warn('Dictionary load error:', e);
  }
}

function* getDistrictsSaga(city_symbol: string): SagaIterator {
  yield put(
    actions.setLoading({
      isLoading: true,
      dictionaryName: Dictionaries.Districts,
    })
  );
  try {
    const result = yield call(getDistrictsApi, city_symbol);
    yield put(actions.setDistricts(result));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(
      actions.setLoading({
        isLoading: false,
        dictionaryName: Dictionaries.Districts,
      })
    );
  }
}

/**
 * Watcher
 * @returns {void}
 */
function* watcher(): SagaIterator<void> {
  yield all([
    takeEvery(actions.getDictionaries, loadDictionariesSaga),
    takeEvery(
      actions.getDistricts,
      composeSaga(getDistrictsSaga, [passActionPayload])
    ),
  ]);
}

export const sagas = {
  watcher,
  loadDictionariesSaga,
};
