import { TransformSuggestionToMatchFilter } from '../commonTypes';

export const FILTER_RULES = {
  includes: 'includes' as const,
  smart: 'smart' as const,
  startsWith: 'startsWith' as const,
};

/**
 * Normalizes string for later comparison
 * @param {string} str - Unnormalized string
 * @param {TransformSuggestionToMatchFilter | undefined} transformSuggestionToMatchFilter - Eliminates differences between suggestions from filtered list and value in input
 *
 * @returns {string} Normalized string
 */
export const normalizeString = (str: string, transformSuggestionToMatchFilter?: TransformSuggestionToMatchFilter): string => {
  /** Ignore difference between lower case and upper case */
  const normalizedStr = str.toLowerCase();

  /** Ignore custom difference */
  if (transformSuggestionToMatchFilter) return transformSuggestionToMatchFilter(normalizedStr);

  return normalizedStr;
};

/* value: 'Paris is the capital of France'
   suggestion: 'paris'
   => true

   value: 'Paris is the capital of France'
   suggestion: 'paris france'
   => true
*/
export const filterByIncludes = (suggestion: string, value: string): boolean => suggestion.toLowerCase().includes(value.toLowerCase());


/* value: 'Paris is the capital of France'
   suggestion: 'paris'
   => true

   value: 'Paris is the capital of France'
   suggestion: 'paris france'
   => false
*/
export const filterByStartsWith = (suggestion: string, value: string): boolean => suggestion.toLowerCase().startsWith(value.toLowerCase());

export const escapeRegexp = (string: string): string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

/* returns true if all needles are present in hay
*  regardless of their order
*
*  e.g.
*  sentence: 'Saint Petersburg is the capital of Russia'
*  words: 'russia saint'
*  => true
*
*  sentence: 'Saint Petersburg is the capital of Russia'
*  words: 'russia moscow'
*  => false
*
*  sentence: 'Saint Petersburg is the capital of Russia'
*  words: 'peter'
*  => true
*
* sentence is a string containing words, one or more spaces and other symbols
* words is a string containing spaces and other symbols, a word is a set of symbols bounded by spaces or string boundaries
*/

export const getIsSentenceIncludingWords = (sentence: string, words: string): boolean => {
  if (words === '') return true;
  if (/^\s+$/.test(words)) return false;
  if (sentence.trim() === '') return false;

  const isMultiWord = /\s+/.test(words);

  if (isMultiWord) {
    const wordsArr = words.split(/\s+/);
    const regExpCore = wordsArr.map((el) => `(?=.*${escapeRegexp(el)})`).join('');
    const needle = new RegExp(`^${regExpCore}.*$`, 'gi');

    return needle.test(sentence);
  }

  return sentence.toLowerCase().includes(words.toLowerCase());
};

/**
 * Filters suggestions by a given Rule
 * @param {string} suggestion - Suggestion from list being compared
 * @param {string} value - Value in input
 * @param {'includes' | 'includes' | 'startsWith' | undefined} filterRule - Rule by which filtering occurs
 * @param {TransformSuggestionToMatchFilter | undefined} transformSuggestionToMatchFilter - Eliminates differences between suggestions from filtered list and value in input
 *
 * @returns {boolean} Suggestion passed filter?
 */
export const filterSuggestionByRule = (
  suggestion: string,
  value: string,
  filterRule?: keyof typeof FILTER_RULES,
  transformSuggestionToMatchFilter?: TransformSuggestionToMatchFilter,
): boolean => {
  const normalizedSuggestion = normalizeString(suggestion, transformSuggestionToMatchFilter);
  const normalizedValue = normalizeString(value, transformSuggestionToMatchFilter);

  switch (filterRule) {
    case FILTER_RULES.smart:
      /**
       * Smart search. Filters strings that contain all values in string from input.
       * Returns "true" if all values from input are present in string, despite position of values.
       */
      return getIsSentenceIncludingWords(normalizedSuggestion, normalizedValue);

    case FILTER_RULES.startsWith:
      /** Filters strings whose beginning matches with string from input */
      return filterByStartsWith(normalizedSuggestion, normalizedValue);

    case FILTER_RULES.includes:
      /** Filters strings that contain a string from an input, regardless of its position */
      return filterByIncludes(normalizedSuggestion, normalizedValue);
    default:
      return getIsSentenceIncludingWords(normalizedSuggestion, normalizedValue);
  }
};
