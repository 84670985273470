import { createAction, createSlice } from '@reduxjs/toolkit';

import { setStoreField } from '@common/store-utils';

import { config } from '../config';
import { ModelState } from '../types';

const initialState: ModelState = {
  id: '',
  otpCode: '',
  password: '',
  email: '',
  phone: '',
  shouldSaveUser: false,
  error: false,
  confirmSession: '',
  newPassword: '',
  isResetPasswordSuccess: false,
  notExistUserValidation: '',
  errorMessage: '',
};

/**
 * Action to login by email and password
 */
const loginByPassword = createAction('loginByPassword');

/**
 * Action to login by phone and OTP
 * */
const loginByPhone = createAction('loginByPhone');

/**
 * Action to resend OTP code
 * */
const resendCode = createAction('resendOtpCode');

/**
 * Action to confirm login by phone with OTP
 * */
const verifyCodeAfterLogin = createAction('verifyCodeAfterLogin');

/**
 * Action to confirm login by phone with OTP
 * */
const restorePassword = createAction('restorePassword');

export const createLoginSlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    setOtpCode: setStoreField('otpCode'),
    setId: setStoreField('id'),
    setPassword: setStoreField('password'),
    setEmail: setStoreField('email'),
    setShouldSaveUser: setStoreField('shouldSaveUser'),
    setPhone: setStoreField('phone'),
    setError: setStoreField('error'),
    setErrorMessage: setStoreField('errorMessage'),
    setNotExistUserValidation: setStoreField('notExistUserValidation'),
    setConfirmSession: setStoreField('confirmSession'),
    setNewPassword: setStoreField('newPassword'),
    setIsResetPasswordSuccess: setStoreField('isResetPasswordSuccess'),
    reset: () => initialState,
  },
});

export const { reducer } = createLoginSlice;

export const actions = {
  ...createLoginSlice.actions,
  loginByPhone,
  loginByPassword,
  resendCode,
  verifyCodeAfterLogin,
  restorePassword,
};
