import {
  createHookSelector,
  createModelFieldSelector,
  createModelSelector,
} from '@common/store-utils';
import { ModelState } from '../types';
import { config } from '../config';
import { createSelector } from '@reduxjs/toolkit';

const modelSelector = createModelSelector<ModelState>(config.modelName);
const fieldSelector = createModelFieldSelector<ModelState>(config.modelName);

const userRequestId = fieldSelector('request_id');
const appealReasonSelector = fieldSelector('documents');
const appealFromErrorSelector = fieldSelector('details');
const isAppealSentSuccess = fieldSelector('isApeealSentSuccess');
const isAppealSent = fieldSelector('isAppealSent');
const userDocumentsListSelector = fieldSelector('userDocumentsList');
const appealTypeSelector = fieldSelector('appealType');
const appealDocumentsBackToFixSelector = fieldSelector(
  'appealDocumentsBackToFix'
);
const isChangeBankDetails = fieldSelector('isChangeBankDetails');
const requestData = fieldSelector('requestData');
const requestScore = fieldSelector('detailedScore');
const invalidDocumentsSelector = fieldSelector('invalidDocuments');
const returnCommentSelector = fieldSelector('appealReturnComment');
const fixBankFileSelector = fieldSelector('fixBankFile');

export const selectors = {
  appeal: modelSelector,
  appealReason: appealReasonSelector,
  appealFromError: appealFromErrorSelector,
  appealType: appealTypeSelector,
  appealDocumentsBackToFix: appealDocumentsBackToFixSelector,
  fixBankFile: fixBankFileSelector,

  useAppealType: createHookSelector(appealTypeSelector),
  useIsAppealSent: createHookSelector(isAppealSent),
  usePersonalAreaState: createHookSelector(modelSelector),
  useUserRequestId: createHookSelector(userRequestId),
  useAppealReasonData: createHookSelector(modelSelector),
  useAppealFromError: createHookSelector(appealFromErrorSelector),
  useIsAppealSentSuccess: createHookSelector(isAppealSentSuccess),
  useUserDocumentsList: createHookSelector(userDocumentsListSelector),
  useAppealDocumentsBackToFix: createHookSelector(
    appealDocumentsBackToFixSelector
  ),
  useIsChangeBankDetails: createHookSelector(isChangeBankDetails),
  useRequestData: createHookSelector(requestData),
  useRequestScore: createHookSelector(requestScore),
  useInvalidDocuments: createHookSelector(invalidDocumentsSelector),
  useReturnComment: createHookSelector(returnCommentSelector),
};
