import { request } from '../../../common/request';

const OTP_PATH = `${process.env.REACT_APP_API_URL_AUTHZ}/authz-service/api`;

export const forgotPassword = (phone: string): Promise<any> => {
  return request.post<any>({
    url: `${OTP_PATH}/auth/forgot-password`,
    data: {
      phone,
    },
  });
};
export const resetPassword = async (userData: {
  code: string;
  password: string;
  password_verification?: string;
  phone: string;
}): Promise<any> => {
  return await request
    .post<any>({
      url: `${OTP_PATH}/auth/reset-password`,
      data: { ...userData },
    })
    .then((res) => res)
    .catch((err) => err);
};
