import { actions, reducer } from './ducks';
import { config } from './config';
import { selectors } from './selectors';

export const loaderModel = {
  actions,
  reducer,
  config,
  selectors,
};
