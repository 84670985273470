import { validateEmail as email } from './email';
import { validateUrl as url } from './url';
import { validatePassword as password } from './password';
import { validateIsraelId as israelId } from './israelId';

export const validate = {
  email,
  password,
  url,
  israelId,
};
