import {DicRecord} from "@entities/dictionaries";

export enum MaritalStatusType {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWER',
  OTHER = 'OTHER',
}

export enum OneOrBothParentsType {
  NONE = '',
  ONE = 'ONE_PARENT',
  BOTH = 'BOTH_PARENTS',
}

export enum FamilyMembersType {
  PARENTONE = 'PARENT_ONE',
  PARENTTWO = 'PARENT_TWO',
  SIBLING = 'BROTHER_SISTER',
  SPOUSE = 'SPOUSE',
  CANDIDATE = 'CANDIDATE',
  CHILD = 'CHILD',
  NONE = '',
}

export type DisabledFamilyMember = {
  id: string;
  type: DicRecord;
  percent: string;
};

export type AdditionalInfo = {
  maritalStatus: DicRecord;
  isMaritalStatusGap: boolean;
  maritalStatusGap: string;
  childrenCount: string;
  isChildrenCountGap: boolean;
  childrenCountGap: string;
  siblingsCount: string;
  siblingsStudentsCount: string;
  isNewcomer: boolean;
  repatriationDate: string | Date | null;
  isOrphan: boolean;
  orphanOfParentsCount: DicRecord;
  isCutOfFromParents: boolean;
  cutOfFromParentsCount: DicRecord;
  isSingleParent: boolean;
  isDisabledFamilyMembers: boolean;
  disabledFamilyMembers: DisabledFamilyMember[];
  //tuitionCost: string;
  //isFundedByEmployer: boolean;
  //amountOfFinancing: string;
};


export type DisabledPersonRequest = {
  family_member: string;
  disability_percentage: number | string;
};

export type AdditionalInfoRequest = {
  family_status: string;
  family_status_gap: boolean;
  family_status_gap_details?: string;
  identity_children: number | string;
  identity_children_gap: boolean;
  identity_children_gap_details?: string;
  brothers_under_24: number | string;
  brothers_students_under_30: number | string;
  new_immigrant: boolean;
  immigrant_date: string | Date;
  is_orphan: boolean;
  orphan_from?: string;
  is_disconnected_from_parents: boolean;
  disconnected_from?: string;
  is_single_parent: boolean;
  is_family_member_disabled: boolean;
  family_members: DisabledPersonRequest[];
  // tuition_wage: number | string;
  // tuition_finances_by_work: boolean;
  // financing_amount?: number | string;
};

export type AdditionalInfoResponse = {
  type: string;
  form_data: string;
  process_id: number;
  user_id: number;
  id: number;
  error?: any;
};
