import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShekel } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { InputTypes } from '../../CUI';

export const ShekelRender: InputTypes.InputProps['inputRender'] = ({
  Element,
  elementProps,
}) => (
  <>
    <Element {...elementProps} />
    <FontAwesomeIcon icon={faShekel} className="ml-16 fs-20" />
  </>
);
