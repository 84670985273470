import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as CUI from '@CUI';
import { useActions } from '@common/store-utils/hooks';
import { actions, selectors } from '@features/Login/model';
import { loginModel } from '@features/Login';
import isPasswordValid from '@/utility/validators/pass-validator';

import Button from '../components/shared/Button/Button';
import ButtonEnum from '../components/shared/Button/ButtonEnum';
import AuthCode from './OtpNew';

type OtpInputFieldProps = {
  digits?: number;
  onConfirm: () => void;
  onSendAgain?: () => void;
  onSetValue: (value: string) => void;
  isForgotPassword?: boolean;
};

export const OtpInputField: React.FC<OtpInputFieldProps> = ({
  digits = 6,
  onSetValue,
  onConfirm,
  onSendAgain,
  isForgotPassword,
}) => {
  const { t } = useTranslation();
  const [isVerifyClicked, setIsVerifyClicked] = useState(false);

  const [codeValue, setCodeValue] = useState('');

  const { setNewPassword, setPassword } = useActions(actions);
  const newPassword = selectors.useNewPassword();

  const onClickVerify = () => {
    setIsVerifyClicked(true);
    if (codeValue.length === digits) {
      onSetValue(codeValue);
      onConfirm();
    }
  };

  useEffect(() => {
    if (codeValue.length === 6 && !isForgotPassword) {
      onSetValue(codeValue);
      onConfirm();
      setCodeValue('');
    }
  },[codeValue])

  return (
    <>
      <div className="otp-wrapper">
        <label className="label-perach">{t('WHAT_CODE_YOU_RECEIVED')}</label>

        <AuthCode
          onChange={(value) => {
            setCodeValue(value);
          }}
          length={6}
          allowedCharacters={'numeric'}
          autoFocus={true}
          containerClassName={
            'code-verification d-flex align-items-center justify-content-between rr'
          }
          inputClassName={
            'aw otp-input-field text-center numeral-mask mx-25 mb-1'
          }
        />

        {/*<div className="code-verification d-flex align-items-center justify-content-between rr">*/}
        {/*  {values.map((value, index) => (*/}
        {/*    <input*/}
        {/*      required={true}*/}
        {/*      key={index}*/}
        {/*      id={'index'}*/}
        {/*      inputMode="numeric"*/}
        {/*      pattern="[0-9]*"*/}
        {/*      autoComplete="one-time-code"*/}
        {/*      type="text"*/}
        {/*      placeholder=" "*/}
        {/*      value={value}*/}
        {/*      ref={(el) => (myRefs.current[index] = el)}*/}
        {/*      onClick={() => onClickInput(index)}*/}
        {/*      //onChange={() => false}*/}
        {/*      onKeyUp={(e) => onKeyUp(e, index)}*/}
        {/*      onPaste={onPaste}*/}
        {/*      className="aw otp-input-field text-center numeral-mask mx-25 mb-1"*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</div>*/}
        {isForgotPassword && (
          <div className={'reset-password-inputs-container'}>
            <div className={'id-input-container'}>
              <span className={'id-input-container-title'}>סיסמה</span>
              <CUI.Password
                form={loginModel.config.formName}
                showEvaluationMessage={false}
                autoComplete={'off'}
                maxLength={20}
                validator={(value) => isPasswordValid(value)}
                requiredMessage={t('FIELD_REQUIRED')}
                invalidMessage={t('ENTER_CORRECT_PASSWORD')}
                onChange={(e) => {
                  setIsVerifyClicked(false);
                  setPassword(e.component.value);
                }}
                name={'password'}
                isRequired
              />
            </div>
            <div className={'id-input-container'}>
              <span className={'id-input-container-title'}>אימות סיסמה</span>
              <CUI.Password
                form={loginModel.config.formName}
                onPaste={(e: any) => {
                  e.preventDefault();
                  return false;
                }}
                requiredMessage={t('FIELD_REQUIRED')}
                invalidMessage={t('ENTER_SAME_PASSWORD')}
                autoComplete={'off'}
                maxLength={20}
                showEvaluationMessage={false}
                validator={(value) =>
                  value === newPassword && isPasswordValid(value)
                }
                onChange={(e) => {
                  setNewPassword(e.component.value);
                  setIsVerifyClicked(false);
                }}
                name={'confirm-password'}
                isRequired
              />
            </div>
          </div>
        )}
        <div
          onClick={() => (onSendAgain ? onSendAgain() : null)}
          className="single-link pointer"
        >
          {t('SEND_CODE_AGAIN')}
        </div>
        <Button
          type={ButtonEnum.PRIMARY}
          onClick={onClickVerify}
          className="d-block w-100 sign-in-button"
          text={t('NEXT')}
        />
      </div>
    </>
  );
};
