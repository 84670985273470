// ** I18n Imports
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n

  // Enables the i18next backend
  .use(Backend)

  // Enable automatic language detection
  .use(LanguageDetector)

  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    lng: 'he',
    backend: {
      /* translation file path */
      loadPath: `/assets/data/locales/{{lng}}.json?v=${process.env.REACT_APP_VERSION}`,
    },
    fallbackLng: 'he',
    debug: false,
    keySeparator: '.',
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export default i18n;
