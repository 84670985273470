export const appFields = {
  //step one
  first_name: 'שם פרטי',
  last_name: 'שם משפחה',
  identity_number: 'תעודת זהות',
  birthdate: 'תאריך לידה',
  birthdate_hebrew: 'תאריך לידה עברית',
  gender: 'מגדר',
  sector_id: 'מגזר',
  phone: 'טלפון',
  additional_phone: 'טלפון נוסף',
  email: 'כתובת דואר אלקטרוני',
  city_id: 'יישוב',
  street: 'רחוב',
  house_number: 'מספר בית',
  apartment_number: 'מספר דירה',
  study_city_id: 'יישוב',
  study_street: 'רחוב',
  study_house_number: 'מספר בית',
  study_apartment_number: 'מספר דירה',
  educational_city_id: 'עיר בה נמצא בית הספר האחרון בו למדת',
  is_ba: 'האם לומד תואר ראשון?',
  study_year_number: 'שנת לימודים',
  learning_disability_certificate: 'בעל אישור לקוי למידה',
  field_of_study_id: 'תחום לימודים',
  another_field_of_study: 'הזן את תחום הלימוד',
  additional_field_of_study_id: 'תחום לימודים נוסף',
  additional_another_field_of_study: 'הזן את תחום הלימוד',
  military_service_type: 'סוג שירות',
  value_exemption: 'פטור ערכי',
  service_month: 'משך שירות בחודשים',
  days_in_reserve: 'מספר ימי המילואים',
  is_reservist: 'שירות מילואים',
  //step two
  bank_name: 'בנק',
  branch_number: 'מספר סניף',
  account_number: 'מספר חשבון',

  //step three
  family_status: 'מצב משפחתי',
  family_status_gap: 'פער בין נתונים רשמיים לנתונים עבור “המצב המשפחתי”',
  family_status_gap_details: 'פרט את ההבדל',
  identity_children: 'מספר ילדים רשומים בת.ז',
  identity_children_gap: 'פער בין נתונים רשמיים לנתונים עבור “מספר ילדים”',
  identity_children_gap_details: 'פרט את ההבדל',
  brothers_under_24: 'מספר אחים עד גיל 24',
  brothers_students_under_30: 'מספר אחים סטודנטים עד גיל 30',
  new_immigrant: 'עולה חדש?',
  immigrant_date: 'תאריך עליה',
  is_orphan: 'האם את.ה יתום.ה מאחד ההורים או שניהם?',
  orphan_from: 'בחר הורה',
  is_disconnected_from_parents: 'האם את.ה מנותק.ת קשר מההורים?',
  disconnected_from: 'בחר הורה',
  is_single_parent: 'הורה יחיד עם ילד/ים?',
  is_family_member_disabled: 'האם את.ה או מישהו מבני המשפחה נכה?',
  //TODO family_members: DisabledPersonRequest[],

  tuition_wage: 'שכר לימודים',
  tuition_finances_by_work: 'האם שכר הלימוד ממומן ע"י המעסיק?',
  financing_amount: 'גובה המימון',

  //step four
  is_identity_biometric: 'האם תעודת הזהות ביומטרית?',
  front_identity: 'צילום קדמי',
  back_identity: 'צילום אחורי',
  attachment_identity: 'ספח פתוח ברור וקריא',
  parent_one_is_identity_biometric: 'האם תעודת הזהות ביומטרית?',
  parent_one_front_identity: 'צילום קדמי',
  parent_one_back_identity: 'צילום אחורי',
  parent_one_attachment_identity: 'ספח פתוח ברור וקריא',
  parent_one_death_certificate: 'תעודת פטירה/הצהרה בפני עורך דין',
  parent_one_disconnect_permission: 'תעודת פטירה/הצהרה בפני עורך דין',
  parent_two_is_identity_biometric: 'האם תעודת הזהות ביומטרית?',
  parent_two_front_identity: 'צילום קדמי',
  parent_two_back_identity: 'צילום אחורי',
  parent_two_attachment_identity: 'ספח פתוח ברור וקריא',
  parent_two_death_certificate: 'תעודת פטירה/הצהרה בפני עורך דין',
  parent_two_disconnect_permission: 'תעודת פטירה/הצהרה בפני עורך דין',
  confirmation_studies: 'אישור לימודים',
  schedule: 'מערכת שעות',
  //TODO DUBLE learning_disability_certificate: 'בעל אישור לקוי למידה',
  approval_annual_tuition_fees: 'אישור גובה שכר לימוד שנתי',
  //TODO approval_study_brothers_under_30:
  bank_account_reference: 'אסמכתא לחשבון בנק',
  //TODO family_documents:
  is_another_income: 'הכנסה נוספת',
  other_income_details: 'סוג הכנסה נוספת',
  other_income_file: 'טופס אישור הכנסה נוספת',
  approve_incomes:
    'אני מאשר/ת כי כל ההכנסות הקיימות דווחו ולא קיימות הכנסות נוספות',
  army_type_document: 'תעדות שחרור',
  reservist_document: 'אישור על שירות המילואים',
  newcomer_document: 'אישור עולה חדש ממשרד העלייה והקליטה',
  single_parent_approval: 'תצהיר בפני עורך דין על היותך הורה יחיד',
  //TODO disability_documents:
  is_additional_document: 'קבצים נוספים',
  additional_document: 'קבצים נוספים',
  declaration_correctness_information: 'פסקת דוגמה להצהרת נכונות המידע',
};

export const stepOneFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  userId: 'userId',
  birthday: 'birthday',
  birthdayHebrew: 'birthdayHebrew',
  gender: 'gender',
  sector: 'sector',
  phone: 'phone',
  phoneAdditional: 'phoneAdditional',
  email: 'email',
  emailConfirm: 'emailConfirm',
  addressById: 'addressById',
  street: 'street',
  home: 'home',
  apartment: 'apartment',
  matchAddresses: 'matchAddresses',
  addressWhileStudying: 'addressWhileStudying',
  streetWhileStudying: 'streetWhileStudying',
  homeWhileStudying: 'homeWhileStudying',
  apartmentWhileStudying: 'apartmentWhileStudying',
  academicCity: 'academicCity',
  isBachelor: 'isBachelor',
  year: 'year',
  learningDisabilityCertificate: 'learningDisabilityCertificate',
  field: 'field',
  otherField: 'otherField',
  additionalField: 'additionalField',
  additionalAnotherFieldOfStudy: 'additionalAnotherFieldOfStudy',
  militaryServiceType: 'militaryServiceType',
  valueExemption: 'valueExemption',
  serviceMoth: 'serviceMoth',
  buttonSubmit: 'buttonSubmit',
  buttonSave: 'buttonSave',
  isUnder18address: 'isUnder18address',
  under18City: 'under18City',
  under18District: 'under18District',
  under18Home: 'under18Home',
  under18Street: 'under18Street',
  under18Confirm: 'under18Confirm',
  isEducationalStudent: 'isEducationalStudent',
  isReservist: 'isReservist',
  daysInReserve: 'daysInReserve',
};

export const stepTwoFields = {
  bank: 'bank',
  branch: 'branch',
  accountNumber: 'accountNumber',
  buttonSubmit: 'buttonSubmit',
  buttonSave: 'buttonSave',
};

export const stepThreeFields = {
  maritalStatus: 'maritalStatus',
  isMaritalStatusGap: 'isMaritalStatusGap',
  maritalStatusGap: 'maritalStatusGap',
  childrenCount: 'childrenCount',
  isChildrenCountGap: 'isChildrenCountGap',
  childrenCountGap: 'childrenCountGap',
  siblingsCount: 'siblingsCount',
  siblingsStudentsCount: 'siblingsStudentsCount',
  isNewcomer: 'isNewcomer',
  repatriationDate: 'repatriationDate',
  isOrphan: 'isOrphan',
  orphanOfParentsCount: 'orphanOfParentsCount',
  isCutOfFromParents: 'isCutOfFromParents',
  cutOfFromParentsCount: 'cutOfFromParentsCount',
  isSingleParent: 'isSingleParent',
  isDisabledFamilyMembers: 'isDisabledFamilyMembers',
  familyMember: 'familyMember',
  disabilityPercent: 'disabilityPercent',
  tuitionCost: 'tuitionCost',
  isFundedByEmployer: 'isFundedByEmployer',
  amountOfFinancing: 'amountOfFinancing',
  buttonDeleteFamilyMember: 'buttonDeleteFamilyMember',
  buttonAddFamilyMember: 'buttonAddFamilyMember',
  buttonSave: 'buttonSave',
  buttonSubmit: 'buttonSubmit',
};

export const stepFourFields = {
  isIdentityBiometric: 'isIdentityBiometric',
  frontIdentity: 'frontIdentity',
  backIdentity: 'backIdentity',
  attachmentIdentity: 'attachmentIdentity',
  parentOneIsIdentityBiometric: 'parentOneIsIdentityBiometric',
  parentOneFrontIdentity: 'parentOneFrontIdentity',
  parentOneBackIdentity: 'parentOneBackIdentity',
  parentOneAttachmentIdentity: 'parentOneAttachmentIdentity',
  parentOneDeathCertificate: 'parentOneDeathCertificate',
  parentOneDisconnectPermission: 'parentOneDisconnectPermission',
  parentTwoDisconnectPermission: 'parentTwoDisconnectPermission',
  parentTwoIsIdentityBiometric: 'parentTwoIsIdentityBiometric',
  parentTwoFrontIdentity: 'parentTwoFrontIdentity',
  parentTwoBackIdentity: 'parentTwoBackIdentity',
  parentTwoAttachmentIdentity: 'parentTwoAttachmentIdentity',
  parentTwoDeathCertificate: 'parentTwoDeathCertificate',
  confirmationStudies: 'confirmationStudies',
  schedule: 'schedule',
  learningDisabilityCertificate: 'learningDisabilityCertificate',
  approvalAnnualTuitionFees: 'approvalAnnualTuitionFees',
  brother: 'brother',
  bankAccountReference: 'bankAccountReference',
  incomeStudent: 'incomeStudent',
  incomeSpouse: 'incomeSpouse',
  incomeParentOne: 'incomeParentOne',
  incomeParenTwo: 'incomeParenTwo',
  incomeDocument: 'incomeDocument',
  allowanceType: 'allowanceType',
  allowanceDocument: 'allowanceDocument',
  additionalIncome: 'additionalIncome',
  otherIncomeFile: 'otherIncomeFile',
  armyTypeDocument: 'armyTypeDocument',
  reservistDocument: 'reservistDocument',
  newcomerDocument: 'newcomerDocument',
  singleParentApproval: 'singleParentApproval',
  disabledFamily: 'disabledFamily',
  additionalDocument: 'additionalDocument',
  buttonSave: 'buttonSave',
  buttonSubmit: 'buttonSubmit',
};
