import {
  createHookSelector,
  createModelFieldSelector,
} from '@common/store-utils';

import { config } from './config';

/**
 * Selector of model State
 */
const fieldSelector = createModelFieldSelector<{ isLoading: boolean }>(
  config.modelName
);

/**
 * Loader state selector
 */
const isLoadingSelector = fieldSelector('isLoading');

export const selectors = {
  isLoading: isLoadingSelector,
  useIsLoading: createHookSelector(isLoadingSelector),
};
