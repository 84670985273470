import * as React from 'react';
import { Div } from '../../components/Div';
import { Context } from '../../components/Provider';
import { formatDate } from '../DateTimeInput/helpers';
import { CALENDAR_CLICK_ACTION } from './constants';
import {
  getDateCellClassNames, getDateCellConditions,
} from './helpers';
import { getClassNames, useElement } from '../../utils';
import { DateCellProps } from './types';

export const DateCell = (props: DateCellProps): React.ReactElement => {
  const {
    date,
    dateCellRender,
    index,
    onClick,
    theme,
    viewDate,
  } = props;

  const { renders: { dateTimeInput: dateTimeInputRenders } } = React.useContext(Context);

  const DateCellItem = useElement(
    'DateCellItem',
    Div,
    dateCellRender || dateTimeInputRenders.dateCellRender,
    props,
  );

  const {
    renderedDate,
    isDateDisabled,
    isDateOfPrevMonth,
    isDateOfNextMonth,
    isDateOutOfMinMonthRange,
    isDateOutOfMaxMonthRange,
    isDateMarked,
  } = getDateCellConditions(props);

  const dateCellClassNames = getDateCellClassNames(props, renderedDate, isDateMarked);
  const dateCellDisabledClassNames = getClassNames(
    theme.dateCell,
    theme.dateCellDisabled,
    { [theme.dateCellMarked]: isDateMarked },
  );
  const dateCellNotSelectedMonthClassNames = getClassNames(
    theme.dateCell,
    theme.dateCellDifferentMonth,
    { [theme.dateCellMarked]: isDateMarked },
  );

  const monthCell = isDateOfPrevMonth ? viewDate.getMonth() - 1 : viewDate.getMonth() + 1;

  /**
   * Function sets state of value selected date and state of value selected month, if click was made on date of unselected month
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} ev - Click event
   *
   * @returns {void}
   */
  const handleClickNotSelectedMonth = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => onClick(CALENDAR_CLICK_ACTION.DATES_SELECT, ev, { dateCell: date, monthCell });

  /**
   * Function sets state of value selected date, if click was made on date of selected month
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} ev - Click event
   *
   * @returns {void}
   */
  const handleClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => onClick(CALENDAR_CLICK_ACTION.DATES_SELECT, ev, { dateCell: date });

  if (isDateDisabled || isDateOutOfMinMonthRange || isDateOutOfMaxMonthRange) {
    return (
      <DateCellItem
        key={`${index.toString()}-${date}`}
        className={dateCellDisabledClassNames}
      >
        {date}
      </DateCellItem>
    );
  }

  if (isDateOfPrevMonth || isDateOfNextMonth) {
    return (
      <DateCellItem
        key={`${index.toString()}-${date}`}
        className={dateCellNotSelectedMonthClassNames}
        onClick={handleClickNotSelectedMonth}
        title={formatDate(renderedDate)}
      >
        {date}
      </DateCellItem>
    );
  }

  return (
    <DateCellItem
      key={`${index.toString()}-${date}`}
      className={dateCellClassNames}
      onClick={handleClick}
      title={formatDate(renderedDate)}
    >
      {date}
    </DateCellItem>
  );
};
