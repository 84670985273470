import { ModelState } from './types';

export const modelStateDefaultValue: ModelState = {
  documents: '',
  details: '',
  request_id: undefined,
  appealSent: false,
  appealSentSuccessfully: false,
  isAppealSent: false,
  isApeealSentSuccess: false,
  userDocumentsList: {},
  appealType: '',
  appealDocumentsBackToFix: [],
  isChangeBankDetails: false,
  requestData: {
    adminAssignment: false,
    candidateEntering: '',
    childrenDisability: '',
    disability: [],
    entry: '',
    exceedsStandards: { value: false, label: '' },
    note: '',
    houseHoldPeople: '',
    openUniversity: '',
    requestNumber: 0,
    parenOneIncome: '',
    parenTwoIncome: '',
    reservedField: '',
    spouse: '',
    studiesScope: '',
    surveyMonth: '',
  },
  detailedScore: {
    income: 0,
    cluster: 0,
    family: 0,
    military: 0,
    priority: 0,
    reservist: 0,
    total: 0,
  },
  invalidDocuments: [],
  appealReturnComment: '',
  originalAppealDocuments: [],
  fixBankFile: '',
};
