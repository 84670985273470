import accept from 'attr-accept';
import {ERROR_MESSAGES, FileErrorCodes, MAX_FILE_SIZE, MIN_FILE_SIZE} from '../../constants';
import { FileUploadProps } from './types';

export const getErrorCode = (file: File, props: FileUploadProps): number => {
  const {
    allowedFiles,
    forbiddenFiles,
    minFileSize = MIN_FILE_SIZE,
    maxFileSize = MAX_FILE_SIZE,
    maxFileNameLength = 255,
  } = props;

  if (allowedFiles) {
    const isAccepted = accept({
      name: file.name,
      type: file.type,
    }, allowedFiles);

    if (!isAccepted) return FileErrorCodes.WrongFileFormat;
  }
  if (forbiddenFiles) {
    const isAcceptedForbiddenFiles = accept({
      name: file.name,
      type: file.type,
    }, forbiddenFiles);

    if (isAcceptedForbiddenFiles) return FileErrorCodes.WrongFileFormat;
  }

  if (file.size < minFileSize) return FileErrorCodes.FileIsTooSmall;
  if (file.size > maxFileSize) return FileErrorCodes.FileIsTooBig;

  if (file.name.length > maxFileNameLength) return FileErrorCodes.NameIsTooLong;

  return FileErrorCodes.None;
};

export const errorCodeToMessage = (errorCode: number): string => {
  const err = ERROR_MESSAGES.find((errorMessage): boolean => errorCode === errorMessage.errorCode);
  if (err === undefined) throw new Error('ErrorCodeToMessage error: wrong error code');
  return err.message;
};
