import { actions, reducer, selectors, sagas } from './model';
import { config } from './config';

export const personalAreaModel = {
  selectors,
  actions,
  reducer,
  config,
  sagas,
};
