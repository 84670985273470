import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Collapse, Row } from 'reactstrap';

import * as CUI from '@CUI';
import { useActions } from '@common/store-utils/hooks';
import {
  CheckBox,
  CollapseHeader,
  LabelWithHelp,
  LabelWithPopup,
  OpionalTooltip,
  OptionsInput,
} from '@common/perach-ui';

import {
  DicRecord,
  Dictionaries,
  dictionariesModel,
  fixValue,
} from '@entities/dictionaries';
import { RegistrationInputField } from './RegistrationInputField';
import { actions, selectors } from '../model';
import {
  Address,
  EducationDetails,
  MilitaryType,
  PersonalInfo,
} from '../types';
import { personalInfoDefaultValues } from '../defaults';
import { config } from '../config';
import { stepOneFields } from '../fields';
import { Optional } from '@common/ui';
import {
  getReservistDatesRange,
  subtractYears,
} from '@features/Registration/utils';
import { HelpKeyType, HelpText } from '@entities/settings';

const InputField: React.FC<{ label: string } & CUI.InputTypes.InputProps> = ({
  label,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <RegistrationInputField
      form={config.forms.personalData}
      label={label}
      requiredMessage={t('FIELD_REQUIRED')}
      {...props}
    />
  );
};

/**
 * Users personal data for Registration
 * @returns React.FC
 * */
export const PersonalInfoForm: React.FC<{
  disabledFields?: Record<string, boolean>;
  shouldHideButtons?: boolean;
}> = ({
  disabledFields = { userId: true, phone: true, isBachelor: true },
  shouldHideButtons = false,
}) => {
  const { t } = useTranslation();
  const { updatePersonalInfo, saveUserPersonalData, setIsModalOpen } =
    useActions(actions);
  const { getDistricts } = useActions(dictionariesModel.actions);
  const personalInfo =
    selectors.usePersonalInfoData() || personalInfoDefaultValues;
  const cities = dictionariesModel.selectors.useCities();
  const studyFields = dictionariesModel.selectors.useStudyFields();
  const districts = dictionariesModel.selectors.useDistricts();
  const loaders = dictionariesModel.selectors.useLoaders();

  const GENDER_OPTIONS = [
    { value: 'MALE', label: t('MALE') },
    { value: 'FEMALE', label: t('FEMALE') },
    { value: 'ANOTHER', label: t('OTHER') },
  ];

  const BOOL_OPTIONS = [
    { value: true, label: t('YES') },
    { value: false, label: t('NO') },
  ];

  const initialCollapses = {
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
  };

  const [collapses, setCollapses] =
    useState<Record<string, boolean>>(initialCollapses);

  const toggleCollapse = (id: string) =>
    setCollapses({ ...collapses, [id]: !collapses[id] });

  const onFieldChange = (e: CUI.InputTypes.ChangeEvent) => {
    const { name, value } = e.component;
    updatePersonalInfo({
      changes: { [name as Partial<keyof PersonalInfo>]: value },
    });
  };

  const handleChange = (field: keyof PersonalInfo, value: string | boolean) =>
    updatePersonalInfo({ changes: { [field]: value } });

  const handleChangeAddress = (
    type: keyof Pick<PersonalInfo, 'addressById' | 'addressWhileStudying'>,
    field: keyof Address,
    value: string | boolean | { value?: any; text?: string }
  ) =>
    updatePersonalInfo({
      changes: {
        [type]: { ...personalInfo[type], [field]: value },
        addressesMatch:
          type === 'addressWhileStudying'
            ? false
            : personalInfo?.addressesMatch,
      },
    });

  const handleChangeEducation = (
    field: keyof EducationDetails,
    value: string | boolean | DicRecord
  ) =>
    updatePersonalInfo({
      changes: {
        educationDetails: {
          ...personalInfo['educationDetails'],
          [field]: value,
        },
      },
    });

  useEffect(() => {
    if (personalInfo.addressesMatch) {
      updatePersonalInfo({
        changes: { addressWhileStudying: personalInfo.addressById },
      });
    }
  }, [
    personalInfo.addressesMatch,
    personalInfo.addressWhileStudying,
    personalInfo.addressById,
  ]);

  const sectorMock = [
    { value: '1', text: 'מגזר חרדי' },
    { value: '2', text: 'חברה ערבית' },
    { value: '3', text: 'יוצאי אתיופיה' },
  ];

  const schoolYearMock = [
    { value: 'FIRST_YEAR', text: 'שנה ראשונה' },
    { value: 'SECOND_YEAR', text: 'שנה שנייה' },
    { value: 'THIRD_YEAR', text: 'שנה שלישית' },
    { value: 'FOUR_YEAR', text: 'שנה רביעית' },
    { value: 'FIVE_PLUS_YEAR', text: 'שנה חמישית +' },
  ];

  const militaryServiceMock = [
    { value: MilitaryType.MILITARY_SERVICE, text: 'שירות צבאי' },
    { value: MilitaryType.NATIONAL_SERVICE, text: 'שירות לאומי' },
    { value: MilitaryType.CIVIL_SERVICE, text: 'שירות אזרחי' },
    { value: MilitaryType.NOT_SERVED, text: 'לא עשיתי שירות צבאי/לאומי/אזרחי' },
  ];

  useEffect(() => {
    if (!!personalInfo?.under18City?.value) {
      getDistricts(personalInfo?.under18City?.value);
    }
  }, [personalInfo?.under18City]);

  const isButtonDisabled = false; // !personalInfo?.under18Confirm;

  // @ts-ignore
  return (
    <>
      <div className="form-container ph-25">
        <CollapseHeader
          id="1"
          isOpen={collapses['1']}
          title={t('PERSONAL_INFO')}
          toggle={() => toggleCollapse('1')}
        />
        <Collapse isOpen={collapses['1']}>
          <Row className="g-2 gx-5">
            <Col className="col-12 col-md-6">
              <InputField
                label={t('FIRST_NAME')}
                name={stepOneFields.firstName}
                isDisabled={!!disabledFields?.[stepOneFields.firstName]}
                value={personalInfo.firstName}
                onChange={onFieldChange}
                isRequired
                maxLength={20}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <InputField
                label={t('LAST_NAME')}
                name={stepOneFields.lastName}
                isDisabled={!!disabledFields?.[stepOneFields.lastName]}
                value={personalInfo.lastName}
                onChange={onFieldChange}
                forbiddenSymbols={'numbers'}
                isRequired
                maxLength={20}
              />
            </Col>
          </Row>
          <Row className="g-2 gx-5">
            <Col className="col-12 col-md-6">
              <InputField
                label={t('IDENTITY_NUMBER')}
                name={stepOneFields.userId}
                isDisabled={!!disabledFields?.[stepOneFields.userId]}
                value={personalInfo.userId}
                onChange={onFieldChange}
                validator={'israelId'}
                maxLength={9}
                minLength={7}
                invalidMessage={t('ENTER_CORRECT_ID')}
                isRequired
              />
            </Col>
            <Col className="col-12 col-md-6">
              <LabelWithPopup
                label={t('BIRTHDAY')}
                helpKey={stepOneFields.birthday}
              />
              <CUI.DatePicker
                _wideList
                form={config.forms.personalData}
                name={stepOneFields.birthday}
                isDisabled={!!disabledFields?.[stepOneFields.birthday]}
                placeholder={' '}
                value={personalInfo?.birthday || null}
                format={''}
                max={subtractYears(14)}
                min={new Date('01-01-1901')}
                onChange={(e) => {
                  updatePersonalInfo({
                    changes: {
                      birthday: e.component.value || null,
                    },
                  });
                }}
                isRequired
                requiredMessage={t('FIELD_REQUIRED')}
              />
            </Col>
          </Row>
          <Row className="g-2 gx-5">
            <Col className="col-12 mb-40">
              <InputField
                label={t('BIRTHDAY_HEBREW')}
                name={stepOneFields.birthdayHebrew}
                isDisabled={!!disabledFields?.[stepOneFields.birthdayHebrew]}
                value={personalInfo.birthdayHebrew}
                onChange={onFieldChange}
              />
            </Col>
          </Row>
          <Row className="g-2 gx-5">
            <Col className="col-12 col-md-6 mb-40">
              <LabelWithPopup
                label={t('GENDER')}
                helpKey={stepOneFields.gender}
              />
              <OptionsInput
                isDisabled={!!disabledFields?.[stepOneFields.gender]}
                options={GENDER_OPTIONS}
                value={personalInfo?.gender || ''}
                onChange={(val) =>
                  updatePersonalInfo({ changes: { gender: val } })
                }
              />
            </Col>
            {/*TODO: Delete after fixes */}
            {/*<Col className="col-12 col-md-6 mb-40">*/}
            {/*  <CUI.Label _labelPerach>{t('SECTOR')}</CUI.Label>*/}
            {/*  <CUI.DropDownSelect*/}
            {/*    form={config.forms.personalData}*/}
            {/*    name={stepOneFields.sector}*/}
            {/*    isDisabled={!!disabledFields?.[stepOneFields.sector]}*/}
            {/*    value={fixValue(personalInfo?.sector, sectors)}*/}
            {/*    data={sectors}*/}
            {/*    textField={'text'}*/}
            {/*    shouldAllowEmpty*/}
            {/*    shouldFilterValues*/}
            {/*    onChange={(e) =>*/}
            {/*      updatePersonalInfo({*/}
            {/*        changes: { sector: e.component.value },*/}
            {/*      })*/}
            {/*    }*/}
            {/*    isRequired*/}
            {/*    requiredMessage={t('FIELD_REQUIRED')}*/}
            {/*  />*/}
            {/*</Col>*/}
          </Row>
        </Collapse>

        <CollapseHeader
          id="2"
          isOpen={collapses['2']}
          title={t('CONNECT_INFO')}
          toggle={() => toggleCollapse('2')}
        />
        <Collapse isOpen={collapses['2']}>
          <Row className="g-2 gx-5">
            <Col className="col-12 col-md-6">
              <InputField
                label={t('PHONE')}
                name={stepOneFields.phone}
                isDisabled={!!disabledFields?.[stepOneFields.phone]}
                value={personalInfo.phone}
                onChange={onFieldChange}
                allowedSymbols="numbers"
                isRequired
                maxLength={10}
                minLength={9}
                validator={(v) => v.length >= 9 && v.length <= 10}
                invalidMessage={t('ENTER_CORRECT_PHONE')}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <InputField
                label={t('ADDITIONAL_PHONE')}
                name={stepOneFields.phoneAdditional}
                isDisabled={!!disabledFields?.[stepOneFields.phoneAdditional]}
                value={personalInfo.phoneAdditional}
                allowedSymbols="numbers"
                onChange={onFieldChange}
                maxLength={10}
                minLength={9}
                validator={(v) => v.length >= 9 && v.length <= 10}
                invalidMessage={t('ENTER_CORRECT_PHONE')}
              />
            </Col>
          </Row>
          {/*<Row className="g-2 gx-5">*/}
          {/*  <Col className="col-12 col-md-6">*/}
          {/*    <InputField*/}
          {/*      label={t('EMAIL_ADDRESS')}*/}
          {/*      name={stepOneFields.email}*/}
          {/*      isDisabled={!!disabledFields?.[stepOneFields.email]}*/}
          {/*      invalidMessage={t('ERRORS.ENTER_CORRECT_EMAIL')}*/}
          {/*      value={personalInfo.email}*/}
          {/*      onChange={(e) => {*/}
          {/*        updatePersonalInfo({*/}
          {/*          changes: {*/}
          {/*            emailTouched: true,*/}
          {/*            email: e.component.value,*/}
          {/*          },*/}
          {/*          withNoChangesMark: true,*/}
          {/*        });*/}
          {/*      }}*/}
          {/*      validator={'email'}*/}
          {/*      isRequired*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*  <Col className="col-12 col-md-6">*/}
          {/*    <CUI.Label _labelPerach>{t('EMAIL_ADDRESS_CONFIRM')}</CUI.Label>*/}
          {/*    <CUI.Input*/}
          {/*      _mb40*/}
          {/*      form={config.forms.personalData}*/}
          {/*      requiredMessage={t('FIELD_REQUIRED')}*/}
          {/*      invalidMessage={t('ERRORS.ENTER_CORRECT_EMAIL')}*/}
          {/*      name={stepOneFields.emailConfirm}*/}
          {/*      isDisabled={!!disabledFields?.[stepOneFields.emailConfirm]}*/}
          {/*      value={personalInfo?.emailConfirm}*/}
          {/*      validator={(value) => value === personalInfo.email}*/}
          {/*      pasteDisabled={true}*/}
          {/*      onChange={onFieldChange}*/}
          {/*      isRequired={personalInfo?.emailTouched}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Collapse>

        {/*<CollapseHeader*/}
        {/*  id="3"*/}
        {/*  isOpen={collapses['3']}*/}
        {/*  title={t('ADDRESS_BY_ID')}*/}
        {/*  toggle={() => toggleCollapse('3')}*/}
        {/*/>*/}
        {/*<Collapse isOpen={collapses['3']}>*/}
        {/*  <Row className="g-2 gx-5">*/}
        {/*    <Col className="col-12 col-md-6 mb-40">*/}
        {/*      <CUI.Label _labelPerach>{t('CITY')}</CUI.Label>*/}
        {/*      <CUI.DropDownSelect*/}
        {/*        form={config.forms.personalData}*/}
        {/*        name={stepOneFields.addressById}*/}
        {/*        isDisabled={!!disabledFields?.[stepOneFields.addressById]}*/}
        {/*        value={fixValue(personalInfo?.addressById?.city, cities)}*/}
        {/*        data={cities}*/}
        {/*        textField={'text'}*/}
        {/*        shouldAllowEmpty={false}*/}
        {/*        shouldFilterValues*/}
        {/*        onChange={(e) =>*/}
        {/*          handleChangeAddress(*/}
        {/*            'addressById',*/}
        {/*            'city',*/}
        {/*            e?.component?.value*/}
        {/*          )*/}
        {/*        }*/}
        {/*        isRequired*/}
        {/*        requiredMessage={t('FIELD_REQUIRED')}*/}
        {/*      />*/}
        {/*    </Col>*/}
        {/*    <Col className="col-12 col-md-6">*/}
        {/*      <InputField*/}
        {/*        label={t('STREET')}*/}
        {/*        name={stepOneFields.street}*/}
        {/*        isDisabled={!!disabledFields?.[stepOneFields.street]}*/}
        {/*        value={personalInfo.addressById.street}*/}
        {/*        isRequired*/}
        {/*        maxLength={30}*/}
        {/*        onChange={(e) =>*/}
        {/*          handleChangeAddress(*/}
        {/*            'addressById',*/}
        {/*            'street',*/}
        {/*            e?.component?.value*/}
        {/*          )*/}
        {/*        }*/}
        {/*      />*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*  <Row className="g-2 gx-5">*/}
        {/*    <Col className="col-12 col-md-6">*/}
        {/*      <InputField*/}
        {/*        label={t('HOME_NUMBER')}*/}
        {/*        name={stepOneFields.home}*/}
        {/*        isDisabled={!!disabledFields?.[stepOneFields.home]}*/}
        {/*        value={personalInfo.addressById.home}*/}
        {/*        isRequired*/}
        {/*        maxLength={10}*/}
        {/*        onChange={(e) =>*/}
        {/*          handleChangeAddress(*/}
        {/*            'addressById',*/}
        {/*            'home',*/}
        {/*            e?.component?.value*/}
        {/*          )*/}
        {/*        }*/}
        {/*      />*/}
        {/*    </Col>*/}
        {/*    <Col className="col-12 col-md-6">*/}
        {/*      <InputField*/}
        {/*        label={t('APARTMENT_NUMBER')}*/}
        {/*        name={stepOneFields.apartment}*/}
        {/*        isDisabled={!!disabledFields?.[stepOneFields.apartment]}*/}
        {/*        value={personalInfo.addressById.apartment}*/}
        {/*        maxLength={10}*/}
        {/*        onChange={(e) =>*/}
        {/*          handleChangeAddress(*/}
        {/*            'addressById',*/}
        {/*            'apartment',*/}
        {/*            e?.component?.value*/}
        {/*          )*/}
        {/*        }*/}
        {/*      />*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*</Collapse>*/}

        {/*<CollapseHeader*/}
        {/*  id="4"*/}
        {/*  isOpen={collapses['4']}*/}
        {/*  title={t('ADDRESS_FOR_STUDYING')}*/}
        {/*  toggle={() => toggleCollapse('4')}*/}
        {/*/>*/}
        {/*<Collapse isOpen={collapses['4']}>*/}
        {/*  <Row className="mb-8">*/}
        {/*    <Col className="col-12 mb-40">*/}
        {/*      <CheckBox*/}
        {/*        value={personalInfo.addressesMatch}*/}
        {/*        name={stepOneFields.matchAddresses}*/}
        {/*        isDisabled={!!disabledFields?.[stepOneFields.matchAddresses]}*/}
        {/*        form={config.forms.personalData}*/}
        {/*        onChange={() =>*/}
        {/*          updatePersonalInfo({*/}
        {/*            changes: {addressesMatch: !personalInfo.addressesMatch},*/}
        {/*          })*/}
        {/*        }*/}
        {/*      >*/}
        {/*        {t('ADDRESSES_ARE_SAME')}*/}
        {/*      </CheckBox>*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*  <Row className="g-2 gx-5">*/}
        {/*    <Col className="col-12 col-md-6">*/}
        {/*      <CUI.Label _labelPerach>{t('CITY')}</CUI.Label>*/}
        {/*      <CUI.DropDownSelect*/}
        {/*        form={config.forms.personalData}*/}
        {/*        name={stepOneFields.addressWhileStudying}*/}
        {/*        isDisabled={*/}
        {/*          !!disabledFields?.[stepOneFields.addressWhileStudying]*/}
        {/*        }*/}
        {/*        value={fixValue(personalInfo?.addressWhileStudying?.city, cities) || null}*/}
        {/*        data={cities}*/}
        {/*        textField={'text'}*/}
        {/*        shouldAllowEmpty*/}
        {/*        shouldFilterValues*/}
        {/*        onChange={(e) =>*/}
        {/*          handleChangeAddress(*/}
        {/*            'addressWhileStudying',*/}
        {/*            'city',*/}
        {/*            e?.component?.value*/}
        {/*          )*/}
        {/*        }*/}
        {/*        isRequired*/}
        {/*        requiredMessage={t('FIELD_REQUIRED')}*/}

        {/*      />*/}
        {/*    </Col>*/}
        {/*    <Col className="col-12 col-md-6">*/}
        {/*      <InputField*/}
        {/*        label={t('STREET')}*/}
        {/*        name={stepOneFields.streetWhileStudying}*/}
        {/*        isDisabled={*/}
        {/*          !!disabledFields?.[stepOneFields.streetWhileStudying]*/}
        {/*        }*/}
        {/*        value={personalInfo.addressWhileStudying.street}*/}
        {/*        isRequired*/}
        {/*        maxLength={30}*/}
        {/*        onChange={(e) =>*/}
        {/*          handleChangeAddress(*/}
        {/*            'addressWhileStudying',*/}
        {/*            'street',*/}
        {/*            e?.component?.value*/}
        {/*          )*/}
        {/*        }*/}
        {/*      />*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*  <Row className="g-2 gx-5">*/}
        {/*    <Col className="col-12 col-md-6">*/}
        {/*      <InputField*/}
        {/*        label={t('HOME_NUMBER')}*/}
        {/*        name={stepOneFields.homeWhileStudying}*/}
        {/*        isDisabled={!!disabledFields?.[stepOneFields.homeWhileStudying]}*/}
        {/*        value={personalInfo.addressWhileStudying.home}*/}
        {/*        isRequired*/}
        {/*        maxLength={10}*/}
        {/*        onChange={(e) =>*/}
        {/*          handleChangeAddress(*/}
        {/*            'addressWhileStudying',*/}
        {/*            'home',*/}
        {/*            e?.component?.value*/}
        {/*          )*/}
        {/*        }*/}
        {/*      />*/}
        {/*    </Col>*/}
        {/*    <Col className="col-12 col-md-6">*/}
        {/*      <InputField*/}
        {/*        label={t('APARTMENT_NUMBER')}*/}
        {/*        name={stepOneFields.apartmentWhileStudying}*/}
        {/*        isDisabled={*/}
        {/*          !!disabledFields?.[stepOneFields.apartmentWhileStudying]*/}
        {/*        }*/}
        {/*        value={personalInfo.addressWhileStudying.apartment}*/}
        {/*        maxLength={10}*/}
        {/*        onChange={(e) =>*/}
        {/*          handleChangeAddress(*/}
        {/*            'addressWhileStudying',*/}
        {/*            'apartment',*/}
        {/*            e?.component?.value*/}
        {/*          )*/}
        {/*        }*/}
        {/*      />*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*</Collapse>*/}

        {/*NEW FIELDS*/}
        <CollapseHeader
          id="7"
          isOpen={collapses['7']}
          title={t('ADDRESS_BY_ID')}
          // title={t('STEP_ONE_FIELDS.AGE_18_ADDRESS_COLLAPSE')}
          toggle={() => toggleCollapse('7')}
        />
        <Collapse isOpen={collapses['7']}>
          {/*<Row className="mb-16">*/}
          {/*  <Col className="col-12">*/}
          {/*    <CheckBox*/}
          {/*      value={personalInfo.isUnder18address}*/}
          {/*      checked={!!personalInfo?.isUnder18address}*/}
          {/*      name={stepOneFields.isUnder18address}*/}
          {/*      isDisabled={!!disabledFields?.[stepOneFields.isUnder18address]}*/}
          {/*      form={config.forms.personalData}*/}
          {/*      onChange={() =>*/}
          {/*        updatePersonalInfo({*/}
          {/*          changes: {*/}
          {/*            isUnder18address: !personalInfo.isUnder18address,*/}
          {/*          },*/}
          {/*        })*/}
          {/*      }*/}
          {/*    >*/}
          {/*      {t('STEP_ONE_FIELDS.UNDER_18_OUTSIDE_ISRAEL_CHECKBOX')}*/}
          {/*    </CheckBox>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row className="g-2 gx-5 mb-40">
            <Col className="col-12 col-md-6">
              <LabelWithPopup
                label={t('CITY')}
                helpKey={stepOneFields.under18City}
              />
              <CUI.DropDownSelect
                form={config.forms.personalData}
                name={stepOneFields.under18City}
                isDisabled={
                  !!personalInfo?.isUnder18address ||
                  !!disabledFields?.[stepOneFields.under18City]
                }
                value={fixValue(personalInfo?.under18City, cities)}
                data={cities}
                textField={'text'}
                shouldAllowEmpty
                shouldFilterValues
                onChange={(e) =>
                  updatePersonalInfo({
                    changes: {
                      under18City: e.component.value,
                      under18District: { value: '', text: '' },
                    },
                  })
                }
                isRequired={!personalInfo?.isUnder18address}
              />
            </Col>
            <Col>
              <LabelWithPopup
                label={t('STEP_ONE_FIELDS.DISTRICT_DROPDOWN')}
                helpKey={stepOneFields.under18District}
              />
              <CUI.DropDownSelect
                form={config.forms.personalData}
                name={stepOneFields.under18District}
                isDisabled={
                  !!personalInfo?.isUnder18address ||
                  !!disabledFields?.[stepOneFields.under18District]
                }
                value={fixValue(personalInfo?.under18District, [
                  ...districts,
                  // { value: '9999', text: 'None' },
                ])}
                data={[
                  ...districts,
                  // { value: '9999', text: 'None' }
                ]}
                isLoading={loaders?.[Dictionaries.Districts] || false}
                textField={'text'}
                shouldAllowEmpty
                shouldFilterValues
                onChange={(e) =>
                  updatePersonalInfo({
                    changes: { under18District: e.component.value },
                  })
                }
                isRequired={!personalInfo?.isUnder18address}
              />
              <CUI.Span>אם לא מצאת, יש לבחור אחר</CUI.Span>
            </Col>
          </Row>
          <Row className="g-2 gx-5">
            <Col className="col-12 col-md-6">
              <InputField
                label={t('STREET')}
                name={stepOneFields.under18Street}
                isDisabled={
                  !!personalInfo?.isUnder18address ||
                  !!disabledFields?.[stepOneFields.under18Street]
                }
                value={personalInfo.under18Street}
                maxLength={30}
                onChange={(e) =>
                  updatePersonalInfo({
                    changes: { under18Street: e.component.value },
                  })
                }
                isRequired={!personalInfo?.isUnder18address}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <InputField
                label={t('HOME_NUMBER')}
                name={stepOneFields.under18Home}
                isDisabled={
                  !!personalInfo?.isUnder18address ||
                  !!disabledFields?.[stepOneFields.under18Home]
                }
                value={personalInfo.under18Home}
                isRequired={!personalInfo?.isUnder18address}
                maxLength={10}
                onChange={(e) =>
                  updatePersonalInfo({
                    changes: { under18Home: e.component.value },
                  })
                }
              />
            </Col>
          </Row>
          {/*<Row className="mb-8">*/}
          {/*  <Col className="col-12">*/}
          {/*    <CheckBox*/}
          {/*      value={personalInfo.under18Confirm}*/}
          {/*      name={stepOneFields.under18Confirm}*/}
          {/*      checked={!!personalInfo?.under18Confirm}*/}
          {/*      isDisabled={*/}
          {/*        !!disabledFields?.[stepOneFields.under18Confirm]*/}
          {/*      }*/}
          {/*      form={config.forms.personalData}*/}
          {/*      onChange={() =>*/}
          {/*        updatePersonalInfo({*/}
          {/*          changes: {under18Confirm: !personalInfo.under18Confirm},*/}
          {/*        })*/}
          {/*      }*/}
          {/*    >*/}
          {/*      {t('STEP_ONE_FIELDS.UNDER_18_CONFIRM_CHECKBOX')}*/}
          {/*    </CheckBox>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Collapse>
        {/*NEW FIELDS ENDS*/}

        <CollapseHeader
          id="5"
          isOpen={collapses['5']}
          title={t('ACADEMIC_INFO')}
          toggle={() => toggleCollapse('5')}
        />
        <Collapse isOpen={collapses['5']}>
          <Row className="g-2 gx-5 mb-40">
            {/*TODO: Delete after fixes*/}
            {/*<Col className="col-12 col-md-6">*/}
            {/*  <CUI.Label _labelPerach>{t('LAST_ACADEMIC_CITY')}</CUI.Label>*/}
            {/*  <CUI.DropDownSelect*/}
            {/*    form={config.forms.personalData}*/}
            {/*    name={stepOneFields.academicCity}*/}
            {/*    isDisabled={!!disabledFields?.[stepOneFields.academicCity]}*/}
            {/*    value={personalInfo?.educationDetails.city || null}*/}
            {/*    data={cities}*/}
            {/*    textField={'text'}*/}
            {/*    shouldAllowEmpty*/}
            {/*    shouldFilterValues*/}
            {/*    onChange={(e) =>*/}
            {/*      handleChangeEducation('city', e?.component?.value)*/}
            {/*    }*/}
            {/*    isRequired*/}
            {/*  />*/}
            {/*</Col>*/}
            {!disabledFields?.[stepOneFields.isBachelor] && (
              <Col className="col-12 col-md-6">
                <LabelWithPopup
                  label={t('WAS_STUDYING_BA')}
                  helpKey={stepOneFields.isBachelor}
                />
                <OptionsInput
                  options={BOOL_OPTIONS}
                  isDisabled={!!disabledFields?.[stepOneFields.isBachelor]}
                  value={personalInfo?.educationDetails?.isBachelor}
                  onChange={(val) => handleChangeEducation('isBachelor', val)}
                />
              </Col>
            )}
          </Row>
          <Row className="g-2 gx-5">
            <Col className="col-12 col-md-6 mb-40">
              <LabelWithPopup
                label={t('YEAR_OF_STUDYING')}
                helpKey={stepOneFields.year}
              />
              <CUI.DropDownSelect
                form={config.forms.personalData}
                name={stepOneFields.year}
                isDisabled={!!disabledFields?.[stepOneFields.year]}
                value={fixValue(
                  personalInfo?.educationDetails.year,
                  schoolYearMock
                )}
                data={schoolYearMock}
                textField={'text'}
                shouldAllowEmpty
                shouldFilterValues
                onChange={(e) =>
                  handleChangeEducation('year', e?.component?.value)
                }
                isRequired
              />
            </Col>
          </Row>
          <Row className="g-2 gx-5">
            <Col className="col-12 col-md-6 mb-40">
              <LabelWithPopup
                label={t('SPECIALITY')}
                helpKey={stepOneFields.field}
              />
              <CUI.DropDownSelect
                form={config.forms.personalData}
                name={stepOneFields.field}
                isDisabled={!!disabledFields?.[stepOneFields.field]}
                value={fixValue(
                  personalInfo?.educationDetails.field,
                  studyFields
                )}
                data={studyFields}
                textField={'text'}
                shouldAllowEmpty
                shouldFilterValues
                onChange={(e) =>
                  handleChangeEducation('field', e?.component?.value)
                }
                isRequired
              />
            </Col>
            <Optional
              when={personalInfo?.educationDetails.field.value === '17'}
            >
              <Col className="col-12 col-md-6">
                <InputField
                  label={t('WHAT_SPECIALITY')}
                  name={stepOneFields.otherField}
                  isDisabled={!!disabledFields?.[stepOneFields.otherField]}
                  value={personalInfo.educationDetails.otherField}
                  onChange={(e) =>
                    handleChangeEducation('otherField', e?.component?.value)
                  }
                  maxLength={30}
                  isRequired={true}
                  requiredMessage={t('FIELD_REQUIRED')}
                />
              </Col>
            </Optional>
          </Row>
          <Row className="g-2 gx-5">
            <Col className="col-12 col-md-6">
              <LabelWithPopup
                label={t('ADDITIONAL_SPECIALITY')}
                helpKey={stepOneFields.additionalField}
              />
              <CUI.DropDownSelect
                form={config.forms.personalData}
                name={stepOneFields.additionalField}
                isDisabled={!!disabledFields?.[stepOneFields.additionalField]}
                value={fixValue(
                  personalInfo?.educationDetails.additionalField,
                  [{ value: '', text: 'ללא' }, ...studyFields]
                )}
                data={[{ value: '', text: 'ללא' }, ...studyFields]}
                textField={'text'}
                shouldAllowEmpty
                shouldFilterValues
                onChange={(e) =>
                  handleChangeEducation('additionalField', e?.component?.value)
                }
              />
            </Col>
            <Optional
              when={
                personalInfo?.educationDetails.additionalField.value === '17'
              }
            >
              <Col className="col-12 col-md-6">
                <InputField
                  label={t('WHAT_SPECIALITY')}
                  name={stepOneFields.additionalAnotherFieldOfStudy}
                  isDisabled={
                    !!disabledFields?.[
                      stepOneFields.additionalAnotherFieldOfStudy
                    ]
                  }
                  value={
                    personalInfo?.educationDetails
                      ?.additionalAnotherFieldOfStudy
                  }
                  onChange={(e) =>
                    handleChangeEducation(
                      'additionalAnotherFieldOfStudy',
                      e?.component?.value
                    )
                  }
                  maxLength={30}
                  requiredMessage={t('FIELD_REQUIRED')}
                  isRequired={true}
                />
              </Col>
            </Optional>
          </Row>
          <Row className="mb-8">
            <Col className="col-12 d-inline-flex align-items-center">
              <CheckBox
                value={personalInfo.isEducationalStudent}
                name={stepOneFields.isEducationalStudent}
                checked={!!personalInfo?.isEducationalStudent}
                isDisabled={
                  !!disabledFields?.[stepOneFields.isEducationalStudent]
                }
                form={config.forms.personalData}
                onChange={() =>
                  updatePersonalInfo({
                    changes: {
                      isEducationalStudent: !personalInfo.isEducationalStudent,
                    },
                  })
                }
              >
                {'לומד.ת לתואר בהוראה, B.Ed'}
              </CheckBox>
              <span className={'strong-text-important'}>
                <HelpText
                  fieldName={stepOneFields.isEducationalStudent as HelpKeyType}
                />
              </span>
            </Col>
          </Row>
        </Collapse>

        <CollapseHeader
          id="6"
          isOpen={collapses['6']}
          title={t('MILITARY_INFO')}
          toggle={() => toggleCollapse('6')}
        />
        <Collapse isOpen={collapses['6']}>
          <Row className="g-2 gx-5">
            <Col className="col-12 col-md-6 mb-40">
              <LabelWithPopup
                label={t('MILITARY_KIND')}
                helpKey={stepOneFields.militaryServiceType}
              />
              <CUI.DropDownSelect
                form={config.forms.personalData}
                name={stepOneFields.militaryServiceType}
                isDisabled={
                  !!disabledFields?.[stepOneFields.militaryServiceType]
                }
                value={fixValue<MilitaryType>(
                  personalInfo?.militaryServiceType,
                  militaryServiceMock
                )}
                data={militaryServiceMock}
                textField={'text'}
                requiredMessage={t('FIELD_REQUIRED')}
                shouldFilterValues
                onChange={(e) =>
                  updatePersonalInfo({
                    changes: {
                      militaryServiceType: e.component.value,
                      serviceMoth:
                        // @ts-ignore
                        e.component.value === MilitaryType.NOT_SERVED
                          ? ''
                          : personalInfo?.serviceMoth,
                    },
                  })
                }
                isRequired
              />
            </Col>
            <Optional
              when={
                personalInfo?.militaryServiceType.value !==
                  MilitaryType.NOT_SERVED &&
                personalInfo?.militaryServiceType.value !== ''
              }
            >
              <Col className="col-12 col-md-6 mb-40">
                <InputField
                  label={t('MILITARY_YEARS')}
                  name={stepOneFields.serviceMoth}
                  isDisabled={!!disabledFields?.[stepOneFields.serviceMoth]}
                  value={personalInfo.serviceMoth}
                  onChange={onFieldChange}
                  isRequired
                  allowedSymbols="numbers"
                  maxLength={3}
                />
              </Col>
            </Optional>
          </Row>
          <Row className="g-2 gx-5">
            <Col className="col-12 col-md-6 mb-40">
              <LabelWithPopup
                label={getReservistDatesRange()}
                helpKey={stepOneFields.isReservist}
              />
              <OptionsInput
                isDisabled={!!disabledFields?.[stepOneFields.isReservist]}
                options={BOOL_OPTIONS}
                value={personalInfo?.isReservist || false}
                onChange={(val) =>
                  updatePersonalInfo({ changes: { isReservist: val } })
                }
              />
            </Col>
            <Optional when={personalInfo?.isReservist === true}>
              <Col className="col-12 col-md-6 mb-40">
                <InputField
                  label={'מספר ימי המילואים בטווח התאריכים'}
                  name={stepOneFields.daysInReserve}
                  isDisabled={!!disabledFields?.[stepOneFields.daysInReserve]}
                  value={personalInfo.daysInReserve}
                  onChange={onFieldChange}
                  isRequired
                  allowedSymbols="numbers"
                  maxLength={3}
                />
              </Col>
            </Optional>
          </Row>
        </Collapse>
        <Optional when={!shouldHideButtons}>
          <Row>
            <Col className="col-12 col-md-9 mb-40">
              <OpionalTooltip
                option={isButtonDisabled}
                text={t('DISABLE_BUTTON_STEP_ONE_TOOLTIP')}
              >
                <CUI.Button
                  _dBlock
                  _w100
                  _signInButton
                  _btnPrimaryPerach
                  form={config.forms.personalData}
                  isDisabled={
                    isButtonDisabled ||
                    !!disabledFields?.[stepOneFields.buttonSubmit]
                  }
                  shouldScrollToInvalidFields
                  scrollOffset={135}
                  onClick={() => saveUserPersonalData()}
                >
                  {t('NEXT')}
                </CUI.Button>
              </OpionalTooltip>
            </Col>
            <Col className="col-12 col-md-3 mb-40">
              <CUI.Button
                _dBlock
                _w100
                _signInButton
                _btnInfoPerach
                isDisabled={!!disabledFields?.[stepOneFields.buttonSave]}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                {t('SAVE_AND_CLOSE')}
              </CUI.Button>
            </Col>
          </Row>
        </Optional>
      </div>
    </>
  );
};
