import {
  isArray,
  isFunction,
  isNil,
  isString,
  isRegExp,
  isObject,
  isDate,
} from 'lodash';

import { PREDEFINED_VALIDATORS } from './predefinedValidators';
import {
  AddFieldData,
  Field,
  Form,
  FormGetField,
  NormalizedValidatorObject,
  PredefinedValidator,
  PreValidationResult,
  RemoveFieldOptions,
  UpdateFieldData,
  Validator,
  ValidatorObject,
} from './types';
import { checkIsFilled } from '../../form/helpers';
import { isDatesEqual } from '../../src/Calendar/helpers';
import {
  checkIsEmptyValue,
  checkIsFullFilled,
  getEmptyValue,
} from '../../src/MaskedInputBase/helpers';

export const getForms = (formName?: string | string[]): Form[] => {
  // @ts-ignore
  const forms: Form[] = window[Symbol.for('leda/validation-forms')] || [];

  if (isString(formName)) {
    const form = forms.find(
      (currentForm: Form) => currentForm.name === formName
    );
    return form ? [form] : [];
  }

  if (isArray(formName)) {
    return forms.filter((form: Form) => formName.includes(form.name));
  }

  // get all available forms
  return forms;
};

export const setForms = (newForms: Form[]): void => {
  // @ts-ignore
  window[Symbol.for('leda/validation-forms')] = newForms;
};

export const getField = (
  formName?: string,
  fieldName?: string
): Field | undefined => {
  if (!formName || !fieldName) return undefined;

  const forms = getForms();

  const currentForm = forms.find((form) => form.name === formName);

  if (!currentForm) return undefined;

  const currentField = currentForm.fields.find(
    (field) => field.name === fieldName
  );

  if (!currentField) return undefined;

  return currentField;
};

/**
 * Pre-validates value before launching main validators
 * @param {any} value - Current value
 * @param {Field} field - Field
 * @param {boolean} isValidCurrent - Current status of isValid value (for controlled mode)
 *
 * @returns {PreValidationResult} Defines if form or field is valid or invalid or needs launching main validators
 */
export const getPreValidationResult = (
  value: any,
  field: Field,
  isValidCurrent?: boolean
): PreValidationResult => {
  const { isRequired, mask } = field;

  /** MaskedInput pre-validation */
  if (mask) {
    const { placeholderChar } = field;

    const emptyValue = getEmptyValue(mask, placeholderChar);
    const isEmptyValue = checkIsEmptyValue(value, emptyValue);

    const isFullFilled = checkIsFullFilled(value, mask, placeholderChar);

    /** If field is optional and has empty value, then it is valid */
    if (!isRequired && isEmptyValue) return PreValidationResult.FieldIsValid;

    /** If field has not full filled mask, then it is invalid */
    if (!isFullFilled) return PreValidationResult.FieldIsInvalid;

    return PreValidationResult.RequireRunValidators;
  }

  const isFilled = checkIsFilled(value);

  /** If field is required and not filled */
  if (isRequired && !isFilled) return PreValidationResult.FieldIsInvalid;

  /** Take into account current isValid value for controlled mode */
  if (isFilled || !isValidCurrent)
    return PreValidationResult.RequireRunValidators;
  if (isValidCurrent) return PreValidationResult.FieldIsValid;

  return PreValidationResult.RequireRunValidators;
};

/**
 * Function starts validators validation
 * @param {any} value - Current value
 * @param {NormalizedValidatorObject[]} validators - Validators array
 * @returns {boolean} Value is valid?
 */
export const runValidators = (
  value: any,
  validators: NormalizedValidatorObject[]
): boolean => !validators.some((validator) => !validator.validator(value));

/**
 * Function get invalid messages array
 * @param {any} value - Current value
 * @param {Field} field - Field
 * @param {PreValidationResult} preValidationResult - Defines if form or field is valid or invalid or needs launching main validators
 * @returns {string[]} Invalid messages array
 */
export const getInvalidMessages = (
  value: any,
  field: Field,
  preValidationResult: PreValidationResult
): string[] => {
  const invalidMessages: string[] = [];

  /** Add validator messages if they were running */
  if (preValidationResult === PreValidationResult.RequireRunValidators) {
    field.validators.forEach((validator) => {
      /** If validator looks like { validator, invalidMessage } - get error message */
      if (
        isObject(validator) &&
        'validator' in validator &&
        !validator.validator(value) &&
        validator.invalidMessage
      )
        invalidMessages.push(validator.invalidMessage);
    });

    return invalidMessages;
  }

  /** Add a requiredMessage when field is required */
  if (field.isRequired && field.requiredMessage) {
    invalidMessages.push(field.requiredMessage);
  } else if (field.invalidMessage) {
    /** Add a invalidMessage when field is optional */
    invalidMessages.push(field.invalidMessage);
  }

  return invalidMessages;
};

/**
 * Validation function. Used in form submit handler
 * @param {string | undefined} formName - Name of form
 * @param {string} fieldName - Name of field
 * @param {unknown} externalValue - Value that will be validated instead of current field value
 * @param {boolean | undefined} isValidateCurrent - Is validate current
 *
 * @returns {boolean} Flag defines if form or field is valid
 */
export const validate = (
  formName: string | undefined,
  fieldName?: string,
  externalValue?: unknown,
  isValidateCurrent?: boolean
): boolean => {
  const forms: Form[] = getForms();

  const currentForm = forms.find((form) => form.name === formName);

  if (!currentForm) return false;

  if (!fieldName) {
    return currentForm.fields
      .map((field) => validate(formName, field.name))
      .every((result) => result);
  }

  const currentField = getField(formName, fieldName);

  if (!currentField) return false;

  /**
   * If form is submitted take the current isValid value of the field (can be false in controlled mode)
   * If validateCurrent is called, set to true
   */
  let isValid = isValidateCurrent ?? currentField.isValid;

  const value =
    externalValue === undefined ? currentField.value : externalValue;

  const preValidationResult = getPreValidationResult(
    value,
    currentField,
    isValid
  );

  /** If result of pre-validation is RequireRunValidators then run validators */
  if (preValidationResult === PreValidationResult.RequireRunValidators) {
    isValid = runValidators(value, currentField.validators);
  } else {
    /** Else, use result of pre-validation */
    isValid = preValidationResult === PreValidationResult.FieldIsValid;
  }

  /** If result of validation showed that field is invalid, add message to array of invalid messages */
  const invalidMessages: string[] = !isValid
    ? getInvalidMessages(value, currentField, preValidationResult)
    : [];

  const newForms = [
    ...forms.map((form: Form): Form => {
      if (form.name !== formName) return form;

      const newFields = currentForm.fields.map((field) => {
        if (field.name !== fieldName) return field;

        return {
          ...field,
          invalidMessages,
          isValid,
          value,
        };
      });

      return { fields: newFields, name: formName };
    }),
  ];

  setForms(newForms);

  currentField.setIsValid(isValid);

  currentField.setMessages(invalidMessages);

  return isValid;
};

export const addField = ({
  formName,
  fieldName,
  value,
  setIsValid,
  setMessages,
  shouldValidateUnmounted = false,
  validators,
  isRequired = false,
  requiredMessage,
  reset,
  mask,
  placeholderChar,
  invalidMessage,
}: AddFieldData): void => {
  const forms: Form[] = getForms();

  const currentForm = forms.find((form) => form.name === formName);

  if (!currentForm) {
    const newForms = [
      ...forms,
      {
        fields: [
          {
            invalidMessage,
            isRequired,
            isValid: true,
            mask,
            name: fieldName,
            placeholderChar,
            requiredMessage,
            reset,
            setIsValid,
            setMessages,
            shouldValidateUnmounted,
            validators,
            value,
          },
        ],
        name: formName,
      },
    ];

    setForms(newForms);

    return;
  }

  const currentField = currentForm.fields.find(
    (field) => field.name === fieldName
  );

  if (!currentField) {
    const newForms = [
      ...forms.map((form: Form): Form => {
        if (form.name !== formName) return form;

        const newFields = [
          ...currentForm.fields,
          {
            invalidMessage,
            isRequired,
            isValid: true,
            mask,
            name: fieldName,
            placeholderChar,
            requiredMessage,
            reset,
            setIsValid,
            setMessages,
            shouldValidateUnmounted,
            validators,
            value,
          },
        ];

        return { fields: newFields, name: formName };
      }),
    ];

    setForms(newForms);

    return;
  }

  if (currentField.shouldValidateUnmounted) {
    const newForms = [
      ...forms.map((form: Form): Form => {
        if (form.name !== formName) return form;

        const newFields = [
          ...currentForm.fields.map((field) => {
            if (field.name !== fieldName) return field;

            return { ...field, setIsValid };
          }),
        ];

        return { fields: newFields, name: formName };
      }),
    ];

    setForms(newForms);
  }
};

export const removeField = (
  formName: string,
  fieldName: string,
  options: RemoveFieldOptions = {}
): void => {
  const { shouldRemoveUnmounted } = options;

  const forms: Form[] = getForms();

  const currentForm = forms.find((form) => form.name === formName);

  if (!currentForm) {
    return;
  }

  const currentField = currentForm.fields.find(
    (field) => field.name === fieldName
  );

  if (!currentField) {
    return;
  }

  if (currentField.shouldValidateUnmounted && shouldRemoveUnmounted !== true) {
    const newForms = [
      ...forms.map((form: Form): Form => {
        if (form.name !== formName) return form;

        const newFields = [
          ...currentForm.fields.map((field) => {
            if (field.name !== fieldName) return field;
            // stub for an unmounted component
            return {
              ...field,
              setIsValid: (): void => {},
            };
          }),
        ];

        return { fields: newFields, name: formName };
      }),
    ];

    setForms(newForms);

    return;
  }

  const newForms = [
    ...forms.map((form: Form): Form => {
      if (form.name !== formName) return form;

      const newFields = currentForm.fields.filter(
        (field) => field.name !== fieldName
      );

      return { fields: newFields, name: formName };
    }),
  ];

  setForms(newForms.filter((form) => form.fields.length !== 0));
};

export const removeForm = (formName: string): void => {
  const forms: Form[] = getForms();

  const currentForm = forms.find((form) => form.name === formName);

  if (!currentForm) {
    return;
  }

  setForms(forms.filter((form) => form.name !== formName));
};

/**
 * Helper updates state of field
 * @param {UpdateFieldData} data - Update field data
 *
 * @returns {void}
 */
export const updateField = ({
  formName,
  fieldName,
  value,
  isValidProp,
  isRequired = false,
  requiredMessage,
  shouldValidateUnmounted = false,
  validators,
  mask,
  placeholderChar,
  invalidMessage,
}: UpdateFieldData): void => {
  const forms: Form[] = getForms();

  const currentForm = forms.find((form) => form.name === formName);

  if (!currentForm) {
    return;
  }

  const currentField = currentForm.fields.find(
    (field) => field.name === fieldName
  );

  if (!currentField) {
    return;
  }

  const isValid = ((): boolean => {
    // if validation is controlled
    if (!isNil(isValidProp)) return isValidProp;
    // if is date value and previous value was null, return to initial validation state
    // eslint-disable-next-line eqeqeq
    if (isDate(value) && currentField.value == null) return true;
    // if date value has changed, return to initial validation state
    if (isDate(value) && !isDatesEqual(value, currentField.value)) return true;
    // if the value has changed, return to initial validation state
    if (!isDate(value) && value !== currentField.value) return true;
    // if isRequired prop was added or removed, return to initial validation state
    if (isRequired !== currentField.isRequired) return true;
    // do nothing
    return currentField.isValid;
  })();

  const invalidMessages =
    value !== currentField.value ? [] : currentField.invalidMessages;

  const newForms = [
    ...forms.map((form: Form): Form => {
      if (form.name !== formName) return form;

      const newFields = currentForm.fields.map((field) => {
        if (field.name !== fieldName) return field;

        return {
          ...field,
          invalidMessage,
          isRequired,
          isValid,
          mask,
          placeholderChar,
          requiredMessage,
          shouldValidateUnmounted,
          validators,
          value,
        };
      });

      return { fields: newFields, name: formName };
    }),
  ];

  if (currentField.isValid !== isValid) {
    currentField.setIsValid(isValid);
  }
  // if invalidMessages were changed or removed (length === 0)
  if (
    currentField.invalidMessages !== invalidMessages &&
    invalidMessages &&
    invalidMessages.length !== 0
  ) {
    currentField.setMessages(invalidMessages);
  }

  setForms(newForms);
};

export const getInvalidMessage = (
  formName?: string,
  fieldName?: string
): string[] | undefined => {
  const forms: Form[] = getForms();

  const currentForm = forms.find((form) => form.name === formName);

  if (!currentForm) {
    return undefined;
  }

  const currentField = currentForm.fields.find(
    (field) => field.name === fieldName
  );

  if (!currentField) {
    return undefined;
  }

  return currentField.invalidMessages;
};

export const getPredefinedValidator = (
  type: PredefinedValidator,
  customMessage?: string
): NormalizedValidatorObject => {
  const predefinedValidator = PREDEFINED_VALIDATORS[type];

  if (!predefinedValidator)
    throw new Error('Cui.Validator: no such predefined validator');

  return customMessage
    ? {
        invalidMessage: customMessage,
        validator: predefinedValidator.validator,
      }
    : predefinedValidator;
};

const getRegExpValidator = (
  validator: RegExp,
  invalidMessage?: string
): NormalizedValidatorObject => {
  const testRegExp: Validator = (value) => !!value.match(validator);

  return { invalidMessage, validator: testRegExp };
};

const getArrayValidator = (
  validator: ValidatorObject[],
  customMessage?: string
): NormalizedValidatorObject[] =>
  validator.map((validatorItem) => {
    if (!isObject(validatorItem))
      throw new Error(
        `L.Validation: type of validator ${JSON.stringify(
          validator
        )} is incorrect!`
      );
    // { function, message? }
    if (isFunction(validatorItem.validator)) {
      return {
        invalidMessage: validatorItem.invalidMessage,
        validator: validatorItem.validator,
      };
    }

    // { predefinedValidator, message? }
    if (isString(validatorItem.validator)) {
      const predefinedValidator = getPredefinedValidator(
        validatorItem.validator as PredefinedValidator
      );

      return customMessage
        ? {
            invalidMessage: customMessage,
            validator: predefinedValidator.validator,
          }
        : predefinedValidator;
    }

    // { regexp, message? }
    if (isRegExp(validatorItem.validator)) {
      return {
        invalidMessage: validatorItem.invalidMessage,
        validator: (value: string): boolean =>
          !!value.match(validatorItem.validator as RegExp),
      };
    }

    throw new Error(
      `L.Validation: type of validator ${JSON.stringify(
        validator
      )} is incorrect!`
    );
  });

export const getValidators = (
  validator?: Validator | PredefinedValidator | RegExp | ValidatorObject[],
  invalidMessage?: string,
  isValidProp?: boolean
): NormalizedValidatorObject[] => {
  if (isValidProp !== undefined)
    return [{ invalidMessage, validator: (): boolean => isValidProp }];

  if (!validator) return [];

  if (isFunction(validator)) return [{ invalidMessage, validator }];

  if (isString(validator))
    return [getPredefinedValidator(validator as PredefinedValidator, invalidMessage)];

  if (isRegExp(validator))
    return [getRegExpValidator(validator, invalidMessage)];

  if (Array.isArray(validator))
    return getArrayValidator(validator, invalidMessage);

  throw new Error(
    `L.Validation: type of validator ${JSON.stringify(validator)} is incorrect!`
  );
};

export const getFieldValidState = (
  formName: string,
  fieldName: string
): FormGetField | undefined => {
  const rawField = getField(formName, fieldName);

  // eslint-disable-next-line eqeqeq
  if (rawField == null) return undefined;

  const { name, value, validators, isRequired, mask, placeholderChar } =
    rawField;

  /** Field isFilled flag. Mask is filled only if it is fulfilled */
  const isFilled = mask
    ? checkIsFullFilled(value, mask, placeholderChar)
    : checkIsFilled(value);

  const isValid = ((): boolean => {
    const preValidationResult = getPreValidationResult(
      value,
      rawField,
      rawField.isValid
    );

    /** If result of pre-validation is RequireRunValidators then run validators */
    if (preValidationResult === PreValidationResult.RequireRunValidators)
      return runValidators(value, validators);
    /** Else, use result of pre-validation */
    return preValidationResult === PreValidationResult.FieldIsValid;
  })();

  return {
    isFilled,
    isRequired,
    isValid,
    name,
    value,
  };
};
