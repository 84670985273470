import { request } from '../../../common/request';

import { CheckEligibilityRequest, CheckEligibilityResponse } from '../types';

const PATH = `${process.env.REACT_APP_API_URL}/core-service/api/eligibility`;
const OTP_PATH = `${process.env.REACT_APP_API_URL_AUTHZ}/authz-service/api`;

export const sendCheckEligibilityForm = async (
  userData: CheckEligibilityRequest
): Promise<CheckEligibilityResponse> => {
  const data = await request.post<CheckEligibilityResponse>({
    url: `${PATH}`,
    data: userData,
  });
  return data?.data;
};

export const sendOtpCode = async (
  code: string,
  username: string,
  password: string
): Promise<any> => {
  const data = await request.post({
    url: `${OTP_PATH}/auth/verify-otp`,
    data: { code, username, password },
  });

  return data?.data;
};

export const resendOtpCode = async (
  username: string,
  password: string
): Promise<any> => {
  const data = await request.post({
    url: `${OTP_PATH}/auth/resend-OTP`,
    data: { username, password },
  });
  return data;
};
