import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setStoreField } from '@common/store-utils';

import { storeUtils } from '../../../common';

import { config } from '../config';
import {
  ModelState,
  PersonalInfo,
  BankDetails,
  AdditionalInfo,
  DisabledFamilyMember,
  DocumentsForm,
  PrefilledDocuments,
  FamilyMembersType,
  FamilyDocumentType,
  PrefillFileType,
  WizardSteps,
} from '../types';
import { createFamilyMember, modelStateDefaultValue } from '../defaults';

type Update<T> = {
  changes: Partial<T>;
  withNoChangesMark?: boolean;
};
type UpdateFamilyIncome<T> = {
  changes: Partial<T>;
  memberIndex: number;
};

const initialState = modelStateDefaultValue;

const createAction = storeUtils.createActionCreatorWithPrefix(config.modelName);

/**
 * Action for prefill user registration data
 */
const prefillUserPersonalData = createAction('prefillUserData');

/**
 * Action for saving user data
 */
const saveUserPersonalData = createAction('saveUserData');

/**
 * Action for saving user bank details
 */
const saveBankDetails = createAction('saveBankDetails');

/**
 * Action for saving user additional data
 */
const saveAdditionalData = createAction('saveAdditionalData');

/**
 * Action for saving user documents
 */
const saveDocumentsData = createAction('saveDocumentsData');

/**
 * Action for auto-save form
 */
const autoSaveRegistration = createAction('autoSaveRegistration');

/**
 * Action for send email to student
 */
const sendEmailToStudent = createAction('sendEmailToStudent');

/**
 * Action for auto-save form
 */
const getUserData = createAction('getUserData');

/**
 * Action for getting documents to be fixed
 */
const getUserDocumentsToFix = createAction('getUserDocumentsToFix');

/**
 * Action for getting documents to be fixed
 */
const pingIframe = createAction('pingIframe');

/**
 * Action for sending documents after fixes
 */
const sendDocumentsAfterFixes = createAction('sendDocumentsAfterFixes');

/**
 * Action for sending documents after fixes
 */
const getDocuments = createAction('getDocuments');
const getCurrentUser = createAction('getCurrentUser');
const submitRequestConfirm = createAction('submitRequestConfirm');

export const createCheckEligibilitySlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    // Update hole state
    setState: (_, { payload }: PayloadAction<ModelState>) => payload,

    // partial personal info update
    updatePersonalInfo(
      state,
      { payload }: PayloadAction<Update<PersonalInfo>>
    ) {
      const { changes, withNoChangesMark } = payload;
      state.personalInfo = { ...state.personalInfo, ...changes };
      state.changed = !withNoChangesMark;
    },

    // partial bank details update
    updateBankDetails(state, { payload }: PayloadAction<Update<BankDetails>>) {
      const { changes, withNoChangesMark } = payload;
      state.bankDetails = { ...state.bankDetails, ...changes };
      state.changed = !withNoChangesMark;
    },

    // partial additional info update
    updateAdditionalInfo(
      state,
      { payload }: PayloadAction<Update<AdditionalInfo>>
    ) {
      const { changes, withNoChangesMark } = payload;
      state.additionalInfo = { ...state.additionalInfo, ...changes };
      state.changed = !withNoChangesMark;
    },

    addDisabledFamilyMember(state) {
      state.additionalInfo.disabledFamilyMembers.push(createFamilyMember());
      state.changed = true;
    },

    removeDisabledFamilyMember(state, { payload }: PayloadAction<string>) {
      state.additionalInfo.disabledFamilyMembers =
        state.additionalInfo.disabledFamilyMembers.filter(
          ({ id }) => id !== payload
        );
      state.changed = true;
    },

    updateDisabledFamilyMember(
      state,
      { payload }: PayloadAction<Update<DisabledFamilyMember> & { id: string }>
    ) {
      const { id, changes } = payload;
      const lineIndex = state.additionalInfo.disabledFamilyMembers.findIndex(
        (el) => el.id === id
      );
      const line = state.additionalInfo.disabledFamilyMembers[lineIndex];

      state.additionalInfo.disabledFamilyMembers[lineIndex] = {
        ...line,
        ...changes,
      };
      state.changed = true;
    },

    // partial documents update
    updateDocuments(state, { payload }: PayloadAction<Update<DocumentsForm>>) {
      const { changes, withNoChangesMark } = payload;
      state.documents = { ...state.documents, ...changes };
      state.changed = !withNoChangesMark;
    },

    // partial prefill documents update
    updatePrefillDocuments(
      state,
      { payload }: PayloadAction<Update<PrefilledDocuments>>
    ) {
      const { changes, withNoChangesMark = true } = payload;
      state.documentsPrefilled = { ...state.documentsPrefilled, ...changes };
      state.changed = !withNoChangesMark;
    },

    updateFamilyIncomeDocument(
      state,
      { payload }: PayloadAction<UpdateFamilyIncome<FamilyDocumentType>>
    ) {
      const { changes, memberIndex } = payload;
      state.documents.familyDocuments[memberIndex] = {
        ...state.documents.familyDocuments[memberIndex],
        ...changes,
      };
      state.changed = true;
    },

    updateBrothersDocument(
      state,
      { payload }: PayloadAction<{ index: number; value: string }>
    ) {
      const { index, value } = payload;
      state.documents.approvalStudyBrothersUnder30[index] = value;
      state.changed = true;
    },

    updatePrefilledFamilyIncomeDocument(
      state,
      {
        payload,
      }: PayloadAction<UpdateFamilyIncome<FamilyDocumentType<PrefillFileType>>>
    ) {
      const { changes, memberIndex } = payload;
      if (!!state?.documentsPrefilled?.familyDocuments?.[memberIndex]) {
        state.documentsPrefilled.familyDocuments[memberIndex] = {
          ...state.documentsPrefilled.familyDocuments[memberIndex],
          ...changes,
        };
        state.changed = false;
      }
    },

    setStep(state, { payload }: PayloadAction<WizardSteps>) {
      state.currentStep = payload;
      state.changed = false;
    },

    // set Wizard step
    //setStep: setStoreField('currentStep'),

    // set the changed marker
    setChangedMark: setStoreField('changed'),

    // set alert modal state
    setIsModalOpen: setStoreField('isModalOpen'),
    setBackToFix: setStoreField('backToFix'),
    setValidationErrors: setStoreField('validationError'),
    setResultModalOpen: setStoreField('isResultModalOpen'),
    reset: () => initialState,
  },
});

export const { reducer } = createCheckEligibilitySlice;

export const actions = {
  ...createCheckEligibilitySlice.actions,
  saveUserPersonalData,
  saveBankDetails,
  saveAdditionalData,
  autoSaveRegistration,
  prefillUserPersonalData,
  saveDocumentsData,
  sendEmailToStudent,
  getUserData,
  getUserDocumentsToFix,
  pingIframe,
  sendDocumentsAfterFixes,
  getDocuments,
  submitRequestConfirm,
  getCurrentUser,
};
