import {selectors, actions, sagas, reducer} from './model';

export * from './types';

import {config} from './config';

export const scholarshipFaqPageModel = {
  selectors,
  actions,
  config,
  sagas,
  reducer,
};
