import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { config } from '../config';
import { storeUtils } from '../../../common';
import { Dictionaries } from '../types';
import {PersonalInfo} from "@features/Registration";

const initialState = {
  [Dictionaries.AcademicInstitution]: [],
  [Dictionaries.StudyFields]: [],
  [Dictionaries.Cities]: [],
  [Dictionaries.Sector]: [],
  [Dictionaries.Banks]: [],
  [Dictionaries.Districts]: [],
  loaders: {
    [Dictionaries.Districts]: false,
  }
};

const createAction = storeUtils.createActionCreatorWithPrefix(config.modelName);

/**
 * Action for getting all dictionaries
 */
const getDictionaries = createAction('getDictionaries');

const getDistricts = createAction<string>('getDistricts');

export const createDictionariesSlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    setAcademicInstitutions: storeUtils.setStoreField(
      Dictionaries.AcademicInstitution
    ),
    setStudyFields: storeUtils.setStoreField(
      Dictionaries.StudyFields
    ),
    setCities: storeUtils.setStoreField(
      Dictionaries.Cities
    ),
    setSector: storeUtils.setStoreField(
      Dictionaries.Sector
    ),
    setBanks: storeUtils.setStoreField(
      Dictionaries.Banks
    ),
    setDistricts: storeUtils.setStoreField(
      Dictionaries.Districts
    ),

    setLoading(
      state,
      { payload }: PayloadAction<{isLoading: boolean, dictionaryName: Dictionaries}>
    ) {
      const { isLoading, dictionaryName } = payload;
      state.loaders = { ...state.loaders, [dictionaryName]: isLoading };
    },

    reset: () => initialState,
  },
});

export const { reducer } = createDictionariesSlice;

export const actions = {
  ...createDictionariesSlice.actions,
  getDictionaries,
  getDistricts,
};
