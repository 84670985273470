import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { config } from '../config';
import { storeUtils } from '../../../common';

import { setStoreField } from '../../../common/store-utils';
import {
  CheckEligibilityFormFields,
  CheckEligibilityDefaultValues,
  ModelState,
} from '../types';

type Update<T> = {
  changes: Partial<T>;
};

const initialState = CheckEligibilityDefaultValues;

const createAction = storeUtils.createActionCreatorWithPrefix(config.modelName);

/**
 * Action for start check eligibility process
 */
const checkUserEligibility = createAction('checkUserEligibility');

/**
 * Action for confirming check eligibility with OTP
 */
const sendOtpCode = createAction('sendOtpCode');

/**
 * Action for resending the OTP code
 */
const resendOtpCode = createAction('resendOtpCode');

/**
 * Action for requesting OTP code again
 */
const requestOtpCodeAgain = createAction('requestOtpCodeAgain');

export const createCheckEligibilitySlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    // Update hole state
    setState: (_, { payload }: PayloadAction<ModelState>) => payload,

    // partial state update
    update(
      state,
      { payload }: PayloadAction<Update<CheckEligibilityFormFields>>
    ) {
      const { changes } = payload;
      state.formData = { ...state.formData, ...changes };
    },

    // set status of Check process
    setStatus: setStoreField('status'),

    // set status of Check process
    setOtp: setStoreField('otpCode'),

    // set error message for check eligibility
    setErrors: setStoreField('errors'),

    reset: () => initialState,
  },
});

export const { reducer } = createCheckEligibilitySlice;

export const actions = {
  ...createCheckEligibilitySlice.actions,
  checkUserEligibility,
  sendOtpCode,
  requestOtpCodeAgain,
  resendOtpCode,
};
