const isPasswordValid = (password: string): boolean => {
  if (password.length < 8) return false;
  const validator = !(
    !/[a-z]/.test(password) ||
    !/[A-Z]/.test(password) ||
    !/\d/.test(password)
  );
  return validator;
};

export default isPasswordValid;
