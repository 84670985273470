type CheckFormProps = {
  form?: string,
  name?: [string | undefined, string | undefined] | string,
};
/**
 * Throws error if form goes without name
 * @param { string | [(string | undefined), (string | undefined)] | undefined } name - Field name
 * @param { string | undefined } form - Form name
 *
 * @returns {void}
 */
export const checkFormProps = ({ name, form }: CheckFormProps): void => {
  if (!name && form) {
    console.error('prop "name" must be provided with "form"');
  }
};
