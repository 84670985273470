import {sagas, actions, reducer, selectors} from './model';
import {config} from './config';

export * from './types';

export {HelpText} from './HelpText'

export const settingsModel = {
  actions,
  reducer,
  sagas,
  config,
  selectors,
};
