import { selectors, actions, sagas, reducer } from './model';
import { config } from './config';

export * from './types';

export const studentSurveyModel = {
  selectors,
  actions,
  config,
  sagas,
  reducer,
};
