import { selectors, actions, sagas, reducer } from './model';
export * from './types';

import { config } from './config';

export const faqPageModel = {
  selectors,
  actions,
  config,
  sagas,
  reducer,
};
