import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons';

type CollapseHeaderProps = {
  title: string;
  isOpen: boolean;
  id: string;
  toggle: () => void;
};

/**
 * Header that triggers collapsable elements
 * @returns React.FC
 * */
export const CollapseHeader: React.FC<CollapseHeaderProps> = ({
  title,
  isOpen,
  id: string,
  toggle,
}) => (
  <div className="collapse-container" onClick={toggle}>
    <div className="collapse-item-name">{title}</div>
    <div className="collapse-item-sign">
      {isOpen ? (
        <FontAwesomeIcon icon={faMinusCircle} />
      ) : (
        <FontAwesomeIcon icon={faPlusCircle} />
      )}
    </div>
  </div>
);
