import {
  createModelSelector,
  createHookSelector,
  createModelFieldSelector,
} from '@common/store-utils';

import { config } from '../config';
import { ModelState } from '../types';

/**
 * Selector of model State
 */
const modelSelector = createModelSelector<ModelState>(config.modelName);

/**
 * Selector builder of model State field
 */
const fieldSelector = createModelFieldSelector<ModelState>(config.modelName);

/**
 * Selector - Carousel Items
 */
const carouselItemsSelector = fieldSelector('carouselItems');

/**
 * Selector - Info Squares
 */
const infoSquaresSelector = fieldSelector('infoSquares');

export const selectors = {
  home: modelSelector,
  carouselItems: carouselItemsSelector,
  infoSquares: infoSquaresSelector,

  useModelState: createHookSelector(modelSelector),
  useCarouselItems: createHookSelector(carouselItemsSelector),
  useInfoSquares: createHookSelector(infoSquaresSelector),
};
