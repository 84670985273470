import { SagaIterator } from 'redux-saga';
import { put, call, all, takeEvery } from 'redux-saga/effects';

import { getCurrentTimeApi } from './api';
import { actions } from './ducks';

/**
 * Load Dictionaries
 * @returns {void}
 */
function* loadCurrentTimeSaga(): SagaIterator {
  const data = yield call(getCurrentTimeApi);
  yield put(actions.setTime(data));
}

/**
 * Watcher
 * @returns {void}
 */
function* watcher(): SagaIterator<void> {
  yield all([takeEvery(actions.getTime, loadCurrentTimeSaga)]);
}

export const sagas = {
  watcher,
};
