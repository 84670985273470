import classNames from 'classnames';
import ButtonEnum from './ButtonEnum';
import './button.scss';

interface ButtonProps {
  text?: string;
  icon?: any;
  type: ButtonEnum;
  onClick?: () => void;
  className?: string;
  input?: any;
  disabled?: boolean;
}

const Button = (props: ButtonProps) => {
  return (
    <button
      className={classNames(
        'custom-button btn',
        props.className,
        { 'btn-primary-perach': props.type === ButtonEnum.PRIMARY },
        { 'btn-info-perach': props.type === ButtonEnum.INFO },
        { 'btn-blank-perach': props.type === ButtonEnum.BLANK },
        { 'btn-disabled-perach': props.disabled },
      )}
      type="submit"
      onClick={props.onClick}
    >
      {props.icon && <span className="button-icon">{props.icon}</span>}
      {props.text}
    </button>
  );
};
export default Button;
