import {createAction, createSlice} from '@reduxjs/toolkit';

import {setStoreField} from '@common/store-utils';

import {config} from '../config';
import {ModelState} from '../types';

const initialState: ModelState = {
  faqCategories: [],
  faqCategory: {
    id: 0,
    title: '',
    description: '',
  },
  searchInput: '',
};

/**
 * Action to get Carousel Items From API
 */
const getScholarshipFaqCategories = createAction('getScholarshipFaqCategories');

export const createScholarshipFaqSlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    setScholarshipFaqCategories: setStoreField('faqCategories'),
    setSearchInput: setStoreField('searchInput'),
    reset: () => initialState,
  },
});

export const {reducer} = createScholarshipFaqSlice;

export const actions = {
  ...createScholarshipFaqSlice.actions,
  getScholarshipFaqCategories,
};
