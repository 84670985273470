import { createAction, createSlice } from '@reduxjs/toolkit';

import { setStoreField } from '@common/store-utils';

import { config } from '../config';
import { ModelState } from '../types';

const initialState: ModelState = {
  faqCategories: [],
  faqCategory: {
    id: 0,
    title: '',
    description: '',
  },
  searchInput: '',
};

/**
 * Action to get Carousel Items From API
 */
const getFaqCategories = createAction('getFaqCategories');
const getInfoSquares = createAction('getInfoSquares');

export const createLoginSlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    setFaqCategories: setStoreField('faqCategories'),
    setInfoSquares: setStoreField('faqCategory'),
    setSearchInput: setStoreField('searchInput'),
    reset: () => initialState,
  },
});

export const { reducer } = createLoginSlice;

export const actions = {
  ...createLoginSlice.actions,
  getFaqCategories,
  getInfoSquares,
};
