import { request } from '@common/request';

const PATH = `${process.env.REACT_APP_API_URL_AUTHZ}/authz-service/api`;

/**
 * Api POST request for login with Email and Password
 * @returns {void}
 */
export const loginByPasswordApi = async (
  register_period: number,
  email: string,
  password: string
): Promise<any> => {
  const { data } = await request.post<any>({
    url: `${PATH}/auth/login-by-email`,
    data: {
      register_period,
      email,
      password,
    },
  });
  return data;
};

/**
 * Api POST request login with Phone number
 * @returns {void}
 */
export const loginByPhoneApi = async (phone: string): Promise<any> => {
  const { data } = await request.post<any>({
    url: `${PATH}/auth/login-by-phone`,
    data: {
      phone,
    },
  });
  return data;
};

/**
 * Api POST request for re-sending OTP for login by phone
 * @returns {void}
 */
export const resendOtpCodeApi = async (phone: string): Promise<any> => {
  const { data } = await request.post<any>({
    url: `${PATH}/auth/resend-code`,
    data: {
      phone,
    },
  });
  return data;
};

/**
 * Api POST request confirmation login by phone with OTP
 * @returns {void}
 */
export const confirmOtpCodeAfterLoginApi = async (
  session: string,
  username: string,
  code: string
): Promise<any> => {
  const { data } = await request.post<any>({
    url: `${PATH}/auth/verify-OTP-after-login`,
    data: {
      session,
      username,
      code,
    },
  });
  return data;
};
