import {
  createModelSelector,
  createHookSelector,
  createModelFieldSelector,
} from '@common/store-utils';

import { config } from '../config';
import { ModelState } from '../types';
import { createSelector } from '@reduxjs/toolkit';
import { validate } from '@CUI';
import isPasswordValid from '@/utility/validators/pass-validator';

/**
 * Selector of model State
 */
const modelSelector = createModelSelector<ModelState>(config.modelName);

/**
 * Selector builder of model State field
 */
const fieldSelector = createModelFieldSelector<ModelState>(config.modelName);

/**
 * Selector - User Country
 */
const countrySelector = fieldSelector('birth_country');

/**
 * Selector - First Parent Education
 */
const firstParentEducationSelector = fieldSelector('education_parent_one');

/**
 * Selector - Second Parent Education
 */
const secondParentEducationSelector = fieldSelector('education_parent_two');

/**
 * Selector - First Parent Country
 */
const firstParentCountrySelector = fieldSelector('birth_country_parent_one');

/**
 * Selector - Second Parent Country
 */
const secondParentCountrySelector = fieldSelector('birth_country_parent_two');

/**
 * Selector - Special Culture Group
 */
const specialCultureGroupSelector = fieldSelector('culture_group');

/**
 * Selector - Special Culture Group
 */
const sawSurveySelector = fieldSelector('saw_survey');

/**
 * Selector - Special Culture Group
 */
const tookSurveySelector = fieldSelector('took_survey');

/**
 * Selector - Special Culture Group
 */
const surveyStaticDataSelector = fieldSelector('surveyStaticData');

/**
 * Is form valid selector
 */
const isValidFormSelector = createSelector(modelSelector, (data) =>
  [Object.values(data).every((val) => val.length > 0)].every(Boolean)
);

export const selectors = {
  survey: modelSelector,
  country: countrySelector,
  firstParentEducation: firstParentEducationSelector,
  secondParentEducation: secondParentEducationSelector,
  secondParentCountry: secondParentCountrySelector,
  firstParentCountry: firstParentCountrySelector,
  specialCultureGroup: specialCultureGroupSelector,
  saw_survey: sawSurveySelector,
  took_survey: tookSurveySelector,
  surveyStaticData: surveyStaticDataSelector,
  isValidForm: isValidFormSelector,

  useSurveyModel: createHookSelector(modelSelector),
  useLoginData: createHookSelector(modelSelector),
  useCountry: createHookSelector(countrySelector),
  useFirstParentEducation: createHookSelector(firstParentEducationSelector),
  useSecondParentEducationSelector: createHookSelector(
    secondParentEducationSelector
  ),
  useSecondParentCountrySelector: createHookSelector(
    secondParentCountrySelector
  ),
  useFirstParentCountrySelector: createHookSelector(firstParentCountrySelector),
  useSpecialCultureGroup: createHookSelector(specialCultureGroupSelector),
  useIsValidFormSelector: createHookSelector(isValidFormSelector),
  useSawSurvey: createHookSelector(sawSurveySelector),
  useTookSurvey: createHookSelector(tookSurveySelector),
  useStaticData: createHookSelector(surveyStaticDataSelector),
};
