import {request} from '../../../common/request';
import {FieldHelpElement, RegistrationPeriodsResponse, SettingsResponse} from '../types';

const PATH = `${process.env.REACT_APP_API_URL}/core-service/api`;

export const getAppSettingsApi = async (): Promise<SettingsResponse> => {
  const {data} = await request.get<SettingsResponse>({
    url: `${PATH}/setting/get-all`,
  });
  return data;
};

export const getAllRegistrationPeriodsApi =
  async (): Promise<RegistrationPeriodsResponse> => {
    const {data} = await request.get<RegistrationPeriodsResponse>({
      url: `${PATH}/registration-period/get-all`,
    });
    return data;
  };

export const sendMailApi = async (
  from: string,
  to: string,
  subject: string,
  body: string,
): Promise<any> => {
  const {data} = await request.post<any>({
    url: `${PATH}/mailer/send-email-without-file`,
    data: {
      from,
      to,
      subject,
      body,
    },
  });
  return data;
};

export const getFieldsHelpApi = async (): Promise<FieldHelpElement[]> => {
  const response = await request.get<FieldHelpElement[]>({
    url: `${PATH}/fields-help/get-all`,
  });
  return response.data;
};
