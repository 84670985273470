import React from 'react';
import { Context } from './Context';
import { globalDefaultTheme } from './globalDefaultTheme';
import { PartialGlobalDefaultTheme } from '../../utils/useTheme';
import { globalDefaultRenders, GlobalDefaultRenders } from './globalDefaultRenders';
import { UnderscoreClasses } from './underscoreClasses';
import '../../assets/fonts/font-awesome-5/css/all.min.css';

export interface CuiProps {
  children: React.ReactNode,
  renders?: GlobalDefaultRenders,
  theme?: PartialGlobalDefaultTheme,
  underscoreClassesTransform?: UnderscoreClasses,
}

export const Cui = ((props: CuiProps): React.ReactElement => {
  const {
    theme = globalDefaultTheme,
    renders = globalDefaultRenders,
    underscoreClassesTransform = UnderscoreClasses.NoTransform,
    children,
  } = props;

  const context = { renders, theme, underscoreClassesTransform };

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  );
}) as React.FC<CuiProps>;

export { Context } from './Context';
export { globalDefaultTheme } from './globalDefaultTheme';
export { UnderscoreClasses } from './underscoreClasses';
