import React from 'react';

import * as CUI from '@CUI';
import {useActions} from '@common/store-utils/hooks';

import {actions} from '../model';
import {config} from '../config';
import {LabelWithPopup} from "@common/perach-ui";
import {HelpKeyType} from "@entities/settings";

type InputFieldProps = CUI.InputTypes.InputProps & { label: string };

/**
 * Registration input field
 * @returns React.FC
 * */
export const RegistrationInputField: React.FC<InputFieldProps> = ({
                                                                    label,
                                                                    ...restProps
                                                                  }) => {
  const {autoSaveRegistration} = useActions(actions);
  return (
    <>
      <LabelWithPopup label={label} helpKey={restProps.name as HelpKeyType}/>
      <CUI.Input
        _mb40
        form={config.forms.personalData}
        {...restProps}
      />
    </>
  );
};
