import { actions, reducer, selectors, sagas } from './model';
import { config } from './config';
import * as api from './model/api';

export * from './types';

export const userModel = {
  actions,
  reducer,
  config,
  selectors,
  sagas,
  api,
};
