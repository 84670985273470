export enum ServerDate {
  Date = 'yyyy-MM-dd',
}

export enum UiDate {
  Date = 'dd.MM.yyyy',
  WideDate = 'd MMMM yyyy',
  DateTime = 'dd.MM.yyyy, HH:mm',
  Time = 'HH:mm',
}
