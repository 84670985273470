import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { setStoreField } from '@common/store-utils';

import { config } from '../config';
import { ModelState } from '../types';
import { validate } from '@CUI';
import isPasswordValid from '@/utility/validators/pass-validator';
import { CheckEligibilityFormFields } from '@features/CheckEligibilityForm';

const initialState: ModelState = {
  birth_country: '',
  birth_country_parent_one: '',
  birth_country_parent_two: '',
  education_parent_one: '',
  education_parent_two: '',
  culture_group: '',
  saw_survey: false,
  took_survey: false,
  surveyStaticData: {
    countries: [],
    culture_group: [],
    education: [],
  },
};

/**
 * Action to send student survey
 */
const sendStudentSurveyAction = createAction('sendStudentSurveyAction');

/**
 * Action to send student survey
 */
const getSurveyStaticDataAction = createAction('getSurveyStaticDataAction');

export const creatSurveySlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    setState: (state, { payload }: PayloadAction<ModelState>) => {
      const changesObject = Object.keys(payload).map((_k) => ({
        [_k]: payload[_k as keyof ModelState] || '',
      }));
      state = { ...state, ...changesObject };
    },
    setSurveyStaticData: setStoreField('surveyStaticData'),
    setCountry: setStoreField('birth_country'),
    setFirstParentCountry: setStoreField('birth_country_parent_one'),
    setSecondParentCountry: setStoreField('birth_country_parent_two'),
    setFirstParentEducation: setStoreField('education_parent_one'),
    setSecondParentEducation: setStoreField('education_parent_two'),
    setSpecialCultureGroup: setStoreField('culture_group'),
    setSawSurvey: setStoreField('saw_survey'),
    setTookSurvey: setStoreField('took_survey'),
    reset: () => initialState,
  },
});

export const { reducer } = creatSurveySlice;

export const actions = {
  ...creatSurveySlice.actions,
  sendStudentSurveyAction,
  getSurveyStaticDataAction,
};
