/**
 * Model configuration
 */
export const config = {
  modelName: 'registration',
  forms: {
    personalData: 'personal-data',
    bankDetails: 'bank-details',
    additionalData: 'additional-data',
    documents: 'documents',
  },
  autoSaveInterval: 10000,
} as const;
