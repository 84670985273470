import { DicRecord } from '@entities/dictionaries/types';

export function fixValue<T = string>(value: DicRecord<T>, list: DicRecord<T>[]): DicRecord<T> | null {
  if (!!value?.value && !!value?.text) {
    return value;
  }
  if (!!value.value && !value?.text) {
    return (
      list?.find((el) => el.value === value?.value) || { value: '' as unknown as T, text: '' }
    );
  }
  return null;
};
