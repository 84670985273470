import React from 'react';
import { useTranslation } from 'react-i18next';

import { OtpInputField } from '../../common/perach-ui/OtpField';
import { useActions } from '../../common/store-utils/hooks';
import {actions, selectors} from './model';
import {Optional} from "@common/ui";
import {Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/pro-regular-svg-icons";
import * as CUI from "@CUI";

/**
 * Check eligibility phone confirmation
 * @returns React.FC
 * */
export const CheckEligibilityOtp: React.FC = () => {
  const { t } = useTranslation();
  const { setOtp, sendOtpCode, resendOtpCode } = useActions(actions);
  const serverValidationErrors = selectors.useServerValidationErrors();

  return (
    <>
      <div className="circle-container check center">
        <i className="fa fa-check" />
      </div>
      <div className="page-title eligibility">
        {t('TITLE_CHECK_ELIGIBILITY_SUCCESS')}
      </div>
      <div className="page-subtitle">{t('OTP_SUBTITLE')}</div>
      <Optional when={!!serverValidationErrors?.length}>
        <Row>
          <Col>
            <div className="error-box inline">
              <FontAwesomeIcon icon={faCircleExclamation} className="ml-16" />
              <span>
                  {serverValidationErrors &&
                    serverValidationErrors.length > 0 &&
                    serverValidationErrors.map((e) => (
                      <React.Fragment key={CUI.utils.generateId()}>
                        {e}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
            </div>
          </Col>
        </Row>
      </Optional>
      <div className={'eligibility-opt-container'}>
        <OtpInputField
          onSendAgain={resendOtpCode}
          onConfirm={sendOtpCode}
          onSetValue={setOtp}
        />
      </div>
    </>
  );
};
