import {
  createModelSelector,
  createHookSelector,
  createModelFieldSelector,
} from '@common/store-utils';

import { config } from '../config';
import { ModelState } from '../types';

/**
 * Selector of model State
 */
const modelSelector = createModelSelector<ModelState>(config.modelName);

/**
 * Selector builder of model State field
 */
const fieldSelector = createModelFieldSelector<ModelState>(config.modelName);

/**
 * Selector - User email for login
 */
const emailSelector = fieldSelector('email');

/**
 * Selector - User password for login
 */
const passwordSelector = fieldSelector('password');

/**
 * Selector - User phone for login
 */
const phoneSelector = fieldSelector('phone');

/**
 * Selector - User Id for login
 */
const idSelector = fieldSelector('id');

/**
 * Selector - User OTP code
 */
const otpCodeSelector = fieldSelector('otpCode');

/**
 *
 * Selector - User OTP code
 */
const newPasswordSelector = fieldSelector('newPassword');
/**
 *
 * Selector - User OTP code
 */
const isResetPasswordSuccessSelector = fieldSelector('isResetPasswordSuccess');

const notExistUserValidationSelector = fieldSelector('notExistUserValidation');

export const selectors = {
  registration: modelSelector,
  email: emailSelector,
  password: passwordSelector,
  phone: phoneSelector,
  otpCode: otpCodeSelector,
  newPassword: newPasswordSelector,
  isResetPasswordSuccess: isResetPasswordSuccessSelector,
  notExistUserValidation: notExistUserValidationSelector,

  useLoginData: createHookSelector(modelSelector),
  useEmail: createHookSelector(emailSelector),
  usePassword: createHookSelector(passwordSelector),
  usePhone: createHookSelector(phoneSelector),
  useId: createHookSelector(idSelector),
  useOtpCode: createHookSelector(otpCodeSelector),
  useNewPassword: createHookSelector(newPasswordSelector),
  useIsResetPasswordSuccess: createHookSelector(isResetPasswordSuccessSelector),
  useNotExistUserValidation: createHookSelector(notExistUserValidationSelector),
};
