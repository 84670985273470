import { request } from '../../../common/request';

import {
  SendPersonalInfoResponse,
  BankDetailsRequest,
  BankDetailsResponse,
  AdditionalInfoRequest,
  AdditionalInfoResponse,
  PersonalInfoRequest,
  PersonalInfoResponse,
  RegistrationPrefilledDataResponse,
  UserPersonalDataResponse,
  getUserDocumentsResponse,
  DocumentsRequest,
  BackToFixResponse,
} from '../types';

const PATH = `${process.env.REACT_APP_API_URL}/core-service/api`;

/**
 * Api GET request for getting User information
 * @returns {void}
 */
export const getPrefilledValuesInfo = async () => {
  const { data } = await request.get<RegistrationPrefilledDataResponse>({
    url: `${PATH}/user/current-user`,
  });
  return data;
};

/**
 * Api GET request for getting User information
 * @returns {void}
 */
export const getUserEligibilityDataApi = async () => {
  const { data } = await request.get<BankDetailsRequest>({
    url: `${PATH}/step-1/bank/by-user`,
  });
  return data;
};

/**
 * Api GET request for getting User information
 * @returns {void}
 */
export const getUserBankDetails = async () => {
  const { data } = await request.get<BankDetailsRequest>({
    url: `${PATH}/step-1/bank/by-user`,
  });
  return data;
};

/**
 * Api GET request for getting User information
 * @returns {void}
 */
export const getUserPersonalInfo = async () => {
  const { data } = await request.get<UserPersonalDataResponse>({
    url: `${PATH}/step-1/by-user`,
  });
  return data;
};

/**
 * Api GET request for getting User information
 * @returns {void}
 */
export const getUserAditionalInfo = async () => {
  const { data } = await request.get<AdditionalInfoResponse>({
    url: `${PATH}/step-3/by-user`,
  });
  return data;
};

/**
 * Api GET request for getting User information
 * @returns {void}
 */
export const getUserDocuments = async () => {
  const { data } = await request.get<getUserDocumentsResponse>({
    url: `${PATH}/step-4/documents`,
  });
  return data;
};

/**
 * Api POST request for saving user personal information
 * @returns {void}
 */
export const savePersonalInfo = async (
  userData: PersonalInfoRequest,
  processId: string,
  submit?: boolean
): Promise<PersonalInfoResponse> => {
  const { data } = await request.post<PersonalInfoResponse>({
    url: `${PATH}/step-1/${submit ? 'submit' : 'save'}`,
    data: { ...userData },
  });
  return data;
};

/**
 * Api POST request for saving user bank details
 * @returns {void}
 */
export const sendBankDetails = async (
  userData: BankDetailsRequest,
  processId: string,
  submit?: boolean
): Promise<BankDetailsResponse> => {
  const { data } = await request.post<BankDetailsResponse>({
    url: `${PATH}/step-1/bank/${submit ? 'submit' : 'save'}`,
    data: { ...userData },
  });
  return data;
};

/**
 * Api POST request for saving users Additional info
 * @returns {void}
 */
export const sendAdditionalInfo = async (
  userData: AdditionalInfoRequest,
  processId: string,
  submit?: boolean
): Promise<AdditionalInfoResponse> => {
  const { data } = await request.post<AdditionalInfoResponse>({
    url: `${PATH}/step-3/${submit ? 'submit' : 'save'}`,
    data: { ...userData },
  });
  return data;
};

/**
 * Api POST request for saving users Documents
 * @returns {void}
 */
export const sendUserDocumentsApi = async (
  userData: DocumentsRequest,
  processId: string,
  submit?: boolean
): Promise<any> => {
  const { data } = await request.post<any>({
    url: `${PATH}/step-4/${submit ? 'submit' : 'save'}`,
    data: { ...userData },
  });
  return data;
};

export const sendEmailToStudent = async () => {
  const { data } = await request.post({
    url: `${PATH}/step-4/send-email-to-student`,
  });
  return data;
};

/**
 * Api GET request for getting user documents to be fixed
 * @returns {void}
 */
export const getUserDocumentsToFix = async () => {
  const { data } = await request.get<BackToFixResponse>({
    url: `${PATH}/step-4/get-all-documents-repair-back-for`,
  });
  return data;
};

/**
 * Api GET request for getting user documents to be fixed
 * @returns {void}
 */
export const sendDocumentsToFix = async () => {
  const { data } = await request.post({
    url: `${PATH}/step-4/submit-corrections`,
  });
  return data;
};

