import {
  PayloadAction,
  createAction,
  PayloadActionCreator,
} from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { Saga, SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { SagaWithParameters } from './types';
import { PartialState } from './types';

export const createActionTypeWithPrefix =
  (prefix: string) =>
  (type: string): string =>
    `${prefix}/${type}`;

/**
 * Generates action creator with prefix
 * @param {string} prefix - prefix
 * @returns {PayloadActionCreator} action creator for action creator with prefix
 */
export const createActionCreatorWithPrefix =
  (prefix: string) =>
  <T = void>(type: string): PayloadActionCreator<T> =>
    createAction<T>(createActionTypeWithPrefix(prefix)(type));

/**
 * Sets store field inside module
 * @param {TField} field - field name
 * @returns {void} reducer for changing store value
 */
export const setStoreField =
  <TState, TField extends keyof TState>(field: TField) =>
  (
    state: TState,
    { payload }: PayloadAction<NonNullable<TState[TField]>>
  ): void => {
    // eslint-disable-next-line no-param-reassign
    state[field] = payload;
  };

/**
 * Creates model selector
 * @param {string} modelName - Model name
 * @returns {function} model selector
 */
export function createModelSelector<S>(modelName: string) {
  return (state: PartialState<typeof modelName, S>): S => state[modelName];
}

/**
 * Creates model field selector
 * @param {string} modelName - model name
 * @returns {function} model field selector
 */
export function createModelFieldSelector<S>(modelName: string) {
  return <TField extends keyof S>(field: TField, defaultValue?: S[TField]) =>
    (state: PartialState<typeof modelName, S>): PartialState<string, S>[string][TField] | undefined => {
      const result = state[modelName]?.[field];
      return defaultValue !== undefined ? result ?? defaultValue : result;
    };
}

/**
 * Creates hook for selector
 * @param selector - selector
 * @returns hook
 */
export function createHookSelector<TState, TSelected = unknown>(
  selector: (state: TState) => TSelected
) {
  return (): TSelected => useSelector(selector);
}

/**
 * Тип функции преобразующей первый параметр саги из
 * простого аргумента в payload экшена
 */
type PassPayloadParam = <TSaga extends Saga>(
    saga: TSaga,
) => (
    action: PayloadAction<Parameters<TSaga>[0]>,
) => SagaIterator<ReturnType<TSaga>>;

/**
 * Преобразует первый параметр саги из простого аргумента в payload экшена
 *
 * @example
 * function* foo(bar: string): SagaIterator
 *    =\> function* foo(action: PayloadAction<string>): SagaIterator
 *
 * @param saga - Целевая сага
 * @returns Сага принимающая экшен
 */
export const passActionPayload: PassPayloadParam = (saga) =>
    function* newSaga({ payload }) {
      return yield call(saga, ...([payload] as Parameters<typeof saga>));
    };

type Safe = <TSaga extends Saga>(saga: TSaga) => SagaWithParameters<TSaga>;

/**
 * Безопасная сага
 *
 * @param saga - Целевая сага
 * @returns  новая сага
 */
export const safe: Safe = (saga) =>
    function* newSaga(...args) {
      try {
        return yield call(saga, ...args);
      } catch {
        return undefined;
      }
    };

/**
 * Композиция саг
 *
 * @param target целевая сага
 * @param composers композиторы
 *
 * @returns новая сага
 */
export function composeSaga<TSaga extends Saga, R extends Saga>(
    target: TSaga,
    composers: [(saga: TSaga) => R, ...((saga: TSaga) => Saga)[]],
): R {
  let result: any = target;

  composers.reverse().forEach((composer) => {
    result = composer(result);
  });

  return result;
}
