import { request } from '../../../common/request';

import {
  AcademicInstitutionType,
  CitiesType,
  SectorType,
  StudyFieldsType,
} from '../types';

const PATH = `${process.env.REACT_APP_API_URL}/core-service/api`;

export const getAcademicApi = async (): Promise<AcademicInstitutionType> => {
  const { data } = await request.get<AcademicInstitutionType>({
    url: `${PATH}/academic/get-all`,
  });
  return data;
};

export const getStudyFieldsApi = async (): Promise<StudyFieldsType> => {
  const { data } = await request.get<StudyFieldsType>({
    url: `${PATH}/study-fields/get-all`,
  });
  return data;
};

export const getCitiesApi = async (): Promise<CitiesType> => {
  const { data } = await request.get<CitiesType>({
    url: `${PATH}/city/get-all`,
  });
  return data;
};

export const getSectorsApi = async (): Promise<SectorType> => {
  const { data } = await request.get<SectorType>({
    url: `${PATH}/sector/get-all`,
  });
  return data;
};

export const getBanksApi = async (): Promise<SectorType> => {
  const { data } = await request.get<SectorType>({
    url: `${PATH}/bank-brand/get-all`,
  });
  return data;
};

export const getDistrictsApi = async (city: string): Promise<SectorType> => {
  const { data } = await request.get<SectorType>({
    url: `${PATH}/neighborhood/get-all/${city}`,
  });
  return data;
};
