import React from 'react';

type OptionalProps = {
  /** condition */
  when: boolean;
};

/**
 * Component with optional rendering
 * @param props
 * @returns component
 */

export const Optional: React.FC<OptionalProps> = ({ when, children }) =>
  when ? <>{children}</> : null;
