import { request } from '../../../common/request';
import { SurveyStaticData } from '@features/StudentSurvey';

const PATH = `${process.env.REACT_APP_API_URL}/core-service/api`;

export const getUserSurveyInitialDetails = async (): Promise<any> => {
  return request
    .get<any>({
      url: `${PATH}/sector-survey/get-sector-survey`,
    })
    .then((res) => res)
    .catch((err) => {
      return false;
    });
};

export const createNewSurvey = async (surveyData: {}): Promise<any> => {
  const { data } = await request.post<any>({
    url: `${PATH}/sector-survey/update-sector-survey`,
    data: surveyData,
  });
  return data;
};

export const getStaticData = async (): Promise<SurveyStaticData> => {
  const { data } = await request.get<SurveyStaticData>({
    url: `${PATH}/sector-survey/get-static-data`,
  });
  return data;
};

//
// export const createNewSurvey = (surveyData:{}): Promise<any> => {
//   return request.post<any>({
//     url: `${PATH}/studentSurvey`,
//   });
// };
