import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { actions, selectors } from '../model';
import { ModelState } from '@features/PersonalArea/types';
import {
  createAppeal,
  CreateAppealRequest,
  getUserDocumentsApi,
  getUserAppealDocumentsApi,
  returnBankFromFixApi,
  returnAppealFromCorrectionApi,
  getUserRequestDataApi,
  getPdfApi,
  getUserRequestScoreApi,
  getInvalidDocumentsApi,
  getUserAppealApi,
  updateAppealApi,
  fixBankDetailsApi,
} from '@features/PersonalArea/model/api';

import { userModel } from '@entities/user';

import {
  mapEditedDataFromServerToUi,
  mapUserDocumentsFromServerToUI,
} from '@features/PersonalArea/mapper';
import { push } from 'react-router-redux';
import { globalConfig } from '@/globalConfig';
import { loaderModel } from '@entities/loader';
import { personalInfoModel } from '@features/Registration';
import { sendBankDetails } from '@features/Registration/model/api';
import { mapBankDetailsFromUiToServer } from '@features/Registration/mapper';
import { composeSaga, passActionPayload } from '@common/store-utils';
import saveAs from 'file-saver';
import { settingsModel } from '@entities/settings';

function* sendAppealForm(): SagaIterator {
  const { documents, details }: ModelState = yield select(selectors.appeal);
  const requestId = yield select(userModel.selectors.requestId);
  const isAppealsOpen = yield select(settingsModel.selectors.isAppealsOpen);
  const appealType = yield select(selectors.appealType);
  const shouldDisableAppeals = !isAppealsOpen;
  const createAppealRequest: CreateAppealRequest = {
    user_request_id: requestId,
    details,
    documents: JSON.stringify(documents),
    type: appealType,
  };
  yield put(loaderModel.actions.setIsLoading(true));

  try {
    if (shouldDisableAppeals) {
      yield put(actions.setIsAppealSentSuccess(false));
    } else {
      const response = yield call(createAppeal, createAppealRequest);
      yield put(actions.setIsAppealSentSuccess(false));
      if (!!response) {
        yield put(actions.setIsAppealSent(true));
        yield put(actions.setIsAppealSentSuccess(true));
        yield put(push(globalConfig.routes.personalArea()));
      }
    }
  } catch (e) {
    yield put(actions.setIsAppealSentSuccess(false));
    yield put(loaderModel.actions.setIsLoading(false));
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

/**
 * Get user documents Saga
 * @returns {void}
 */
function* getUserDocumentsListSaga(): SagaIterator {
  const response = yield call(getUserDocumentsApi);
  if (response) {
    yield put(
      actions.setUserDocuments(mapUserDocumentsFromServerToUI(response))
    );
  }
}

function* getAppealDocumentsToFixSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  const userStatus = yield select(userModel.selectors.requestStatus);
  try {
    if (!!userStatus && userStatus === 'APPEAL_REPAIR_BACK_FOR') {
      try {
        const userData = yield call(getUserAppealDocumentsApi);
        yield put(actions.setAppealDocumentsBackToFix(userData));
      } catch (e) {
        console.log(e);
      }
      const appeal = yield call(getUserAppealApi);
      yield put(actions.setAppealReturnComment(appeal?.return_comment || ''));
      yield put(actions.setAppealOriginalDocuments(appeal?.documents || []));
      yield put(actions.setAppealType(appeal?.type || ''));
      yield put(actions.setDetails(appeal?.details || ''));
    }
  } catch (e) {
    yield put(loaderModel.actions.setIsLoading(false));
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

function* getUserRequestDetailsSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  try {
    const requestData = yield call(getUserRequestDataApi);
    const requestScore = yield call(getUserRequestScoreApi);
    yield put(actions.setRequestData(mapEditedDataFromServerToUi(requestData)));
    yield put(actions.setDetailedScore(requestScore));
  } catch (e) {
    yield put(loaderModel.actions.setIsLoading(false));
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

function* getUserInvalidDocumentsSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  try {
    const invalidDocuments = yield call(getInvalidDocumentsApi);
    yield put(actions.setInvalidDocuments(invalidDocuments));
  } catch (e) {
    yield put(loaderModel.actions.setIsLoading(false));
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

function* getPdfSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  try {
    const response = yield call(getPdfApi);
    const file = new Blob([response]);
    yield call(saveAs, file, 'milgo.pdf');
  } catch (e) {
    yield put(loaderModel.actions.setIsLoading(false));
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

function* fixBankDetailsSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  const userData = yield select(personalInfoModel.selectors.bankDetails);
  const processId = yield select(userModel.selectors.processId);
  const request = yield select(userModel.selectors.requestId);
  const file = yield select(selectors.fixBankFile);
  try {
    const response = yield call(fixBankDetailsApi, {
      ...mapBankDetailsFromUiToServer(userData),
      bank_account_reference: file,
    });

    if (!!response) {
      window.location.reload();
    }
  } catch (e) {
    yield put(actions.setIsChangeBankDetails(false));
    yield put(loaderModel.actions.setIsLoading(false));
  } finally {
    yield put(actions.setIsChangeBankDetails(false));
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

function* returnDocumentsFromCorrectionsSaga(
  props: Record<string, any>
): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  try {
    const response = yield call(returnAppealFromCorrectionApi, props);
    if (!!response) {
      yield put(push(globalConfig.routes.personalArea()));
      yield delay(1000);
      yield call(window.location.reload);
    }
  } catch (e) {
    yield put(loaderModel.actions.setIsLoading(false));
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

function* updateAppealSaga(): SagaIterator {
  const { documents, details, appealType }: ModelState = yield select(
    selectors.appeal
  );

  const updateAppealRequest: Partial<CreateAppealRequest> = {
    details,
    type: !!appealType ? appealType : 'PERSONAL_PARAMETER_CHANGE',
    documents: JSON.stringify(documents),
  };
  yield put(loaderModel.actions.setIsLoading(true));

  try {
    const response = yield call(updateAppealApi, updateAppealRequest);
    yield put(actions.setIsAppealSentSuccess(false));
    if (!!response) {
      yield put(push(globalConfig.routes.main()));
    }
  } catch (e) {
    yield put(loaderModel.actions.setIsLoading(false));
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

function* watcher(): SagaIterator<void> {
  yield all([
    takeEvery(actions.createSendAppealForm, sendAppealForm),
    takeEvery(actions.getPdf, getPdfSaga),
    takeEvery(actions.getUserDocumentsList, getUserDocumentsListSaga),
    takeEvery(actions.getAppealDocumentsBackToFix, getAppealDocumentsToFixSaga),
    takeEvery(actions.returnDetailsFromCorrections, fixBankDetailsSaga),
    takeEvery(actions.getRequestData, getUserRequestDetailsSaga),
    takeEvery(actions.getInvalidDocuments, getUserInvalidDocumentsSaga),
    takeEvery(actions.updateAppeal, updateAppealSaga),
    takeEvery(
      actions.returnAppealDocumentsFromCorrections,
      composeSaga(returnDocumentsFromCorrectionsSaga, [passActionPayload])
    ),
  ]);
}

export const sagas = {
  watcher,
};
