export const appStatus = {
  DRAFT: 'טיוטה',
  SUBMITTED: 'הוגשה',
  DENIED: 'נדחה',
  TEAM_MANAGER_APPROVAL: 'אישור מנה"צ',

  COMPUTED: 'חושבה',
  SCHOLARSHIP_PAID: 'מלגה שולמה',

  WAITING_TO_BE_COMPUTED: 'מחכה לחישוב',
  REPAIR_BACK_FOR: 'הוחזרה לתיקון',
  REPAIR_BACK_FROM: 'הוחזרה מתיקון',
  REPAIR_BACK_FROM_TO: 'הוחזרה מתיקון T/O',

  APPEAL_SUBMITTED: 'ערעור הוגש',
  APPEAL_CALCULATED: 'בקשה חושבה לאחר תהליך ערעור',
  APPEAL_PAID: 'מלגה שולמה לאחר תהליך ערעור',
  APPEAL_DENIED: 'ערעור נדחה',
  // APPEAL_DENIED: 'ערעור בטיפול',
  APPEAL_WAITING_CALCULATED: 'בקשה מחכה לחישוב לאחר תהליך ערעור',
  APPEAL_APPROVED: 'ערעור אושר',
  // APPEAL_APPROVED: 'ערעור בטיפול',

  INVALID_BANK: 'פרטי בנק שגוים',
  INVALID_BANK_BACK_FOR_REPAIR: 'הוחזרה לתיקון פרטי בנק',
  INVALID_BANK_BACK_FROM_REPAIR: 'הוחזרה מתיקון פרטי בנק',
  INVALID_BANK_FIXED: 'פרטי בנק תוקנו',
  APPEAL_REPAIR_BACK_FOR: 'ערעור הוחזר לתיקון',
  APPEAL_REPAIR_BACK_FROM: 'ערעור הוחזר מתיקון',

  REJECTED_BY_ACADEMIC_INSTITUTION: 'נפסל על ידי מוסד לימודים',

  UI_PROGRESS: 'בטיפול',
  UI_APPEAL_PROGRESS: 'ערעור בטיפול',
};

export const statusesList = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  DENIED: 'DENIED',
  TEAM_MANAGER_APPROVAL: 'TEAM_MANAGER_APPROVAL',

  WAITING_TO_BE_COMPUTED: 'WAITING_TO_BE_COMPUTED',
  REPAIR_BACK_FOR: 'REPAIR_BACK_FOR',
  REPAIR_BACK_FROM: 'REPAIR_BACK_FROM',
  REPAIR_BACK_FROM_TO: 'REPAIR_BACK_FROM_TO',

  COMPUTED: 'COMPUTED',
  SCHOLARSHIP_PAID: 'SCHOLARSHIP_PAID',

  APPEAL_SUBMITTED: 'APPEAL_SUBMITTED',
  APPEAL_CALCULATED: 'APPEAL_CALCULATED',
  APPEAL_PAID: 'APPEAL_PAID',
  APPEAL_DENIED: 'APPEAL_DENIED',
  APPEAL_WAITING_CALCULATED: 'APPEAL_WAITING_CALCULATED',

  INVALID_BANK: 'INVALID_BANK',
  INVALID_BANK_BACK_FOR_REPAIR: 'INVALID_BANK_BACK_FOR_REPAIR',
  INVALID_BANK_REPAIR_BACK_FROM: 'INVALID_BANK_REPAIR_BACK_FROM',
  INVALID_BANK_FIXED: 'INVALID_BANK_FIXED',

  REJECTED_BY_ACADEMIC_INSTITUTION: 'REJECTED_BY_ACADEMIC_INSTITUTION',
};
