import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { globalConfig } from '@/globalConfig';
import { userModel } from '@entities/user';
import { dictionariesModel } from '@entities/dictionaries';

import {
  mapBankDetailsFromUiToServer,
  mapPersonalInfoFromUiToServer,
  mapAdditionalInfoFromUiToServer,
  mapBankDetailsFromServerToUi,
  mapUserPersonalInfoFromServerToUi,
  mapDocumentsFromUiToServer,
  mapPrefilledDocumentsFromServerToUI,
  mapPrefilledDocumentsFromServerToStore,
  mapAdditionalInfoFromServerToUi,
} from '../mapper';
import {
  AdditionalInfoResponse,
  BankDetailsResponse,
  ModelState,
  RegistrationPrefilledDataResponse,
  SendPersonalInfoResponse,
  WizardSteps,
} from '../types';
import {
  sendBankDetails,
  savePersonalInfo,
  sendAdditionalInfo,
  getPrefilledValuesInfo,
  getUserBankDetails,
  getUserPersonalInfo,
  getUserDocuments,
  sendUserDocumentsApi,
  getUserAditionalInfo,
  sendEmailToStudent as sendEmailToStudentApi,
  sendDocumentsToFix,
  getUserDocumentsToFix,
} from './api';

import { actions } from './ducks';
import { selectors } from './selectors';
import { loaderModel } from '@entities/loader';
import { personalInfoModel } from '@features/Registration';

/**
 * Prefill user personal data
 * @returns {void}
 */
function* prefillPersonalDataSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  try {
    yield put(loaderModel.actions.setIsLoading(true));
    const userPersonalData = yield call(getUserPersonalInfo);
    if (!!userPersonalData) {
      yield put(
        actions.updatePersonalInfo({
          withNoChangesMark: true,
          changes: {
            ...mapUserPersonalInfoFromServerToUi(userPersonalData),
          },
        })
      );
    }
    yield put(loaderModel.actions.setIsLoading(true));
    const bankDetails = yield call(getUserBankDetails);
    if (!!bankDetails) {
      yield put(
        actions.updateBankDetails({
          withNoChangesMark: true,
          changes: {
            ...mapBankDetailsFromServerToUi(bankDetails),
          },
        })
      );
    }

    yield put(loaderModel.actions.setIsLoading(true));
    const additionalInfo = yield call(getUserAditionalInfo);
    if (!!additionalInfo) {
      yield put(
        actions.updateAdditionalInfo({
          withNoChangesMark: true,
          changes: {
            ...mapAdditionalInfoFromServerToUi(additionalInfo),
          },
        })
      );
    }

    yield put(loaderModel.actions.setIsLoading(true));
    const documents = yield call(getUserDocuments);
    if (!!documents) {
      yield put(
        actions.updatePrefillDocuments({
          withNoChangesMark: true,
          changes: {
            ...mapPrefilledDocumentsFromServerToUI(documents),
          },
        })
      );
      yield put(
        actions.updateDocuments({
          withNoChangesMark: true,
          changes: {
            ...mapPrefilledDocumentsFromServerToStore(documents),
          },
        })
      );
    }
  } catch (e) {
    console.log('Preffiled error', e);
  }

  const userData = yield select(selectors.personalInfo);

  yield put(loaderModel.actions.setIsLoading(true));
  try {
    const response = yield call(getPrefilledValuesInfo);
    if (response) {
      yield put(
        actions.updatePersonalInfo({
          withNoChangesMark: true,
          changes: {
            userId: response.identity_number || userData.userId,
            phone: response.phone || userData.phone,
            // email: response.email || userData.email,
            educationDetails: {
              ...userData.educationDetails,
              isBachelor: response.currently_studying,
            },
          },
        })
      );
      yield put(
        userModel.actions.setIsSponsored(
          response?.academic_institution?.is_sponsored || false
        )
      );
      yield put(
        userModel.actions.setRequestStatus(
          response?.request?.request_status || ''
        )
      );
      yield put(
        userModel.actions.setPublishedState(
          response?.request?.publish_state || ''
        )
      );
      yield put(
        userModel.actions.setRequestDate(response?.request?.created_at || '')
      );
    }
  } catch (e) {
    yield put(loaderModel.actions.setIsLoading(false));
    if (
      e.response?.data?.error?.statusCode === 404 ||
      e.response?.data?.error?.statusCode === 401
    ) {
      yield put(push(globalConfig.routes.notFound()));
    }
    console.log('Preffiled error', e);
  }
  yield put(loaderModel.actions.setIsLoading(false));
}

/**
 * Send user personal data
 * @returns {void}
 */
function* submitUserPersonalDataSaga(): SagaIterator {
  const userData = yield select(selectors.personalInfo);
  yield put(loaderModel.actions.setIsLoading(true));
  const processId = yield select(userModel.selectors.processId);
  const isSponsored = yield select(userModel.selectors.isSponsored);
  yield put(actions.setValidationErrors([]));

  try {
    const response: SendPersonalInfoResponse = yield call(
      savePersonalInfo,
      mapPersonalInfoFromUiToServer(userData),
      processId,
      true
    );
    if (response?.id) {
      yield put(
        actions.setStep(isSponsored ? WizardSteps.THREE : WizardSteps.TWO)
      );
    }
    if (response?.error?.statusCode === 250) {
      // @ts-ignore
      const message = response?.error?.message || [];
      yield put(
        actions.setValidationErrors(
          Array.isArray(message) ? message : [message]
        )
      );
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

/**
 * Send user personal data
 * @returns {void}
 */
function* submitUserBankDetailsSaga(): SagaIterator {
  const userData = yield select(selectors.bankDetails);
  const processId = yield select(userModel.selectors.processId);
  yield put(loaderModel.actions.setIsLoading(true));
  yield put(actions.setValidationErrors([]));

  try {
    const response: BankDetailsResponse = yield call(
      sendBankDetails,
      mapBankDetailsFromUiToServer(userData),
      processId,
      true
    );
    if (response) {
      yield put(actions.setStep(WizardSteps.THREE));
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

/**
 * Send user additional personal data
 * @returns {void}
 */
function* sendUserAdditionalDataSaga(): SagaIterator {
  const userData = yield select(selectors.additionalInfo);
  const processId = yield select(userModel.selectors.processId);
  yield put(loaderModel.actions.setIsLoading(true));
  yield put(actions.setValidationErrors([]));

  try {
    const response: AdditionalInfoResponse = yield call(
      sendAdditionalInfo,
      mapAdditionalInfoFromUiToServer(userData),
      processId,
      true
    );
    if (response?.id) {
      yield call(getDocumentsSaga);
      yield put(actions.setStep(WizardSteps.FOUR));
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

/**
 * Send user additional personal data
 * @returns {void}
 */
function* sendUserDocumentsSaga(): SagaIterator {
  const userData = yield select(selectors.documents);
  const processId = yield select(userModel.selectors.processId);
  yield put(loaderModel.actions.setIsLoading(true));
  yield put(actions.setChangedMark(false));
  yield put(actions.setValidationErrors([]));

  try {
    const response: AdditionalInfoResponse = yield call(
      sendUserDocumentsApi,
      mapDocumentsFromUiToServer(userData),
      processId,
      true
    );

    if (
      response?.error?.statusCode === 250 ||
      response?.error?.statusCode === 400
    ) {
      const message = response?.error?.message || ['שגיאה לא מוגדרת'];
      yield put(
        actions.setValidationErrors(
          Array.isArray(message) ? message : [message]
        )
      );
      yield put(actions.setResultModalOpen(true));
    } else {
      yield put(actions.setValidationErrors([]));
      yield put(actions.setResultModalOpen(true));
      // yield put(userModel.actions.setIsShowSurveyFromRegistration(true));
      // yield put(push(globalConfig.routes.personalArea()));
    }
  } catch (e) {
    console.log(e);
    if (
      e.response?.data?.error?.statusCode === 250 ||
      e.response?.data?.error?.statusCode === 400
    ) {
      const message = e.response?.data?.error?.message || ['שגיאה לא מוגדרת'];
      yield put(
        actions.setValidationErrors(
          Array.isArray(message) ? message : [message]
        )
      );
      yield put(actions.setResultModalOpen(true));
    }
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

function* submitRequestConfirmSaga(): SagaIterator {
  yield put(actions.setResultModalOpen(false));
  yield put(userModel.actions.setIsShowSurveyFromRegistration(true));
  yield put(push(globalConfig.routes.personalArea()));
}

/**
 * Auto-save registration form data
 * @returns {void}
 */
function* autoSaveRegistrationFormSaga(): SagaIterator {
  const {
    changed,
    currentStep,
    personalInfo,
    bankDetails,
    additionalInfo,
    documents,
  }: ModelState = yield select(selectors.registration);

  const processId = yield select(userModel.selectors.processId);
  const isSomethingLoading = yield select(loaderModel.selectors.isLoading);
  const isAdmin = window.location.pathname.includes('/admin/');

  // if (!!isAdmin) {
  //   return;
  // }

  if (!!isSomethingLoading) {
    return;
  }

  if (!changed) {
    return;
  }

  yield put(actions.setValidationErrors([]));

  if (currentStep === WizardSteps.ONE) {
    try {
      const response = yield call(
        savePersonalInfo,
        mapPersonalInfoFromUiToServer(personalInfo),
        processId
      );
      if (response?.error?.statusCode === 250) {
        const message = response?.error?.message || [];
        yield put(
          actions.setValidationErrors(
            Array.isArray(message) ? message : [message]
          )
        );
      }
    } catch (e) {
      const message = e?.response?.data?.error?.message || [];
      console.log(message);
    }
  }
  if (currentStep === WizardSteps.TWO) {
    yield call(
      sendBankDetails,
      mapBankDetailsFromUiToServer(bankDetails),
      processId
    );
  }
  if (currentStep === WizardSteps.THREE) {
    yield call(
      sendAdditionalInfo,
      mapAdditionalInfoFromUiToServer(additionalInfo),
      processId
    );
  }
  if (currentStep === WizardSteps.FOUR) {
    yield call(
      sendUserDocumentsApi,
      mapDocumentsFromUiToServer(documents),
      processId
    );
  }

  yield put(actions.setChangedMark(false));
}

/**
 * send email to student
 * @returns {void}
 */
function* sendEmail(): SagaIterator {
  const response = yield call(sendEmailToStudentApi);
}

/**
 * send email to student
 * @returns {void}
 */
function* getCurrentUser(): SagaIterator {
  try {
    const userData = yield call(getPrefilledValuesInfo);
    yield put(
      userModel.actions.setRequestStatus(
        userData?.request?.request_status || ''
      )
    );
    yield put(
      userModel.actions.setPublishedState(
        userData?.request?.publish_state || ''
      )
    );
    yield put(userModel.actions.setRequestId(userData?.request?.id || ''));
    yield put(
      userModel.actions.setRequestDate(userData?.request?.created_at || '')
    );
  } catch (e) {
    console.log(e);
  }
}

function* getUserData(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  const jwt = yield call(
    [window.localStorage, window.localStorage.getItem],
    globalConfig.storage.tokenKey
  );
  if (!!jwt) {
    const userData = yield call(getPrefilledValuesInfo);
    yield put(
      userModel.actions.setRequestStatus(
        userData?.request?.request_status || ''
      )
    );
    yield put(
      userModel.actions.setPublishedState(
        userData?.request?.publish_state || ''
      )
    );
    yield put(userModel.actions.setRequestId(userData?.request?.id || ''));
    yield put(
      userModel.actions.setRequestDate(userData?.request?.created_at || '')
    );
  }
  yield put(loaderModel.actions.setIsLoading(false));
}

function* pingIframeSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  const jwt = yield call(
    [window.localStorage, window.localStorage.getItem],
    globalConfig.storage.tokenKey
  );
  const userId = yield call(
    [window.localStorage, window.localStorage.getItem],
    globalConfig.storage.userId
  );
  const isAdmin = yield call(
    [window.localStorage, window.localStorage.getItem],
    globalConfig.storage.isAdmin
  );
  if (!!jwt && !!isAdmin && !!userId) {
    try {
      const userData = yield call(getPrefilledValuesInfo);
      yield put(userModel.actions.setIsLoggedIn(true));
      yield put(dictionariesModel.actions.getDictionaries());
    } catch (e) {
      console.log('error accessing data');
      //TODO: Temp fix - find normal way
      window.location.reload();
    } finally {
      yield put(loaderModel.actions.setIsLoading(false));
    }
  }
  yield put(loaderModel.actions.setIsLoading(false));
}

function* getUserDocumentsToFixSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  const userStatus = yield select(userModel.selectors.requestStatus);
  try {
    if (!!userStatus && userStatus === 'REPAIR_BACK_FOR') {
      const userData = yield call(getUserDocumentsToFix);
      yield put(actions.setBackToFix(userData));
    }
  } catch (e) {
    yield put(loaderModel.actions.setIsLoading(false));
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

function* sendDocumentsAfterFixSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  try {
    const processId = yield select(userModel.selectors.processId);
    const documents = yield select(personalInfoModel.selectors.documents);
    const response = yield call(
      sendUserDocumentsApi,
      mapDocumentsFromUiToServer(documents),
      processId
    );
    if (!!response) {
      yield call(sendDocumentsToFix);
      yield put(push(globalConfig.routes.personalArea()));
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

function* getDocumentsSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));
  try {
    yield put(loaderModel.actions.setIsLoading(true));
    const documents = yield call(getUserDocuments);
    if (!!documents) {
      yield put(
        actions.updatePrefillDocuments({
          withNoChangesMark: true,
          changes: {
            ...mapPrefilledDocumentsFromServerToUI(documents),
          },
        })
      );
      yield put(
        actions.updateDocuments({
          withNoChangesMark: true,
          changes: {
            ...mapPrefilledDocumentsFromServerToStore(documents),
          },
        })
      );
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

/**
 * Watcher
 * @returns {void}
 */
function* watcher(): SagaIterator<void> {
  yield all([
    takeEvery(actions.sendEmailToStudent, sendEmail),
    takeEvery(actions.prefillUserPersonalData, prefillPersonalDataSaga),
    takeEvery(actions.saveUserPersonalData, submitUserPersonalDataSaga),
    takeEvery(actions.saveBankDetails, submitUserBankDetailsSaga),
    takeEvery(actions.saveAdditionalData, sendUserAdditionalDataSaga),
    takeEvery(actions.autoSaveRegistration, autoSaveRegistrationFormSaga),
    takeEvery(actions.saveDocumentsData, sendUserDocumentsSaga),
    takeEvery(actions.getUserData, getUserData),
    takeEvery(actions.getUserDocumentsToFix, getUserDocumentsToFixSaga),
    takeEvery(actions.pingIframe, pingIframeSaga),
    takeEvery(actions.sendDocumentsAfterFixes, sendDocumentsAfterFixSaga),
    takeEvery(actions.getDocuments, getDocumentsSaga),
    takeEvery(actions.getCurrentUser, getCurrentUser),
    takeEvery(actions.submitRequestConfirm, submitRequestConfirmSaga),
  ]);
}

export const sagas = {
  watcher,
};
