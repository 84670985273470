import { createSlice } from '@reduxjs/toolkit';

import { setStoreField, createActionCreatorWithPrefix } from '@common/store-utils';
import { config } from './config';

const initialState: { isLoading: boolean } = {
  isLoading: false,
};

export const createLoaderSlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    setIsLoading: setStoreField('isLoading'),

    reset: () => initialState,
  },
});

export const { reducer } = createLoaderSlice;

export const actions = {
  ...createLoaderSlice.actions,
};
