import React from 'react';
import * as CUI from '../../CUI';
import { HelpKeyType, HelpText } from '@entities/settings';

/**
 * Input Label with help mark
 * @returns React.FC
 * */
export const LabelWithAction: React.FC<{
  label: string;
  actionText: string;
  actionHandler: () => void;
  isDanger?: boolean;
  isSuccess?: boolean;
  shouldHideAction?: boolean;
  helpKey?: string;
}> = ({
  label,
  actionText,
  actionHandler,
  isDanger = false,
  isSuccess = false,
  shouldHideAction = false,
  helpKey,
}) => {
  return (
    <CUI.Label _labelPerach>
      <CUI.Div _labelMore>
        <div>{label}</div>
        <CUI.Div
          shouldRender={!shouldHideAction}
          _labelDanger={isDanger}
          _labelSuccess={isSuccess}
          onClick={actionHandler}
        >
          {actionText}
        </CUI.Div>
        <HelpText fieldName={helpKey as HelpKeyType} />
      </CUI.Div>
    </CUI.Label>
  );
};
