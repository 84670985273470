import { reducer, selectors } from './model';
import { config } from './config';

/**
 * Модель router
 */
export const routerModel = {
  config,
  reducer,
  selectors,
};
