import {
  createModelSelector,
  createHookSelector,
  createModelFieldSelector,
} from '../../../common/store-utils';

import { config } from '../config';
import { ModelState } from '../types';
import {
  additionalInfoDefaultValues,
  bankDetailsDefaultValue,
  documentsDefaultValue,
} from '../defaults';

/**
 * Selector of model State
 */
const modelSelector = createModelSelector<ModelState>(config.modelName);

/**
 * Selector builder of model State field
 */
const fieldSelector = createModelFieldSelector<ModelState>(config.modelName);

/**
 * Selector - User personal info for registration
 */
const personalInfoSelector = fieldSelector('personalInfo');

/**
 * Selector - Bank details for registration
 */
const bankDetailsSelector =
  fieldSelector('bankDetails') || bankDetailsDefaultValue;

/**
 * Selector - Addiional info for registration
 */
const additionalInfoSelector =
  fieldSelector('additionalInfo') || additionalInfoDefaultValues;

/**
 * Selector - Documents for registration
 */
const documentsSelector = fieldSelector('documents') || documentsDefaultValue;

/**
 * Selector - Documents for registration
 */
const prefilledDocumentsSelector = fieldSelector('documentsPrefilled');

/**
 * Selector - Current wizard step selector
 */
const currentStepSelector = fieldSelector('currentStep');

/**
 * Selector - State of the alert modal
 */
const isModalOpenSelector = fieldSelector('isModalOpen');

/**
 * Selector - State of the alert modal
 */
const backToFixSelector = fieldSelector('backToFix');

/**
 * Selector - State of the alert modal
 */
const validationErrorSelector = fieldSelector('validationError');

const isResultModalOpenSelector = fieldSelector('isResultModalOpen');

export const selectors = {
  registration: modelSelector,
  personalInfo: personalInfoSelector,
  currentStep: currentStepSelector,
  bankDetails: bankDetailsSelector,
  additionalInfo: additionalInfoSelector,
  documents: documentsSelector,
  validationError: validationErrorSelector,
  usePersonalInfoData: createHookSelector(personalInfoSelector),
  useBankDetails: createHookSelector(bankDetailsSelector),
  useAdditionalInfo: createHookSelector(additionalInfoSelector),
  useDocuments: createHookSelector(documentsSelector),
  useCurrentStep: createHookSelector(currentStepSelector),
  usePrefilledDocuments: createHookSelector(prefilledDocumentsSelector),
  useIsModalOpen: createHookSelector(isModalOpenSelector),
  useBackToFix: createHookSelector(backToFixSelector),
  useValidationError: createHookSelector(validationErrorSelector),
  useIsResultModalOpen: createHookSelector(isResultModalOpenSelector),
};
