import { request } from '../../../common/request';
import { userModel } from '@entities/user';
import { getUserDocumentsResponse } from '@features/Registration';

const PATH = `${process.env.REACT_APP_API_URL}/core-service/api`;

export interface CreateAppealRequest {
  details: string;
  documents?: string;
  type: 'OPERATOR_MISTAKE' | 'PERSONAL_PARAMETER_CHANGE' | '';
  user_request_id: number;
}

export const createAppeal = async (
  createAppealRequest: CreateAppealRequest
) => {
  return request
    .post<any>({
      url: `${PATH}/appeal/create-appeal`,
      data: { ...createAppealRequest },
    })
    .then((response) => response.status)
    .catch((err) => {
      return 400;
    });
};

export const getUserRequestId = async (): Promise<{
  id: number;
  request_number: number;
  denied_reason: string;
  request_status: string;
}> => {
  return await request
    .get({
      url: `${PATH}/user-request`,
    })
    .then((data) => data.data)
    .catch((err) => {
      return null;
    });
};

/**
 * Api GET request for getting User information
 * @returns {void}
 */
export const getUserDocumentsApi = async () => {
  const { data } = await request.get<getUserDocumentsResponse>({
    url: `${PATH}/step-4/documents`,
  });
  return data;
};

export const getInvalidDocumentsApi = async () => {
  const { data } = await request.get<getUserDocumentsResponse>({
    url: `${PATH}/step-4/get-all-invalid-documents`,
  });
  return data;
};

export const getUserAppealDocumentsApi = async () => {
  const { data } = await request.get<getUserDocumentsResponse>({
    url: `${PATH}/appeal/get-appeal-documents`,
  });
  return data;
};

export const getUserRequestDataApi = async () => {
  const { data } = await request.get<any>({
    url: `${PATH}/user-request-details/get-user-request-by-user-id`,
  });
  return data;
};

export const getUserRequestScoreApi = async () => {
  const { data } = await request.get<any>({
    url: `${PATH}/score/get-detailed-user-score-by-user-id`,
  });
  return data;
};

export const getUserAppealApi = async () => {
  const { data } = await request.get<any>({
    url: `${PATH}/appeal/get-appeal/by-user`,
  });
  return data;
};

export const updateAppealApi = async (docs: Partial<CreateAppealRequest>) => {
  const { data } = await request.patch<any>({
    url: `${PATH}/appeal/update-appeal/by-user`,
    data: docs,
  });
  return data;
};

export const returnBankFromFixApi = async (id: string) => {
  const { data } = await request.patch<getUserDocumentsResponse>({
    url: `${PATH}/approval-mahat/change_user_request_status_to_invalid_bank_fixed/${id}`,
  });
  return data;
};

type Props = {
  bank_name: string;
  branch_number: string;
  account_number: string;
  bank_account_reference: string;
};
export const fixBankDetailsApi = async (props: Props) => {
  const { data } = await request.patch<getUserDocumentsResponse>({
    url: `${PATH}/user-request/qqq/fix-bank-details`,
    data: props,
  });
  return data;
};

export const returnAppealFromCorrectionApi = async (
  props: Record<string, any>
) => {
  const { data } = await request.post<getUserDocumentsResponse>({
    url: `${PATH}/appeal/submit-corrections`,
    data: props,
  });
  return data;
};

export const getPdfApi = async (): Promise<any> => {
  const response = await request.get<any>({
    url: `${PATH}/publish/get-result-pdf`,
    config: {
      responseType: 'arraybuffer',
    },
  });
  return response.data;
};
