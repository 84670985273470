import { actions, reducer, selectors, sagas } from './model';
import { config } from './config';

export * from './CheckEligibiltyContainer';
export * from './types';

export const checkEligibilityModel = {
  selectors,
  actions,
  reducer,
  config,
  sagas,
};
