import {
  createModelSelector,
  createHookSelector,
  createModelFieldSelector
} from '../../../common/store-utils';

import {validate} from "../../../CUI";
import {config} from '../config';

import {ModelState} from '../types';
import {createSelector} from "@reduxjs/toolkit";
import isPasswordValid from "../../../utility/validators/pass-validator";
import {validateEmail} from "@/CUI/validators/email";

/**
 * Selector of model State
 */
const modelSelector = createModelSelector<ModelState>(config.modelName);

/**
 * Selector builder of model State field
 */
const fieldSelector = createModelFieldSelector<ModelState>(config.modelName);

/**
 * User Status selector
 */
const formDataSelector = fieldSelector('formData');

/**
 * Is form valid selector
 */
const isValidFormSelector = createSelector(
  formDataSelector,
  (data) => [
    validate.israelId(data?.identityNumber || ''),
    data?.phone && data?.phone.length >= 9 && data?.phone.length <= 10,
    data?.password && isPasswordValid(data?.password),
    data?.passwordConfirmation && data?.password === data?.passwordConfirmation,
    !!(data?.academicInstitution && data?.academicInstitution.value),
    (data?.receivedScholarshipAmount && data?.receivedScholarshipName) ||
    data?.receivedScholarship === false,
    Object.values(data?.scholarships || {}).some(Boolean),
    !!(data?.termsConfirm),
    data?.email === data?.emailConfirm,
    validateEmail(data?.email || ''),
  ].every(Boolean)
);

/**
 * User Status selector
 */
const userStatusSelector = fieldSelector('status');

/**
 * User Status selector
 */
const serverValidationErrorsSelector = fieldSelector('errors');

/**
 * User OTP value
 */
const otpCodeValueSelector = fieldSelector('otpCode');

export const selectors = {
  checkEligibility: modelSelector,
  formData: formDataSelector,
  isValidForm: isValidFormSelector,
  userStatus: userStatusSelector,
  otpCodeValue: otpCodeValueSelector,
  serverValidationErrors: serverValidationErrorsSelector,

  useIsValid: createHookSelector(isValidFormSelector),
  useCheckEligibilityData: createHookSelector(formDataSelector),
  useUserStatus: createHookSelector(userStatusSelector),
  useServerValidationErrors: createHookSelector(serverValidationErrorsSelector),
}
