import { sagas, actions, reducer, selectors } from './model';
import { config } from './config';

export * from './types';

export const dictionariesModel = {
  actions,
  reducer,
  sagas,
  config,
  selectors,
};

export * from './utils';