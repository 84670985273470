import React, { useEffect, useState } from 'react';

type Options = {
  label: string;
  value: string | number | boolean;
};

type OptionsInputProps = {
  onChange: (v: any) => void;
  value: string | number | boolean;
  options?: Options[];
  isDisabled?: boolean;
};

/**
 * Custom radio-like input element
 * @returns React.FC
 * */
export const OptionsInput: React.FC<OptionsInputProps> = ({
  options = [],
  value,
  onChange,
  isDisabled = false,
}) => {
  const initialValue = { value: '', label: '' };
  const [selectedValue, setSelectedValue] = useState<Options>(initialValue);
  useEffect(
    () =>
      setSelectedValue(
        options?.find((opt) => opt.value === value) || initialValue
      ),
    [value, setSelectedValue]
  );

  return (
    <div className={`option-box${isDisabled ? '-disabled' : ''}`}>
      {options?.length &&
        options.map((opt) => (
          <div
            key={opt.value.toString()}
            className={`option-element ${
              selectedValue.value === opt.value
                ? isDisabled
                  ? 'optional-input-disabled'
                  : 'selected-option'
                : undefined
            }`}
            onClick={() =>
              isDisabled
                ? null
                : selectedValue.value === opt.value
                ? null
                : onChange(opt.value)
            }
          >
            {opt.label}
          </div>
        ))}
    </div>
  );
};
