import {createSlice} from '@reduxjs/toolkit';

import {storeUtils} from '../../../common';
import {config} from '../config';
import {SettingModel} from '../types';

const initialState: SettingModel = {
  registerPeriod: null,
  registrationPeriods: [],
  fieldsHelp: [],
};

const createAction = storeUtils.createActionCreatorWithPrefix(config.modelName);

/**
 * Action for getting all dictionaries
 */
const getSettings = createAction('getSettings');
const sendMail = createAction<string>('sendMail');

export const createSettingsSlice = createSlice({
  name: config.modelName,
  initialState,

  reducers: {
    setRegisterPeriod: storeUtils.setStoreField('registerPeriod'),
    setRegistrationPeriods: storeUtils.setStoreField('registrationPeriods'),
    setFieldsHelp: storeUtils.setStoreField('fieldsHelp'),

    reset: () => initialState,
  },
});

export const {reducer} = createSettingsSlice;

export const actions = {
  ...createSettingsSlice.actions,
  getSettings,
  sendMail,
};
