import {
  toJewishDate, formatJewishDate, toHebrewJewishDate, formatJewishDateInHebrew, toGregorianDate, JewishMonth, BasicJewishDate, JewishDate

} from "jewish-date";

const currentDate = () => new Date();
const currentYear = () => new Date().getFullYear()
const currentMonth = () => new Date().getMonth()

const currentJewishDate = () => toJewishDate(currentDate());

const changeJewishDateYear = (years: number): JewishDate => {
  const newDate = {...currentJewishDate(), year: currentJewishDate().year + years}
  return newDate;
}

const hebrewYearMapper:Record<string,string> = {
  y2022: 'תשפ"ג',
  y2023: 'תשפ"ד',
  y2024: 'תשפ"ה',
  y2025: 'תשפ"ו',
  y2026: 'תשפ"ז',
  y2027: 'תשפ"ז',
  y2028: 'תשפ"ד',
  y2029: 'תשפ"ט',
  y2030: '',
}

export const getCurrentYear = ():string => currentYear()?.toString() || '';

export const getPreviousYear = ():string => (currentYear() - 1)?.toString() || '';

export const getNextYear = ():string => (currentYear() + 1)?.toString() || '';

export const getPreviousMonth = ():string => `${currentMonth() - 1}/${currentYear()}`;

export const getCurrentHebrewYear = (): string => {
  return toHebrewJewishDate(currentJewishDate()).year;
  // const year = `y${currentYear()}`;
  // if (hebrewYearMapper.hasOwnProperty(year)) {
  //   return hebrewYearMapper[year]
  // }
  // return ''
}

export const getPreviousHebrewYear = (): string => {
  return toHebrewJewishDate(changeJewishDateYear(-1)).year;
  // const year = `y${currentYear() - 1}`;
  // if (hebrewYearMapper.hasOwnProperty(year)) {
  //   return hebrewYearMapper[year]
  // }
  // return ''
}

export const getNextHebrewYear = (): string => {
  return toHebrewJewishDate(changeJewishDateYear(1)).year;
  // const year = `y${currentYear() + 1}`;
  // if (hebrewYearMapper.hasOwnProperty(year)) {
  //   return hebrewYearMapper[year]
  // }
  // return ''
}

export const getReadableDate = (date: string): string => {
  if (!!date) {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();


    const formatted = `${day}/${month}/${yyyy}`;
    return formatted;
  }
  return '';
}

export const getYearLib = {
  getCurrentYear,
  getPreviousYear,
  getNextYear,
  getPreviousMonth,
  getCurrentHebrewYear,
  getPreviousHebrewYear,
  getNextHebrewYear,
  getReadableDate,
}
