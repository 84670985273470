import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import * as CUI from '@CUI';
import { LabelWithHelp, LabelWithPopup } from '@common/perach-ui';
import { useActions } from '@common/store-utils/hooks';

import { dictionariesModel, fixValue } from '@entities/dictionaries';

import { RegistrationInputField } from './RegistrationInputField';
import { actions, selectors } from '../model';
import { BankDetails } from '../types';
import { config } from '../config';
import { stepOneFields, stepTwoFields } from '../fields';
import { bankDetailsDefaultValue } from '../defaults';
import { Optional } from '@common/ui';
import { getReservistDatesRange } from '@features/Registration/utils';

/**
 * Users bank details for Registration
 * @returns React.FC
 * */
export const BankDetailsForm: React.FC<{
  disabledFields?: Record<string, boolean>;
  shouldHideButtons?: boolean;
}> = ({ disabledFields, shouldHideButtons = false }) => {
  const { t } = useTranslation();
  const {
    updateBankDetails,
    saveBankDetails,
    autoSaveRegistration,
    setIsModalOpen,
  } = useActions(actions);

  const { getDictionaries } = useActions(dictionariesModel.actions);
  const [bankNames, setBankNames] = React.useState<any[]>([]);

  const bankDetails = selectors.useBankDetails();
  const banksData = dictionariesModel.selectors.useBanks();

  const onFieldChange = (e: CUI.InputTypes.ChangeEvent) => {
    const { name, value } = e.component;
    updateBankDetails({
      changes: { [name as Partial<keyof BankDetails>]: value },
    });
  };

  useEffect(() => {
    getDictionaries();
  }, []);

  useEffect(() => {
    setBankNames(banksData);
  }, [banksData]);

  return (
    <>
      <div className="form-container ph-25">
        <Row className="g-2 gx-5 mt-34">
          <Col className="col-12 col-md-6">
            {bankNames.length > 0 && (
              <>
                <LabelWithPopup
                  label={t('BANK')}
                  helpKey={stepTwoFields.bank}
                />
                <CUI.DropDownSelect
                  name={stepTwoFields.bank}
                  isDisabled={!!disabledFields?.[stepTwoFields.bank]}
                  form={config.forms.bankDetails}
                  shouldFilterValues
                  // data={banksData}
                  data={bankNames}
                  textField="text"
                  placeholder=" "
                  value={fixValue(bankDetails!.bank, bankNames)}
                  onChange={(e) =>
                    updateBankDetails({ changes: { bank: e.component.value } })
                  }
                  isRequired
                  requiredMessage={t('FIELD_REQUIRED')}
                />
              </>
            )}
          </Col>
          <Col className="col-12 col-md-6">
            <LabelWithPopup
              label={t('BRANCH')}
              helpKey={stepTwoFields.branch}
            />
            <CUI.Input
              _mb40
              form={config.forms.bankDetails}
              value={bankDetails?.branch}
              name={stepTwoFields.branch}
              isDisabled={!!disabledFields?.[stepTwoFields.branch]}
              onChange={onFieldChange}
              // @ts-ignore
              onBlur={autoSaveRegistration}
              isRequired
              allowedSymbols="numbers"
              maxLength={5}
              requiredMessage={t('FIELD_REQUIRED')}
            />
          </Col>
        </Row>
        <Row className="g-2 gx-5">
          <Col className="col-12 mb-80">
            <LabelWithPopup
              label={t('ACCOUNT_NUMBER')}
              helpKey={stepTwoFields.accountNumber}
            />
            <CUI.Input
              form={config.forms.bankDetails}
              value={bankDetails?.accountNumber}
              name={stepTwoFields.accountNumber}
              isDisabled={!!disabledFields?.[stepTwoFields.accountNumber]}
              onChange={onFieldChange}
              // @ts-ignore
              onBlur={autoSaveRegistration}
              allowedSymbols="numbers"
              isRequired
              maxLength={15}
              requiredMessage={t('FIELD_REQUIRED')}
            />
            <span>
              *באיסוף פרטי בנק אין הבטחה למתן מלגה או חלק ממנה, ופרטי הבנק ישמשו
              רק במידה ותהיה זכאות למלגה. שאר פרטי הבנק ימחקו.
            </span>
          </Col>
        </Row>
        <Optional when={!shouldHideButtons}>
          <Row>
            <Col className="col-12 col-md-9 mb-40">
              <CUI.Button
                _dBlock
                _w100
                _signInButton
                _btnPrimaryPerach
                form={config.forms.bankDetails}
                // @ts-ignore
                onClick={saveBankDetails}
                shouldScrollToInvalidFields
                isDisabled={!!disabledFields?.[stepTwoFields.buttonSubmit]}
                scrollOffset={135}
              >
                {t('NEXT')}
              </CUI.Button>
            </Col>
            <Col className="col-12 col-md-3 mb-40">
              <CUI.Button
                _dBlock
                _w100
                _signInButton
                _btnInfoPerach
                isDisabled={!!disabledFields?.[stepTwoFields.buttonSave]}
                onClick={() => setIsModalOpen(true)}
              >
                {t('SAVE_AND_CLOSE')}
              </CUI.Button>
            </Col>
          </Row>
        </Optional>
      </div>
    </>
  );
};
