import React from 'react';
import * as CUI from '@CUI';
import { Modal } from '@common/perach-ui';
import { actions } from '../model';
import { useActions } from '@common/store-utils/hooks';
import { useTranslation } from 'react-i18next';

type props = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

export const PreSubmitRequestModal: React.FC<props> = ({
  isOpen,
  setIsOpen,
}) => {
  const { saveDocumentsData } = useActions(actions);
  const { t } = useTranslation();
  return (
    <Modal
      isModalOpen={isOpen || false}
      header={'הגש בקשה'}
      modalBody={
        <CUI.Div _modalBodyContainer>
          <CUI.Span _modalBodyHeader _mt24>
            את.ה עומד.ת להגיש את הבקשה. לאחר הגשתה לא ניתן יותר לערוך, לשנות,
            להוסיף או למחוק מסמכים או כל מידע אחר שנמסר. אם ברצונך להמשיך לערוך
            את הבקשה, יש ללחוץ על "חזרה לבקשה". אם ברצונך להגיש אותה, יש ללחוץ
            על "הגש.י בקשה". בכל שלב ניתן לחזור ולצפות בנתונים באיזור האישי.
          </CUI.Span>
          <CUI.Div _inline>
            <CUI.Button
              _btnPrimaryPerach
              _mb16
              _mt24
              _ml16
              onClick={() => {
                setIsOpen(false);
                saveDocumentsData();
              }}
            >
              {'הגש.י בקשה'}
            </CUI.Button>
            <CUI.Button
              _btnInfoPerach
              _noMinWidthMob
              _mb16
              _mt24
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {'חזרה לבקשה'}
            </CUI.Button>
          </CUI.Div>
        </CUI.Div>
      }
      closeModal={() => setIsOpen(false)}
    />
  );
};
