import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { actions } from './ducks';
import { selectors } from './selectors';
import { actions as userActions } from '@entities/user/model';
import { createNewSurvey, getStaticData } from './api';
/**
 * Send Student Survey Sage
 * @returns {void}
 */
function* sendStudentSurvey(): SagaIterator {
  try {
    const model = yield select(selectors.survey);
    const birth_country = model.took_survey ? model.birth_country : '';
    const birth_country_parent_one = model.took_survey
      ? model.birth_country_parent_one
      : '';
    const birth_country_parent_two = model.took_survey
      ? model.birth_country_parent_two
      : '';
    const education_parent_one = model.took_survey
      ? model.education_parent_one
      : '';
    const education_parent_two = model.took_survey
      ? model.education_parent_two
      : '';
    const culture_group = model.took_survey ? model.culture_group : '';

    const createSurveyReqBody = {
      birth_country,
      birth_country_parent_one,
      birth_country_parent_two,
      education_parent_one,
      education_parent_two,
      culture_group,
      saw_survey: model.saw_survey,
      took_survey: model.took_survey,
    };
    yield call(createNewSurvey, createSurveyReqBody);
  } catch (error: any) {
    console.log(error);
  }

  yield put(actions.setSawSurvey(true));
  yield put(actions.setTookSurvey(true));
  yield put(userActions.setIsFromSurvey(false));
  yield put(userActions.setIsShowSurveyFromRegistration(false));
}

function* getSurveyStaticData(): SagaIterator {
  try {
    const staticData = yield call(getStaticData);
    yield put(actions.setSurveyStaticData(staticData));
  } catch (e) {
    console.log({ e });
  }
}
/**
 * Watcher
 * @returns {void}
 */
function* watcher(): SagaIterator<void> {
  yield all([
    takeEvery(actions.sendStudentSurveyAction, sendStudentSurvey),
    takeEvery(actions.getSurveyStaticDataAction, getSurveyStaticData),
  ]);
}

export const sagas = {
  watcher,
};
